import React, { useEffect } from "react";
import { Icon, Input } from "semantic-ui-react";
import PropTypes from "prop-types";


const EmployeeToken = (props: any) => {
  const [employeeCode, setEmployeeCode] = React.useState("");
  const [employeeName, setEmployeeName] = React.useState("");

  useEffect(() => {
    setEmployeeCode(props.employeeCode);
  }, [props.employeeCode]);

  useEffect(() => {
    setEmployeeName(props.employeeName);
  }, [props.employeeName]);

  const changeToken = (data: any) => {
    setEmployeeCode(data.value);
  }

  const enterToken = () => {
    props.onEnterToken(employeeCode);
  }

  const clearToken = () => {
    if(props.disabled){
      return
    }
    props.onClearToken()
    setEmployeeCode("");
    setEmployeeName("");
  }

  return (
    <React.Fragment>
      <Input
        disabled={props.disabled}
        style={{ display: employeeName ? "none" : "" }}
        type="password"
        value={employeeCode ? employeeCode : ''}
        placeholder="ระบุ Employee code"
        onChange={(event, data) => changeToken(data)}
        onKeyPress={(event: any, data: any) => {
          if(props.disabled){
            return
          }
          if (event.key === "Enter") {
            enterToken();
          }
        }}
        error={props.error}
        loading={props.loading}
        icon={
          <Icon
            name='delete'
            link
            onClick={clearToken}
          />
        }
        tabIndex={props.tabIndex}
      />
      <Input
        readOnly
        disabled={props.disabled}
        style={{ display: employeeName ? "" : "none" }}
        value={employeeName ? employeeName : ''}
        icon={
          <Icon
            name='delete'
            link
            onClick={clearToken}
          />
        }
      />
    </React.Fragment>
  );
};

EmployeeToken.defaultProps = {
  disabled: false,
  tabIndex: 0,
  employeeCode: "",
  employeeName: "",
  onEnterToken: () => { },
  onClearToken: () => { },
  error: false,
  loading: false
}

EmployeeToken.propTypes = {
  disabled: PropTypes.bool,
  tabIndex: PropTypes.number,
  employeeCode: PropTypes.string,
  employeeName: PropTypes.string,
  onEnterToken: PropTypes.func,
  onClearToken: PropTypes.func,
}

export default React.memo(EmployeeToken);