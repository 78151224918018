import PropTypes from 'prop-types';
import React, { forwardRef, useImperativeHandle, useState, useRef } from 'react';
import { Button, Divider, Grid, Input, Modal, Segment, Form, Icon } from 'semantic-ui-react';
import PureReactTable from '../../../react-lib/apps/common/PureReactTable';
import DateTextBox from '../../../react-lib/apps/common/DateTextBox';
import DoctorSearchBox from "../../../react-lib/apps/common/DoctorSearchBox";
import RightLabel from '../../../react-lib/apps/common/RightLabel';

const ModalEpisodeList = forwardRef((props: any, ref) => {

  const [searchData, setSearchData] = React.useState({
    from_date: '',
    to_date: '',
    doctor_name: '',
    episode_id: '',
    episode_name: '',
  })
  const [isOpen, setIsOpen] = useState(false);
  const [episodeList, setEpisodeList] = useState([]);
  const doctorSearchRef = useRef(null);

  const {
    titleCard,
    onNewEpisode,
    getDoctorSpecialty,
    getDoctorList,
    getSearchDoctorNumberOfPage,
    getSearchDoctorCurrentPage,
  } = props;

  const handleGetEpisodeList = async (params: any) => {
    const [episodeData] = await props.onSearch(params)
    setEpisodeList(episodeData)
  }

  const columns = [
    { Header: 'วันที่บันทึก', accessor: 'created_at', width: 150 },
    { Header: 'เลขที่ Episode', accessor: 'id', width: 200 },
    {
      Header: 'ชื่อ Episode',
      accessor: 'name',
      Cell: (props: any) => {
        return <Input fluid value={props.value} onChange={(e, data) => {
          onEpisodeNameChange(data, props);
        }}></Input>
      }
    },
    { Header: 'ชื่อผู้บันทึก', accessor: 'edit_user_full_name', width: 200 },
  ]

  useImperativeHandle(ref, () => ({
    openModal(data: any) {
      console.log(data)
      setIsOpen(true)

      // Load Data
      var params = { doctorNameCode: '' }
      handleGetEpisodeList(params)
    }
  }));

  const onSearchClicked = () => {
    let doctorName = (doctorSearchRef as any).current.value()
    var params = {
      createdStartDate: searchData.from_date,
      createdEndDate: searchData.to_date,
      doctorNameCode: doctorName,
      id: searchData.episode_id,
      name: searchData.episode_name
    }
    handleGetEpisodeList(params)
  }

  const onNewEpisodeClicked = () => {
    onNewEpisode(episodeList);
    setIsOpen(false);
  }

  const onEpisodeNameChange = (data: any, props: any) => {
    const newEpisodeList = episodeList.map((episode, index) => {
      if (index === props.index) {
        return {
          ...(episode as any),
          [props.column.id]: data.value
        }
      }
      return episode;
    });
    setEpisodeList(newEpisodeList as any);
  };

  let doctorSpecialties = getDoctorSpecialty()
  let doctorList = getDoctorList()
  let doctorListNumberOfPage = getSearchDoctorNumberOfPage()
  let doctorCurrentPage = getSearchDoctorCurrentPage()
  return (
    <Modal open={isOpen}>
      <Segment>
        <strong>{titleCard}</strong>
        <Icon link name='close' style={{float: 'right'}} onClick={() => {setIsOpen(false)}}/>
        <Divider/>
        <Form>
          <Form.Group inline>
            <Form.Field width={3}>
              <RightLabel>วันที่บันทึกตั้งแต่</RightLabel>
            </Form.Field>
            <Form.Field width={3}>
              <DateTextBox
                value={searchData.from_date}
                onChange={(date: any) => setSearchData({
                  ...searchData,
                  from_date: date
                })}
              />
            </Form.Field>
            <Form.Field width={2}>
              <RightLabel>ถึงวันที่</RightLabel>
            </Form.Field>
            <Form.Field width={3}>
              <DateTextBox
                value={searchData.to_date}
                onChange={(date: any) => setSearchData({
                  ...searchData,
                  to_date: date
                })}
              />
            </Form.Field>
            <Form.Field width={2}>
              <RightLabel>ชื่อแพทย์</RightLabel>
            </Form.Field>
            <Form.Field width={5}>
              <DoctorSearchBox
                //Data
                ref={doctorSearchRef}
                specialitiesList={doctorSpecialties}
                doctorList={doctorList}
                // // callback
                getSearchDoctorList={props.handleSearchDoctor}
                getSpecialitiesList={props.handleGetDoctorSpecialty}
                doctorListNumberOfPage={doctorListNumberOfPage}
                currentPage={doctorCurrentPage}
                onPageChange={props.onSearchDoctorListPageChange}
                onSelectDoctor={(e: any, data: any) => {
                  // setSearchData({
                  //   ...searchData,
                  //   doctor_name: data
                  // });
                }}
              />
            </Form.Field>
            <Form.Field width={2}/>
          </Form.Group>               
          <Form.Group inline>
            <Form.Field width={3}>
              <RightLabel>Episode ID</RightLabel>
            </Form.Field>
            <Form.Input width={3}
              placeholder='Episode ID'
              value={searchData.episode_id}
              onChange={(e, data) => {
                setSearchData({
                  ...searchData,
                  episode_id: data.value
                });
              }}
            />
            <Form.Field width={2}>
              <RightLabel>Episode</RightLabel>
            </Form.Field>
            <Form.Input width={10}
              placeholder='ระบุชื่อ Episode'
              value={searchData.episode_name}
              onChange={(e, data) => {
                setSearchData({
                  ...searchData,
                  episode_name: data.value
                });
              }}
            />
            <Form.Button width={2} fluid color='blue' onClick={onSearchClicked}>
              ค้นหา
            </Form.Button>
          </Form.Group>
        </Form>
        <PureReactTable
          data={episodeList}
          style={{height: 400,}}
          columns={columns}
          showPagination={false}
          pageSize={8}
          manual
          getTdProps={()=>({
            style: {
              justifyContent: 'center',
              display: 'flex',
              flexDirection: 'column',
            }
          })}
          onEnter={(row: any)=>{
            props.onEpisodeSelected(row)
            setIsOpen(false)
          }}
        />
        {/* <Grid>
          <Grid.Column textAlign="right">
            <Button color='red' onClick={()=>{setIsOpen(false)}}>
              ยกเลิกระบุ Episode
            </Button>
            <Button color='teal' onClick={onNewEpisodeClicked}>สร้าง Episode ใหม่</Button>
          </Grid.Column>
        </Grid> */}
      </Segment>
    </Modal>
  )
})

ModalEpisodeList.defaultProps = {
  titleCard: 'เลือก Episode',
  onSearch: () => []
};

ModalEpisodeList.propTypes = {
  titleCard: PropTypes.string,
  onSearch: PropTypes.func,
  onNewEpisodeClicked: PropTypes.func,

  /** Episode selected */
  onEpisodeSelected: PropTypes.func,

  // Search doctor
  handleGetDoctorSpecialty: PropTypes.func,
  getDoctorSpecialty: PropTypes.func,
  handleSearchDoctor: PropTypes.func,
  getDoctorList: PropTypes.func,
  getSearchDoctorNumberOfPage: PropTypes.func,
  getSearchDoctorCurrentPage: PropTypes.func,
  onSearchDoctorListPageChange: PropTypes.func
};

export default ModalEpisodeList
