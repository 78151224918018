import BaseService from "./BaseService";
import { REG } from "../../configs/apis";
import { AxiosRequestConfig } from "axios";
class REGService extends BaseService {

  /**
   * get patient data by hn
   * @param {object} data
   */
  getPatientByHN(hn: any) {
    const url = REG.PATIENT_HN({ hn: hn });
    return this.client
      .get(url)
      .then(this.handleResponse)
      .catch(this.throwErrorMessage);
  }
  /**
   * get patient data by patient id
   * @param {object} data
   */
  getPatientById(id: any, apiToken: any) {
    if (apiToken) {
      this.client.defaults.headers["Authorization"] = "Token " + apiToken;
    }
    const url = REG.PATIENT({ patient_id: id });
    return this.client
      .get(url)
      .then(this.handleResponse)
      .catch(this.throwErrorMessage);
  }
  getSearchPatient(nameCode: any) {
    const url = REG.SEARCH_PATIENT_HN({ name_code: nameCode });
    return this.client
      .get(url)
      .then(this.handleResponse)
      .catch(this.throwErrorMessage);
  }
  getSearchAllPatient(params: any, cancelToken: any = null) {
    const url = REG.SEARCH_PATIENT;
    let config: AxiosRequestConfig = { params };
    if (cancelToken) {
      config.cancelToken = cancelToken;
    }
    return this.client
      .get(url, config)
      // .get(url, { params, ...(cancelToken && { cancelToken }) })
      .then(this.handleResponse)
      .catch(this.throwErrorMessage);
  }

  getPatientName(patientId: any) {
    const url = REG.SEARCH_PATIENT_NAME({ patient_id: patientId });
    return this.client
      .get(url)
      .then(this.handleResponse)
      .catch(this.throwErrorMessage);
  }

  getBloodType({ params, apiToken }: any) {
    if (apiToken) {
      this.client.defaults.headers["Authorization"] = "Token " + apiToken
    }
    return this.client.get(REG.BLOOD_TYPE, { params })
      .then((response) => response.data)
      .catch(this.throwErrorMessage);
  }
}
export default REGService;
