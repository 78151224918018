
import React from "react"
import {
	serial_to_datestr, DaySlotDisplay, serial_to_hour
} from "./Time"

interface ServiceSlotSelectProps {
  day_slots_display: DaySlotDisplay[],
  divisions: number[],
  createServiceSlot: ({ serial, status, provider, division }:
    { serial: number, status: number, provider: number, division: number}) => void,
  updateServiceSlot: ({ pk, serial, status, provider, division } :
    { pk: number | null, serial: number, status: number, provider: number, division: number | null }) => void,
  min_hour: number,
	max_hour: number,
	provider: number,
}

const border = (index: number) => (index % 4 === 3 ? "borderBottom" : "" )

const ServiceSlotSelect = (props: ServiceSlotSelectProps) => {

	const handleClick = (d: DaySlotDisplay, division: number | null) => async (e: any) => {
		if (d.exists) {
			props.updateServiceSlot({ pk: d.id, serial: d.serial, status: 1,  provider: props.provider, division: division })
		}
		else if (!d.exists && division !== null) {
			props.createServiceSlot({ serial: d.serial, status: 1,  provider: props.provider, division: division })
		}
	}

	const background = (elem: number | null, d: DaySlotDisplay | null) => {
		if (d?.exists) {
			if (d.status === 1 && elem === d.division ) {
        return "active"
			} else if ((d.status !== 1 || d.status === null) && elem === null) {
				return "active"
			} else {
				return ""
			}
		} else {
			if (elem === null) {
				return "active"
			} else {
				return ""
			}
		}
	}

	return(
	<div className="calendar">

	{ props.day_slots_display.map((d, index) => {
		return (
		serial_to_hour(d.serial) >= props.min_hour && serial_to_hour(d.serial) < props.max_hour &&
		<div key={index} className="service_slot" >
			<div className={`slotTime ${border(index)}`}>
				{d.serial % 4 === 0 || d.status === 1 ? serial_to_datestr(d.serial, "time") : '\u00A0'}
			</div>

		{ d.status === 1 ?
			<div className={`slotName ${border(index)}`}>
				{d.division_name}
			</div> :
			<div className={`slotName ${border(index)}`}></div>
		}

		{d.divisions.length > 0 ?
			<div onClick={handleClick(d, null)} className={`divisionName ${border(index)}`}>
				<span className={`textContent ${background(null, d)}`}>ไม่ลงตรวจ</span>
			</div> :
			<div className={`divisionName ${border(index)}`}></div>
		}

	{props.divisions.map((elem, d_index) => {
    const candidates = d.divisions.filter((division) => division.id === elem)
		if (candidates.length > 0) {
			return(
			<div key={d_index} onClick={handleClick(d, elem)} className={`divisionName ${border(index)}`}>
				<span className={`textContent ${background(elem, d)}`}>{candidates[0].name}</span>
			</div>)
		} else {
			return(
			<div key={d_index} className={`divisionName ${border(index)}`}></div>)
		}
	})}
		</div>
		)})}
	</div>)
}

export default React.memo(ServiceSlotSelect);