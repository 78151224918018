/*eslint no-var: 0*/
import app from "firebase/app";
import "firebase/firestore";
import "firebase/functions";
import "firebase/storage";
import "firebase/auth";
import * as firebaseui from "firebaseui";
import config from "../../_manager/firebase-config";

if (!app.apps.length) {
  app.initializeApp(config);
  // app.firestore.setLogLevel('debug');
}

export { default as app } from "firebase/app";
export var db: app.firestore.Firestore = app.firestore();
export var functions: app.functions.Functions = app.functions();
export var storage: app.storage.Storage = app.storage();
export const ui = new firebaseui.auth.AuthUI(app.auth());