import BaseService from "./BaseService";
import { PTM } from "../../configs/apis";
class PTMService extends BaseService {
  /**
   * get admit order
   * @param {object} data
   */
  getNurseNoteItem(params, apiToken) {
    if (apiToken) {
      this.client.defaults.headers["Authorization"] = "Token " + apiToken;
    }
    return this.client
      .get(PTM.NURSE_NOTE_ITEM, { params })
      .then(this.handleResponse)
      .catch(this.throwErrorMessage);
  }
  getNurseNoteItemER(params) {
    return this.client
      .get(PTM.NURSE_NOTE_ITEM_ER, { params })
      .then(this.handleResponse)
      .catch(this.throwErrorMessage);
  }
  getFormDataLatest(params) {
    return this.client
      .get(PTM.FORM_DATA_LATEST, { params })
      .then(this.handleResponse)
      .catch(this.throwErrorMessage);
  }
  getFormDataActionLog(formId) {
    return this.client
      .get(PTM.FORM_DATA_ACTION_LOG({ form_id: formId }))
      .then(this.handleResponse)
      .catch(this.throwErrorMessage);
  }
  getEMRSummary(params) {
    const url = PTM.GET_EMR_SUMMARY(params);
    return this.client
      .get(url)
      .then(this.handleResponse)
      .catch(this.throwErrorMessage);
  }
  getFormDataEncounter(encounter, params) {
    const url = PTM.FORM_DATA_ENCOUNTER({ encounter });
    return this.client
      .get(url, { params })
      .then(this.handleResponse)
      .catch(this.throwErrorMessage);
  }
  getMultidisciplinaryItem(params) {
    return this.client
      .get(PTM.MULTI_DISCIPLINARY_ITEM, { params })
      .then(this.handleResponse)
      .catch(this.throwErrorMessage);
  }
  deleteMultidisciplinaryItem(item_id, params) {
    const url = PTM.MULTI_DISCIPLINARY_ITEM_DETAIL({
      multidisciplinary_id: item_id
    });
    return this.client
      .delete(url, {
        data: params
      })
      .then(response => response.data)
      .catch(this.throwErrorMessage);
  }
  printMultidisciplinaryItem(params) {
    return this.client
      .post(PTM.PRINT_MULTI_DISCIPLINARY, params)
      .then(this.handleResponse)
      .catch(this.throwErrorMessage);
  }
  getEMRSummaryPrint(params) {
    const url = PTM.GET_EMR_SUMMARY_PRINT(params);
    return this.client
      .get(url)
      .then(this.handleResponse)
      .catch(this.throwErrorMessage);
  }
  getMeasurement(params) {
    return this.client
      .get(PTM.MEASUREMENT, { params })
      .then(this.handleResponse)
      .catch(this.throwErrorMessage);
  }
  
  /**
   * Create vital sign
   * @param {object} data
   * object = {
   *      encounter: encounterID,
   *      vital_signs: [{vitalsign_type: vsType, result: vsValue}, {...}, ..]
   * }
   */
  postMeasurement(data) {
    const url = PTM.MEASUREMENT;
    return this.client
      .post(url, data, null)
      .then(this.handleResponse)
      .catch(this.throwErrorMessage);
  }
  deleteVitalSign({ id, params, data }) {
    const url = PTM.VITAL_SIGN_VALUE_DELETE({ id });
    return this.client
      .delete(url, data, { params })
      .then(this.handleResponse)
      .catch(this.throwErrorMessage);
  }
  /**
   * Cancel vital sign with measurement id
   * @param {measurement_id} measurement
   * @param {object} params
   * object = {
   *      active: false,
   *      username: 'fuzzy'
   *      password: 'fuzzy1234'
   * }
   */
  patchCancelMeasurement(measurementId, params) {
    const url = PTM.MEASUREMENT_CANCEL({ measurementId });
    return this.client
      .patch(url, params, null)
      .then(this.handleResponse)
      .catch(this.throwErrorMessage);
  }
  getVitalSignType(params) {
    return this.client
      .get(PTM.VITAL_SIGN_TYPE, { params })
      .then(this.handleResponse)
      .catch(this.throwErrorMessage);
  }
  getVitalSignValue(params) {
    return this.client
      .get(PTM.VITAL_SIGN_VALUE, { params })
      .then(this.handleResponse)
      .catch(this.throwErrorMessage);
  }
  getLastHeight(patientId) {
    const url = PTM.LAST_HEIGHT({ patientId: patientId });
    return this.client
      .get(url, {})
      .then(this.handleResponse)
      .catch(this.throwErrorMessage);
  }
  postVitalSignUpdate(data) {
    const url = PTM.VITAL_SIGN_VALUE_CREATE_UPDATE;
    return this.client
      .post(url, data, null)
      .then(this.handleResponse)
      .catch(this.throwErrorMessage);
  }
  postPrintNurseNote(params) {
    const url = PTM.NURSE_NOTE_ITEM_PRINT;
    return this.client
      .post(url, null, { params })
      .then(this.handleResponse)
      .catch(this.throwErrorMessage);
  }
  postPrintMultiDisciplinary(data) {
    const url = PTM.MULTIDISCIPLINARY_PRINT;
    return this.client
      .post(url, data, { pdf: true })
      .then(this.handleResponse)
      .catch(this.throwErrorMessage);
  }
  putMultidisciplinaryItem(params) {
    const url = PTM.MULTI_DISCIPLINARY_ITEM_DETAIL({
      multidisciplinary_id: params.id
    });
    return this.client
      .put(url, params)
      .then(this.handleResponse)
      .catch(this.throwErrorMessage);
  }
  postMultidisciplinaryItem(params) {
    return this.client
      .post(PTM.MULTI_DISCIPLINARY_ITEM, params)
      .then(this.handleResponse)
      .catch(this.throwErrorMessage);
  }
  getOpdOneDay(params) {
    const url = PTM.DOCTOR_ORDER;
    return this.client
      .get(url, { params })
      .then(this.handleResponse)
      .catch(this.throwErrorMessage);
  }
  getFormType(params) {
    return this.client
      .get(PTM.FORM_TYPE, { params })
      .then(this.handleResponse)
      .catch(this.throwErrorMessage);
  }
  getFormDataNurseNote({ params }) {
    return this.client
      .get(PTM.FORM_DATA_NURSE_NOTE, { params })
      .then(this.handleResponse)
      .catch(this.throwErrorMessage);
  }
  getNurseDiagnosisResultGroup(params) {
    return this.client
      .get(PTM.NURSE_DIAG_RESULT_GROUP, { params })
      .then(this.handleResponse)
      .catch(this.throwErrorMessage);
  }

  getErShortcut({ params, cancelToken }) {
    return this.client
      .get(PTM.ER_SHORTCUT, { params, cancelToken })
      .then(this.handleResponse)
      .catch(this.throwErrorMessage);
  }

  getKpiTemplate({ params }: { params?: { name__icontains?: string } } = {}){
    return this.client
      .get(PTM.KPI_TEMPLATE, { params })
      .then(this.handleResponse)
      .catch(this.throwErrorMessage);
  }

  getKpiEncounter({ params }: { params?: { encounter?: string | null } } = {}){
    return this.client
      .get(PTM.KPI_ENCOUNTER, { params })
      .then(this.handleResponse)
      .catch(this.throwErrorMessage);
  }

  postKpiEncounter({ params, data }){
    return this.client
      .post(PTM.KPI_ENCOUNTER, data, { params })
      .then(this.handleResponse)
      .catch(this.throwErrorMessage);
  }

  deleteKpiEncounter({ params, kpiId }){
    return this.client
      .delete(PTM.KPI_ENCOUNTER_DELETE({ kpiId }), { params })
      .then(this.handleResponse)
      .catch(this.throwErrorMessage);
  }

  putERBedNo({ params, data, encounterId }){
    return this.client
    .put(PTM.ER_BED_NO_ENCOUNTER({ encounterId }), data, { params })
    .then(this.handleResponse)
    .catch(this.throwErrorMessage);
  }

  putSeenDoctorOrderNotification({ params, orderId }){
    return this.client
    .put(PTM.SEEN_DOCTOR_ORDER_NOTIFICATION({ orderId }), {}, { params })
    .then(this.handleResponse)
    .catch(this.throwErrorMessage);
  }

  getUnacknowledgedDoctorOrders({ params }){
    return this.client
    .get(PTM.UNACKNOWLEDGE_DOCTOR_ORDERS, { params })
    .then(this.handleResponse)
    .catch(this.throwErrorMessage);
  }

  putNurseDiagPrintPhv({ params, data }) {
    return this.client
      .put(PTM.NURSE_DIAG_PRINT_PHV, data, { params })
      .then(this.handleResponse)
      .catch(this.throwErrorMessage);
  }
  getNurseNoteLatest({ params } = {}) {
    return this.client
      .get(PTM.NURSE_NOTE_LATEST, { params })
      .then(this.handleResponse)
      .catch(this.throwErrorMessage);
  }

  postNurseNoteItem({ params, data, apiToken } = {}) {
    if (apiToken) {
      this.client.defaults.headers["Authorization"] = "Token " + apiToken;
    }
  }
  
  postErShortcut({ params, data } = {}){
    return this.client
    .post(PTM.ER_SHORTCUT, data, { params })
    .then(this.handleResponse)
    .catch(this.throwErrorMessage);
  }

  putErShortcut({ params, shortcutId, data } = {}){
    return this.client
    .put(PTM.ER_SHORTCUT_DETAIL({ shortcutId }), data, { params })
    .then(this.handleResponse)
    .catch(this.throwErrorMessage);
  }

  postNurseNoteItem({ params, data } = {}) {
    return this.client
      .post(PTM.NURSE_NOTE_ITEM, data, { params })
      .then(this.handleResponse)
      .catch(this.throwErrorMessage);
  }

  putNurseNoteItem({ params, data, nurseNoteItemId, apiToken } = {}) {
    if (apiToken) {
      this.client.defaults.headers["Authorization"] = "Token " + apiToken;
    }
    const url = PTM.NURSE_NOTE_ITEM_DETAIL({
      nurse_note_item_id: nurseNoteItemId
    });
    return this.client
      .put(url, data, { params })
      .then(this.handleResponse)
      .catch(this.throwErrorMessage);
  }
  deleteNurseNoteItem({ params, data, nurseNoteItemId } = {}) {
    const url = PTM.NURSE_NOTE_ITEM_DETAIL({
      nurse_note_item_id: nurseNoteItemId
    });
    return this.client
      .delete(url, { data }, { params })
      .then(this.handleResponse)
      .catch(this.throwErrorMessage);
  }
  putNurseNote({ params, data, nurseNoteId } = {}) {
    const url = PTM.NURSE_NOTE_DETAIL({ nurse_note_id: nurseNoteId });
    return this.client
      .put(url, data, { params })
      .then(this.handleResponse)
      .catch(this.throwErrorMessage);
  }
  getDrOrder({ params } = {}) {
    return this.client
      .get(PTM.DR_ORDER, { params })
      .then(this.handleResponse)
      .catch(this.throwErrorMessage);
  }
  getVentilatorMode({ params } = {}) {
    return this.client
      .get(PTM.VENTILATOR_MODE, { params })
      .then(this.handleResponse)
      .catch(this.throwErrorMessage);
  }
  putEncounterVitalSignType({ params, data, encounterId }) {
    const url = PTM.ENCOUNTER_VITALSIGN_TYPE({ encounter_id: encounterId });
    return this.client
      .put(url, data, { params })
      .then(this.handleResponse)
      .catch(this.throwErrorMessage);
  }
  putRemoveEncounterVitalSignType({ params, data, encounterId }) {
    const url = PTM.REMOVE_ENCOUNTER_VITALSIGN_TYPE({
      encounter_id: encounterId
    });
    return this.client
      .put(url, data, { params })
      .then(this.handleResponse)
      .catch(this.throwErrorMessage);
  }
  postFormData({ params } = {}) {
    // /apis/PTM/form-data/ #CREATE, UPDATE FORM_DATA
    // /apis/PTM/form-data-latest/ #RETRIEVE FORM_DATA_LATEST
    return this.client
      .post(PTM.FORM_DATA, params)
      .then(this.handleResponse)
      .catch(this.throwErrorMessage);
  }
  putFormData({ params, formDataId } = {}) {
    const url = PTM.FORM_DATA_DETAIL({ form_data_id: formDataId });
    return this.client
      .put(url, params)
      .then(this.handleResponse)
      .catch(this.throwErrorMessage);
  }
  putMeasurement({ params, data, measurementId } = {}) {
    const url = PTM.MEASUREMENT_DETAIL({ measurementId });
    return this.client
      .put(url, data, { params })
      .then(this.handleResponse)
      .catch(this.throwErrorMessage);
  }
  getNurseNoteToken({ params, data } = {}) {
    return this.client
      .post(PTM.NURSE_NOTE_TOKEN, data, { params })
      .then(this.handleResponse)
      .catch(this.throwErrorMessage);
  }

  getStandingOrderTemplateList() {
    return this.client.get(PTM.STANDING_ORDER_TEMPLATE)
    .then(this.handleResponse)
    .catch(this.throwErrorMessage);
  }

  getSafetyChecklistPrint({ params = {}, formDataId = null } = {}){
    return this.client.get(PTM.SAFETY_CHECK_LIST_PRINT({ formDataId }))
    .then(this.handleResponse)
    .catch(this.throwErrorMessage);
  }

  getReferOutPrint({ params = {}, formDataId = null } = {}){
    return this.client.get(PTM.ER_REFER_OUT_PRINT({ formDataId }))
    .then(this.handleResponse)
    .catch(this.throwErrorMessage);
  }
}
export default PTMService;
