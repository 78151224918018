import React from "react";
import { Image, Form, Icon } from "semantic-ui-react";
import personImg from "../../common/person.png";
import PropTypes from "prop-types";
import { convertToBEDate } from "../../../utils/tsUtils";
import {isValidHttpUrl}  from "./MyMessage";

const FriendMessage = props => {
  const createMessage = () => {
    if (props.message.content_type === "file") {
      return (
        <div className="file">
          <div>
            <Icon name="file" size="big" />
          </div>
          <div className="text">Click to open file</div>
        </div>
      );
    } else if (props.message.content_type === "image") {
      return (
        <Image
          src={props.message.content_file}
          // href={props.isMobile ? props.message.content_file: ""}
          onClick={() => {
            // if (typeof window.iosNative !== "undefined") {
            //   window.iosNative.consoleLog("onClick");
            //   window.iosNative.saveImage(props.message.content_file);
            // } else {
            if(!props.isMobile){
              window.open(props.message.content_file);
            }
            // }
          }}
        />
      );
    } else if (props.message.content_type === "vid_call") {
      return <Icon name="call square" size="big" color="green" />;
    } else if (props.message.content_type === "end_call") {
      return <Icon name="call square" size="big" color="gray" />;
    } else if (props.message.content_type === "navigation") {
      return props.onNavigationMessage({
        content: props.message.content,
        contentPayload: props.message.content_payload,
        channelId: props.message.channel
      });
    } else if (props.message.content_type === "bill") {
      return props.onNavigationMessage({
        content: props.message.content,
        contentPayload: props.message.content_payload,
        contentType: props.message.content_type
      });
    } else {
      if (
        props.message.content.includes("http")
      ) {
        let listMsg = props.message.content.split(" ");
        // console.log("listMsg", listMsg);
        let Linkify = listMsg.map((item) => {
          if (!item.startsWith("http") && item.includes("http")) {
            let startIdx = item.indexOf("http");
            if (startIdx > 0) {
              let normalText = item.slice(0, startIdx);
              // console.log("normalText:", normalText);
              let urlText = item.slice(startIdx);
              // console.log("urlText:", urlText);
              return (
                <>
                  <>
                    {normalText}
                    <a href={urlText}>{urlText}</a>
                  </>
                </>
              );
            }
          } else {
            return isValidHttpUrl(item) ? (
              <a href={item}> {item} </a>
            ) : (
              <> {item} </>
            );
          }
        });

        return Linkify;
      } else {
        return props.message.content;
      }
    }
  };

  return (
    <Form className="FriendMessage">
      {props.message.author ? (
        <Form.Field className="friendName">
          <b>{props.message.author.full_name}</b>
        </Form.Field>
      ) : null}
      <Form.Group inline>
        <Form.Field width={1} className="avatarImage">
          <Image
            src={
              props.message.author && props.message.author.image
                ? props.message.author.image
                : personImg
            }
            circular
            size="mini"
            // onMouseOver={() => console.log(9999)}
          />
        </Form.Field>
        <Form.Field
          className="content"
          href={props.isMobile && props.message.content_type === "file" ? props.message.content_file : ""}
          onClick={() => {
            if (props.message.content_type == "vid_call") {
              props.onOpenVideoCall({ url: props.message.content });
            }
            if (props.message.content_type === "file") {
              window.open(props.message.content_file);
            }
            if (props.isMobile && props.message.content_type === "image") {
              props.onMobileOpenImage(props.message.content_file);
            }
          }}
        >
          {createMessage()}
        </Form.Field>
        <Form.Field className="sendDateTime">
          <div>{props.message.send_at.split(" ")[1]}</div>
          <div>
            {convertToBEDate({ date: props.message.send_at.split(" ")[0] })}
          </div>
        </Form.Field>
      </Form.Group>
    </Form>
  );
};

FriendMessage.defaultProps = {
  isMobile: false,
  onOpenVideoCall: ({ url }) => {},
  onNavigationMessage: ({ content = "" }) => content,
  onMobileOpenImage: () => {}
};

FriendMessage.propTypes = {
  isMobile: PropTypes.bool,
  onOpenVideoCall: PropTypes.func,
  onNavigationMessage: PropTypes.func
};

export default React.memo(FriendMessage);
