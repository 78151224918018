export default class QueueController {
  constructor(props) {
    this.queManager = props.queManager;
    this.prxManager = props.prxManager;
    this.hrmManager = props.hrmManager;
  }

  loadDivisionServiceBlock = async ({
    apiToken,
    from_serial,
    to_serial,
    divisions,
    onlyHasProvider,
    employeeType
  }) => {
    const [data, error] = await this.queManager.loadDivisionServiceBlock({
      apiToken,
      from_serial,
      to_serial,
      divisions,
      onlyHasProvider,
      employeeType
    });
    return [data, error]
  }

  listPatientAppointment = async ({
    apiToken,
    patientId,
  }) => {
    const [data, error, network] = await this.queManager.listPatientAppointment({
      apiToken,
      patientId,
    });
    return [data, error, network]
  }

  getPatientAppointment = async ({
    apiToken,
    pk
  } = {}) => {
    const [data, error, network] = await this.queManager.getPatientAppointment({
      apiToken,
      pk,
    });
    return [data, error, network]
  }

  getDivisionHasUser = async ({
    apiToken
  } = {}) => {
    const [data, error, network] = await this.prxManager.getDivisionHasUser({
      apiToken,
    });
    return [data, error, network]
  }

  loadServiceSlot = async ({
    apiToken,
    fromSerial,
    toSerial,
    providerId
  } = {}) => {
    const [data, error, network] = await this.queManager.loadServiceSlot({
      apiToken,
      fromSerial,
      toSerial,
      providerId
    });
    return [data, error, network]
  }

  createServiceSlot = async ({
    apiToken,
    serial,
    status,
    provider,
    division
  } = {}) => {
    const [data, error, network] = await this.queManager.createServiceSlot({
      apiToken,
      serial,
      status,
      provider,
      division
    });
    return [data, error, network]
  }

  updateServiceSlot = async ({
    pk,
    apiToken,
    serial,
    status,
    provider,
    division
  } = {}) => {
    const [data, error, network] = await this.queManager.updateServiceSlot({
      apiToken,
      pk,
      serial,
      status,
      provider,
      division
    });
    return [data, error, network]
  }

  updatePatientAppointment = async ({
    pk,
    apiToken,
    divisionServiceBlock,
  } = {}) => {
    const [data, error, network] = await this.queManager.updatePatientAppointment({
      apiToken,
      pk,
      divisionServiceBlock,
    });
    return [data, error, network]
  }

  getProviderInfo = async ({
    apiToken,
    userId,
  } = {}) => {
    const [data, error, network] = await this.hrmManager.getProviderInfo({
      apiToken,
      userId,
    });
    return [data, error, network]
  }

  getDoctorWorkSchedule = async ({
    apiToken,
    providerId,
    startDate,
    endDate,
    divisionId
  } = {}) => {
    const [data, error, network] = await this.prxManager.getDoctorWorkSchedule({
      apiToken,
      providerId,
      startDate,
      endDate,
      divisionId
    });
    return [data, error, network]
  }

  getAvailableDivisionServiceBlock = async ({
    apiToken,
    division,
    employee,
    date,
    timeRange,
    providerTypeCategory
  } = {}) => {
    const [data, error, network] = await this.prxManager.getAvailableDivisionServiceBlock({
      apiToken,
      division,
      employee,
      date,
      timeRange,
      providerTypeCategory
    });
    return [data, error, network]
  }

  postSelectDivisionServiceBlock = async ({
    apiToken,
    patientAppointmentId,
    division,
    timeRange,
    employee,
    diagFormId,
    providerTypeCategory
  } = {}) => {
    const [data, error, network] = await this.prxManager.postSelectDivisionServiceBlock({
      apiToken,
      patientAppointmentId,
      division,
      timeRange,
      employee,
      diagFormId,
      providerTypeCategory
    })
    return [data, error, network]
  }

  getPatientAppointmentUpdate = async ({
    apiToken,
    appointmentId
  } = {}) => {
    const [data, error, network] = await this.queManager.getPatientAppointmentUpdate({
      apiToken,
      appointmentId
    })
    return [data, error, network]
  }

  postRequestChangeDivisionServiceBlock = async ({
    apiToken,
    remark,
    division,
    patientAppointment
  } = {}) => {
    const [data, error, network] = await this.prxManager.postRequestChangeDivisionServiceBlock({
      apiToken,
      remark,
      division,
      patientAppointment
    })
    return [data, error, network]
  }
}