export default class LoginController {
  
  mixInManager: any;
  userManager: any;

  constructor(props: any) {
    this.mixInManager = props.mixInManager;
    this.userManager = props.userManager;
  }

  getRegisterByTokenFacebook = async ({ accessToken, subscription, device_id, app, email }: {
    accessToken?: any, subscription?: any, device_id?: any, app?: any, email?: any
  }) => {
    let [
      response,
      error,
      network
    ] = await this.mixInManager.getRegisterByTokenFacebook({
      accessToken,
      subscription,
      device_id,
      app,
      email
    });
    return [response, error, network];
  };

  getUserProfile = async ({ apiToken} : { apiToken: any }) => {
    let [
      response,
      error,
      network
    ] = await this.userManager.getUserProfile({
      apiToken
    });
    return [response, error, network];
  }

  updateUserProfile = async ({apiToken, firstName, lastName, dob, phone} : {
    apiToken: any, firstName: any, lastName: any, dob: any, phone: any
  }) => {
    let [
      response,
      error,
      network
    ] = await this.userManager.updateUserProfile({
      apiToken,
      firstName,
      lastName,
      dob,
      phone,
    });
    return [response, error, network];
  }
}