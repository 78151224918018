
/* Generated apis ---------------------- */
import { to } from 'react-lib/utils'
import BaseService from  'react-lib/apis/services/BaseService'
import config from '../../config/config'
import MonitorDiagFormSerializer from '../types/MonitorDiagFormSerializer_apps_PRX'


const HOST = `${config.API_HOST}`
/* ['CreateAPIView'] */
/* params:  */
/* data:  */
const MonitorDiagForm : 
{
  create:   ({params, data, apiToken, extra}:
             { 
                params?: any,
                data?: MonitorDiagFormSerializer,
                apiToken?: any,
                extra?: any
            }) => any,
} = 
{
  create: async ({params, data, apiToken, extra}) => {
    const base = new BaseService()
    if (apiToken) {
        base.client.defaults.headers["Authorization"] = "Token " + apiToken
    }
    const result = await to(base.client.post(`${HOST}/apis/PRX/monitor-diag-form/`,
      { ...data
      },
      { params: params ? { ...params } : {},
         ...extra
      }
    )
    .then(base.handleResponse)
    .catch(base.throwErrorMessage))
    return [result[1], result[0], result[2]]
  },
}

export default MonitorDiagForm

