// import pdfMake from "pdfmake/build/pdfmake";
// import pdfFonts from "../assets/fonts/pdfFonts";
import getPdfMake from "../appcon/common/pdfMake";

// const dataMockup = {
//   language: "EN", // "EN"
//   patientName: "โกวิท วัฒนา",
//   nationality: "Thai",
//   room: "204",
//   hn: "6400044",
//   physician: "007",
//   visitDate: "15/07/2564",
//   department: "เวชระเบียน",
//   birthDate: "01/06/2536",
//   age: "28",
//   sex: "Male",
//   allergies: "None",
//   sideEffect: "None",

//   answerMentalno1: 0,
//   answerMentalno2: 1,
//   answerMentalno3: 2,
//   answerMentalno4: 3,
//   answerMentalno5: 0,
//   answerMentalno6: 1,
//   answerMentalno7: 2,
//   answerMentalno8: 3,
//   answerMentalno9: 0,

//   answerMentalSum0: "3 x 1 = 0",
//   answerMentalSum1: "2 x 1 = 2",
//   answerMentalSum2: "2 x 2 = 4",
//   answerMentalSum3: "2 x 3 = 6",
//   answerMentalTotal: 12,

//   answerDifficult: "Not difficult at all",
//   unableToAccess: false,
//   consultPsychiatrist: false,
//   consultPsychologist: true,
//   consultPsychologistDate: "01/06/2564",
//   consultPsychologistTime: "11:00",
//   notConsultPsy: false,
//   registeredNurse: "นายแพทย์สมศักดิ์ รักษาดี",
//   registeredNurseDate: "01/06/2564",
//   registeredNurseTime: "11:00",
//   scanBy: "นายนพดล ดำดี",
// };

export const createPDF = async (props) => {

  let pdfMake = await getPdfMake()

  // let make = await import("pdfmake/build/pdfmake");
  // let font = await import("../assets/fonts/pdfFonts")
  // let pdfMake = make.default
  // pdfMake.vfs = font.default 
  // pdfMake.fonts = {
  //     THSarabunNew: {
  //         normal: 'THSarabunNew.ttf',
  //         bold: 'THSarabunNew-Bold.ttf',
  //         italics: 'THSarabunNew-Italic.ttf',
  //         bolditalics: 'THSarabunNew-BoldItalic.ttf'
  //     },
  //     Roboto: {
  //         normal: 'Roboto-Regular.ttf',
  //         bold: 'Roboto-Medium.ttf',
  //         italics: 'Roboto-Italic.ttf',
  //         bolditalics: 'Roboto-MediumItalic.ttf'
  //     }
  // }
  // pdfMake.vfs = pdfFonts;
  // pdfMake.fonts = {
  //   THSarabunNew: {
  //     normal: "THSarabunNew.ttf",
  //     bold: "THSarabunNew-Bold.ttf",
  //     italics: "THSarabunNew-Italic.ttf",
  //     bolditalics: "THSarabunNew-BoldItalic.ttf",
  //   },
  //   Roboto: {
  //     normal: "Roboto-Regular.ttf",
  //     bold: "Roboto-Medium.ttf",
  //     italics: "Roboto-Italic.ttf",
  //     bolditalics: "Roboto-MediumItalic.ttf",
  //   },
  // };

  var origin = window?.location?.origin;

  // playground requires you to assign document definition to a variable called dd

  var docDefinition = {
    info: {
      title: props.patientName,
      author: props.patientName,
      subject: "home isolation",
      keywords: "home isolation",
    },

    defaultStyle: {
      font: "THSarabunNew",
      // alignment: 'justify'
      lineHeight: 1,
      fontSize: 14,
    },
    footer: function (currentPage, pageCount) {
      return {
        columns: [
          {
            text:
              props.language === "TH"
                ? "F/M-02.1-BHQ-045 Rev.1 (22 Jul 2019)"
                : "F/M-02.1-BHMC-045 Rev.1 (22 Jul 2019)",
            alignment: "left",
            style: "fontFooterPage",
            margin: [40, 0, 10, 0],
          },
          {
            text: "Page " + currentPage.toString() + " / " + pageCount,
            alignment: "center",
            style: "fontFooterPage",
            margin: [0, 0, 250, 0],
          },
        ],
      };
    },
    pageMargins: [40, 120, 20, 20],
    // pageMargins: [20, 250, 20, 20],
    //A4: [595.28, 841.89]
    pageSize: "A4",
    styles: {
      facultyHeader: {
        fontSize: 16,
        bold: true,
        margin: [0, 0, 0, 0],
      },
      fontHeader: {
        fontSize: 16,
        bold: true,
      },
      fontHeaderTable: {
        fontSize: 14,
        bold: true,
      },
      fieldHeader: {
        bold: true,
        fontSize: 14,
      },
      fieldKey: {
        bold: true,
        fontSize: 13,
      },
      fieldValue: {
        fontSize: 13,
      },
      tableNormal: {
        margin: [0, 0, 0, 0],
      },
      tableWithOutBorder: {
        margin: [0, 0, 0, 0],
        border: [false, false, false, false],
      },
      tableHeader: {
        fillColor: "white",
      },
      fontDevelopTitle: {
        fontSize: 10,
      },
      fontDevelopName: {
        fontSize: 14,
        bold: true,
      },
      fontFooter: {
        fontSize: 12,
        bold: true,
      },
      fontRemark: {
        fontSize: 11,
        bold: true,
      },
      fontFooterPage: {
        fontSize: 12,
      },
      fontAnswer: {
        fontSize: 14,
        bold: true,
      },
      fontEllipsis: {
        overflow: "hidden",
        textOverflow: "ellipsis",
        width: "20px"
      }
    },
    header: {
      margin: [40, 10, 20, 0],
      stack: [
        {
          columns: [
            {
              margin: [0, 3, 0, 0],
              width: "40%",
              stack: [
                {
                  image: "logobkhpt",
                  width: 145,
                },
                {
                  margin: [0, 20, 0, 0],
                  style: "tableNormal",
                  table: {
                    widths: ["97%"],
                    heights: 31,
                    body: [
                      [
                        {
                          style: "tableHeader",
                          margin: [0, 5, 0, 0],
                          text: [
                            {
                              text:
                                props?.language === "TH"
                                  ? "แบบประเมินภาวะสุขภาพจิต PHQ-9"
                                  : "Patient Health Questionnaire PHQ-9",
                              style: "fontHeader",
                              alignment: "center",
                            },
                          ],
                        },
                      ],
                    ],
                  },
                },
              ],
            },
            // {
            //   image: "logobkhpt",
            //   width: 145,
            //   alignment: "right",
            // },
            {
              // star-sized columns fill the remaining space
              // if there's more than one star-column, available width is divided equally
              margin: [0, 0, 0, 0],
              width: "*",
              stack: [
                {
                  columns: [
                    {
                      width: "auto",
                      text: [
                        { text: "Name: ", style: "fieldKey" },
                        {
                          text: props.patientName,
                          style: "fieldValue",
                          alignment: "right",
                        },
                      ],
                    },
                    {
                      // star-sized columns fill the remaining space
                      // if there's more than one star-column, available width is divided equally
                      width: "*",
                      text: [
                        { text: "Nationality: ", style: "fieldKey" },
                        { text: props.nationality, style: "fieldValue" },
                      ],
                    },
                    {
                      // star-sized columns fill the remaining space
                      // if there's more than one star-column, available width is divided equally
                      width: "*",
                      text: [
                        { text: "Room: ", style: "fieldKey" },
                        { text: props.room, style: "fieldValue" },
                      ],
                    },
                  ],
                  columnGap: 5,
                },
                {
                  columns: [
                    {
                      width: "35%",
                      text: [
                        { text: "HN: ", style: "fieldKey" },
                        { text: props.hn, style: "fieldValue" },
                      ],
                    },
                    {
                      // star-sized columns fill the remaining space
                      // if there's more than one star-column, available width is divided equally
                      width: "*",
                      text: [
                        { text: "Physician: ", style: "fieldKey" },
                        { text: props.physician, style: "fieldValue" },
                      ],
                    },
                  ],
                  columnGap: 5,
                },
                {
                  columns: [
                    {
                      width: "35%",
                      text: [
                        { text: "Visit Date: ", style: "fieldKey" },
                        { text: props.visitDate, style: "fieldValue" },
                      ],
                    },
                    {
                      // star-sized columns fill the remaining space
                      // if there's more than one star-column, available width is divided equally
                      width: "*",
                      text: [
                        { text: "Department: ", style: "fieldKey" },
                        { text: props.department, style: "fieldValue" },
                      ],
                    },
                  ],
                  columnGap: 5,
                },
                {
                  columns: [
                    {
                      width: "35%",
                      text: [
                        { text: "Birth Date: ", style: "fieldKey" },
                        { text: props.birthDate, style: "fieldValue" },
                      ],
                    },
                    {
                      // star-sized columns fill the remaining space
                      // if there's more than one star-column, available width is divided equally
                      width: "*",
                      text: [
                        { text: "Age: ", style: "fieldKey" },
                        { text: props.age, style: "fieldValue" },
                      ],
                    },
                    {
                      // star-sized columns fill the remaining space
                      // if there's more than one star-column, available width is divided equally
                      width: "*",
                      text: [
                        { text: "Sex: ", style: "fieldKey" },
                        { text: props.sex, style: "fieldValue" },
                      ],
                    },
                  ],
                  columnGap: 5,
                },
                {
                  columns: [
                    {
                      width: "*",
                      text: [
                        { text: "Allergies: ", style: "fieldKey" },
                        { text: props.allergies, style: "fontEllipsis", width: "100px" },
                      ],
                    },
                  ],
                  columnGap: 5,
                },
                {
                  columns: [
                    {
                      width: "*",
                      text: [
                        { text: "Side Effect: ", style: "fieldKey" },
                        { text: props.sideEffect, style: "fieldValue" },
                      ],
                    },
                  ],
                  columnGap: 5,
                },
              ],
            },
          ],
          // optional space between columns
          columnGap: 5,
        },
      ],
    },
    content: [
      {
        stack: [
          {
            text:
              props?.language === "TH"
                ? "ในช่วง 2 สัปดาห์ที่ผ่านมา หรือระหว่างที่นอนพักรักษาตัวในโรงพยาบาล ท่านมีอาการดังต่อไปนี้บ่อยแค่ไหน"
                : "Over the last 2 weeks or during hospitalization, how often have you been bothered by any of the following problem?",
            style: "fieldHeader",
          },
          {
            text:
              props?.language === "TH"
                ? "กรุณาทำเครื่องหมาย √ ในช่องที่ตรงกับคำตอบของท่าน"
                : "(Please check √ the box that correspond to your answer)",
            style: "fieldHeader",
          },
        ],
      },
      {
        style: "tableNormal",
        table: {
          widths: ["5%", "*", "14%", "14%", "14%", "14%"],
          body: [
            [
              {
                text: props?.language === "TH" ? "ลำดับ" : "No.",
                style: "fontHeaderTable",
                alignment: "center",
              },
              {
                text:
                  props?.language === "TH" ? "หัวข้อประเมิน" : "Content",
                style: "fontHeaderTable",
                alignment: "center",
              },
              {
                stack: [
                  {
                    text:
                      props?.language === "TH" ? "ไม่เลย" : "Not at all",
                    style: "fontHeaderTable",
                    alignment: "center",
                  },
                  { text: "0", style: "fontHeaderTable", alignment: "center" },
                ],
              },
              {
                stack: [
                  {
                    text:
                      props?.language === "TH"
                        ? "มีบางวันไม่บ่อย"
                        : "Several days",
                    style: "fontHeaderTable",
                    alignment: "center",
                  },
                  { text: "1", style: "fontHeaderTable", alignment: "center" },
                ],
              },
              {
                stack: [
                  {
                    text:
                      props?.language === "TH"
                        ? "มีค่อนข้างบ่อย"
                        : "More than half the days",
                    style: "fontHeaderTable",
                    alignment: "center",
                  },
                  { text: "2", style: "fontHeaderTable", alignment: "center" },
                ],
              },
              {
                stack: [
                  {
                    text:
                      props?.language === "TH"
                        ? "เกือบทุกวัน"
                        : "Nearly every day",
                    style: "fontHeaderTable",
                    alignment: "center",
                  },
                  { text: "3", style: "fontHeaderTable", alignment: "center" },
                ],
              },
            ],
            [
              { text: "1.", alignment: "center" },
              {
                text:
                  props?.language === "TH"
                    ? "เบื่อทำอะไรๆ ก็ไม่เพลิดเพลิน"
                    : "Little interest or please in during things",
                margin:
                  props?.language === "TH" ? [0, 2, 0, 2] : [0, 0, 0, 0],
              },
              props.answerMentalno1 === 0
                ? { image: "checkmark", width: 16, alignment: "center" }
                : {},
              props.answerMentalno1 === 1
                ? { image: "checkmark", width: 16, alignment: "center" }
                : {},
              props.answerMentalno1 === 2
                ? { image: "checkmark", width: 16, alignment: "center" }
                : {},
              props.answerMentalno1 === 3
                ? { image: "checkmark", width: 16, alignment: "center" }
                : {},
            ],
            [
              { text: "2.", alignment: "center" },
              {
                text:
                  props?.language === "TH"
                    ? "ไม่สบายใจ ซึมเศร้า หรือท้อแท้"
                    : "Feeling down, depressed, or hopeless",
                margin:
                  props?.language === "TH" ? [0, 2, 0, 2] : [0, 0, 0, 0],
              },
              props.answerMentalno2 === 0
                ? { image: "checkmark", width: 16, alignment: "center" }
                : {},
              props.answerMentalno2 === 1
                ? { image: "checkmark", width: 16, alignment: "center" }
                : {},
              props.answerMentalno2 === 2
                ? { image: "checkmark", width: 16, alignment: "center" }
                : {},
              props.answerMentalno2 === 3
                ? { image: "checkmark", width: 16, alignment: "center" }
                : {},
            ],
            [
              { text: "3.", alignment: "center" },
              {
                lineHeight: props?.language === "TH" ? 1 : 0.8,
                text:
                  props?.language === "TH"
                    ? "หลับยาก หรือหลับๆ ตื่นๆ หรือหลับมากไป"
                    : "Trouble falling / Staying asleep, sleeping too much",
                margin:
                  props?.language === "TH" ? [0, 2, 0, 2] : [0, 0, 0, 0],
              },
              props.answerMentalno3 === 0
                ? { image: "checkmark", width: 16, alignment: "center" }
                : {},
              props.answerMentalno3 === 1
                ? { image: "checkmark", width: 16, alignment: "center" }
                : {},
              props.answerMentalno3 === 2
                ? { image: "checkmark", width: 16, alignment: "center" }
                : {},
              props.answerMentalno3 === 3
                ? { image: "checkmark", width: 16, alignment: "center" }
                : {},
            ],
            [
              { text: "4.", alignment: "center" },
              {
                text:
                  props?.language === "TH"
                    ? "เหนื่อยง่าย หรือไม่ค่อยมีแรง"
                    : "Feeling tired or having little energy",
                margin:
                  props?.language === "TH" ? [0, 2, 0, 2] : [0, 0, 0, 0],
              },
              props.answerMentalno4 === 0
                ? { image: "checkmark", width: 16, alignment: "center" }
                : {},
              props.answerMentalno4 === 1
                ? { image: "checkmark", width: 16, alignment: "center" }
                : {},
              props.answerMentalno4 === 2
                ? { image: "checkmark", width: 16, alignment: "center" }
                : {},
              props.answerMentalno4 === 3
                ? { image: "checkmark", width: 16, alignment: "center" }
                : {},
            ],
            [
              { text: "5.", alignment: "center" },
              {
                text:
                  props?.language === "TH"
                    ? "เบื่ออาหาร หรือกินมากเกินไป"
                    : "Poor appetite or overeating",
                margin:
                  props?.language === "TH" ? [0, 2, 0, 2] : [0, 0, 0, 0],
              },
              props.answerMentalno5 === 0
                ? { image: "checkmark", width: 16, alignment: "center" }
                : {},
              props.answerMentalno5 === 1
                ? { image: "checkmark", width: 16, alignment: "center" }
                : {},
              props.answerMentalno5 === 2
                ? { image: "checkmark", width: 16, alignment: "center" }
                : {},
              props.answerMentalno5 === 3
                ? { image: "checkmark", width: 16, alignment: "center" }
                : {},
            ],
            [
              { text: "6.", alignment: "center" },
              {
                lineHeight: props?.language === "TH" ? 1 : 0.8,
                text:
                  props?.language === "TH"
                    ? "รู้สึกไม่ดีกับตัวเอง-คิดว่าตัวเองล้มเหลว หรือเป็นคนทำให้ตัวเอง หรือครอบครัวผิดหวัง"
                    : "You are a failure, or have let yourself or your family down",
                margin:
                  props?.language === "TH" ? [0, 2, 0, 2] : [0, 0, 0, 0],
              },
              props.answerMentalno6 === 0
                ? { image: "checkmark", width: 16, alignment: "center" }
                : {},
              props.answerMentalno6 === 1
                ? { image: "checkmark", width: 16, alignment: "center" }
                : {},
              props.answerMentalno6 === 2
                ? { image: "checkmark", width: 16, alignment: "center" }
                : {},
              props.answerMentalno6 === 3
                ? { image: "checkmark", width: 16, alignment: "center" }
                : {},
            ],
            [
              { text: "7.", alignment: "center" },
              {
                lineHeight: props?.language === "TH" ? 1 : 0.8,
                text:
                  props?.language === "TH"
                    ? "สมาธิไม่ดีเวลาทำอะไร เช่น ดูโทรทัศน์ ฟังวิทยุ หรือทำงานที่ต้องใช้ความตั้งใจ"
                    : "Trouble concentration on things, such as reading the newspaper or watching television",
                margin:
                  props?.language === "TH" ? [0, 2, 0, 2] : [0, 0, 0, 0],
              },
              props.answerMentalno7 === 0
                ? { image: "checkmark", width: 16, alignment: "center" }
                : {},
              props.answerMentalno7 === 1
                ? { image: "checkmark", width: 16, alignment: "center" }
                : {},
              props.answerMentalno7 === 2
                ? { image: "checkmark", width: 16, alignment: "center" }
                : {},
              props.answerMentalno7 === 3
                ? { image: "checkmark", width: 16, alignment: "center" }
                : {},
            ],
            [
              { text: "8.", alignment: "center" },
              {
                lineHeight: props?.language === "TH" ? 1 : 0.8,
                text:
                  props?.language === "TH"
                    ? "พูดหรือทำอะไรช้าจนคนอื่นมองเห็น หรือกระสับกระส่ายจนท่านไม่อยู่นิ่งเหมือนเคย"
                    : "Moving or speaking so slowly that other people could have noticed, or being so fidgety or restless that you have been moving around more than usual",
                margin:
                  props?.language === "TH" ? [0, 2, 0, 2] : [0, 0, 0, 0],
              },
              props.answerMentalno8 === 0
                ? { image: "checkmark", width: 16, alignment: "center" }
                : {},
              props.answerMentalno8 === 1
                ? { image: "checkmark", width: 16, alignment: "center" }
                : {},
              props.answerMentalno8 === 2
                ? { image: "checkmark", width: 16, alignment: "center" }
                : {},
              props.answerMentalno8 === 3
                ? { image: "checkmark", width: 16, alignment: "center" }
                : {},
            ],
            [
              { text: "9.", alignment: "center" },
              {
                lineHeight: props?.language === "TH" ? 1 : 0.8,
                text:
                  props?.language === "TH"
                    ? "คิดทำร้ายตนเอง หรือคิดว่าถ้าตายๆ ไปเสียคงดี"
                    : "Thoughts that you would be better off dead or of hurting yourself in some way",
                margin:
                  props?.language === "TH" ? [0, 2, 0, 2] : [0, 0, 0, 0],
              },
              props.answerMentalno9 === 0
                ? { image: "checkmark", width: 16, alignment: "center" }
                : {},
              props.answerMentalno9 === 1
                ? { image: "checkmark", width: 16, alignment: "center" }
                : {},
              props.answerMentalno9 === 2
                ? { image: "checkmark", width: 16, alignment: "center" }
                : {},
              props.answerMentalno9 === 3
                ? { image: "checkmark", width: 16, alignment: "center" }
                : {},
            ],
            [
              {
                text: props.language === "TH" ? "รวม" : "Sum",
                alignment: "right",
                style: "fontHeaderTable",
                colSpan: 2,
                margin:
                  props?.language === "TH" ? [0, 2, 0, 2] : [0, 0, 0, 0],
              },
              {},
              {
                text: props.answerMentalSum0,
                alignment: "center",
                style: "fontHeaderTable",
              },
              {
                text: props.answerMentalSum1,
                alignment: "center",
                style: "fontHeaderTable",
              },
              {
                text: props.answerMentalSum2,
                alignment: "center",
                style: "fontHeaderTable",
              },
              {
                text: props.answerMentalSum3,
                alignment: "center",
                style: "fontHeaderTable",
              },
            ],
            [
              {
                text:
                  props.language === "TH"
                    ? "คะแนนรวมทั้งหมด ="
                    : "Total score =",
                alignment: "right",
                style: "fontHeaderTable",
                colSpan: 2,
                margin:
                  props?.language === "TH" ? [0, 2, 0, 2] : [0, 0, 0, 0],
              },
              {},
              {
                text: props.answerMentalTotal,
                alignment: "center",
                style: "fontHeaderTable",
                colSpan: 4,
                margin:
                  props?.language === "TH" ? [0, 2, 0, 2] : [0, 0, 0, 0],
              },
              {},
              {},
              {},
            ],
          ],
        },
      },
      {
        style: "tableNormal",
        margin: [0, 10, 0, 2],
        table: {
          widths: ["60%", "*"],
          body: [
            [
              {
                stack: [
                  {
                    text:
                      props.language === "TH"
                        ? "ถ้าท่านตอบว่ามีอาการ ไม่ว่าในข้อใดก็ตาม อาการนั้นๆ ทำให้ท่านมีปัญหาใน การทำงาน การดูแลสิ่งต่างๆ ในบ้าน หรือการเข้ากับผู้คนหรือไม่"
                        : "(If you checked off any problem on this questionnaire so far, how difficult have these problems made it for you to do your work, take care of things at home, or get along with other people?",
                    alignment: "left",
                  },
                  {
                    text:
                      props.language === "TH"
                        ? "(กรุณาทำเครื่องหมาย √ ในช่องที่ตรงกับคำตอบของท่าน)"
                        : "(Please check  √ the box that correspond to your answer)",
                    alignment: "left",
                  },
                ],
              },
              {
                columns: [
                  [
                    {
                      columns: [
                        props.answerDifficult === "Not difficult at all"
                          ? { image: "checked", width: 16 }
                          : { image: "uncheck", width: 16 },
                        {
                          width: "*",
                          text: [
                            {
                              text:
                                props.language === "TH"
                                  ? "ไม่มีปัญหาเลย"
                                  : "Not difficult at all",
                            },
                          ],
                        },
                        props.answerDifficult === "Somewhat difficult"
                          ? { image: "checked", width: 16 }
                          : { image: "uncheck", width: 16 },
                        {
                          // star-sized columns fill the remaining space
                          // if there's more than one star-column, available width is divided equally
                          width: "*",
                          text: [
                            {
                              text:
                                props.language === "TH"
                                  ? "มีปัญหาบ้าง"
                                  : "Somewhat difficult",
                            },
                          ],
                        },
                      ],
                      columnGap: 5,
                    },

                    {
                      columns: [
                        props.answerDifficult === "Very difficult"
                          ? { image: "checked", width: 16 }
                          : { image: "uncheck", width: 16 },
                        {
                          width: "*",
                          text: [
                            {
                              text:
                                props.language === "TH"
                                  ? "มีปัญหามาก"
                                  : "Very difficult",
                            },
                          ],
                        },
                        props.answerDifficult === "Extremely difficult"
                          ? { image: "checked", width: 16 }
                          : { image: "uncheck", width: 16 },
                        {
                          // star-sized columns fill the remaining space
                          // if there's more than one star-column, available width is divided equally
                          width: "*",
                          text: [
                            {
                              text:
                                props.language === "TH"
                                  ? "มีปัญหามากที่สุด"
                                  : "Extremely difficult",
                            },
                          ],
                        },
                      ],
                      // optional space between columns
                      columnGap: 5,
                    },
                  ],
                ],
              },
            ],
          ],
        },
      },
      {
        margin: [10, 0, 0, 0],
        stack: [
          {
            columns: [
              props.unableToAccess === true
                ? { image: "checked", width: 16 }
                : { image: "uncheck", width: 16 },
              {
                margin: [5, 0, 0, 0],
                text:
                  props.language === "TH"
                    ? " ไม่สามารถประเมินภาวะสุขภาพจิตได้ในขณะนี้ "
                    : " Unable to assess the mental health at this time",
              },
            ],
          },
          {
            text: [
              {
                text:
                  props.language === "TH"
                    ? "กรณีคะแนนประเมินอยู่ระหว่าง 10 –27 คะแนน รายงานแพทย์เจ้าของไข้ "
                    : "If total score is between 10 and 27, Notify primary physician ",
              },
              { text: "", style: "fontAnswer" },
            ],
          },
          {
            columns: [
              props.consultPsychiatrist === true
                ? { image: "checked", width: 16 }
                : { image: "uncheck", width: 16 },
              {
                width: "20%",
                text: [
                  {
                    text:
                      props.language === "TH"
                        ? "ปรึกษาจิตแพทย์"
                        : "Consult psychiatrist",
                  },
                ],
              },
              props.consultPsychologist === true
                ? { image: "checked", width: 16 }
                : { image: "uncheck", width: 16 },
              {
                width: "30%",
                text: [
                  {
                    text:
                      props.language === "TH"
                        ? "ปรึกษานักจิตวิทยาคลีนิค"
                        : "Consult psychologist Date ",
                  },
                ],
              },
              {
                width: "*",
                text: [
                  {
                    text:
                      props.language === "TH"
                        ? "วันที่ " +
                        props.consultPsychologistDate +
                        " เวลา " +
                        props.consultPsychologistTime +
                        " น."
                        : "Date " +
                        props.consultPsychologistDate +
                        " Time " +
                        props.consultPsychologistTime,
                  },
                ],
              },
            ],
            columnGap: 5,
          },
          {
            columns: [
              props.notConsultPsy === true
                ? { image: "checked", width: 16 }
                : { image: "uncheck", width: 16 },
              {
                margin: [5, 0, 0, 0],
                text:
                  props.language === "TH"
                    ? "พิจารณายังไม่ส่งปรึกษาจิตแพทย์/ นักจิตวิทยาคลีนิค "
                    : "Not consult psychiatrist / psychologist",
              },
            ],
          },
        ],
      },
      {
        margin: [0, 5, 0, 0],
        columns: [
          {
            width: "55%",
            text: [
              {
                text:
                  props.language === "TH"
                    ? "พยาบาลผู้รายงาน "
                    : "Registered Nurse ",
              },
              { text: props.registeredNurse, style: "fontAnswer" },
            ],
          },
          {
            // star-sized columns fill the remaining space
            // if there's more than one star-column, available width is divided equally
            width: "*",
            margin: [18, 0, 0, 0],
            text: [
              {
                text:
                  props.language === "TH"
                    ? "วันที่ " +
                    props.registeredNurseDate +
                    " เวลา " +
                    props.registeredNurseTime +
                    " น."
                    : "Date " +
                    props.registeredNurseDate +
                    " Time " +
                    props.registeredNurseTime,
              },
            ],
          },
        ],
        columnGap: 5,
      },
      {
        margin: [0, 2, 0, 0],
        text: [
          { text: "พัฒนาโดย", style: "fontDevelopTitle" },
          {
            text: " Drs. Robert L. Spitzer, Janet B.W. Williams, Kurt Kroenke",
            style: "fontDevelopName",
          },
          {
            text: " และคณะ โดยได้รับทุนสนับสนุนการศึกษาจาก",
            style: "fontDevelopTitle",
          },
          { text: " Pfizer Inc", style: "fontDevelopName" },
        ],
      },
      {
        margin: [10, 3, 0, 0],
        columns: [
          {
            width: "75%",
            stack: [
              {
                text:
                  "Remark : Please mark “N/A“ under the item that is not applicable",
                style: "fontRemark",
                margin: [0, 0, 0, -3],
              },
              {
                columns: [
                  {
                    width: "25%",
                    text: "Dangerous abbreviations :",
                    style: "fontRemark",
                  },
                  {
                    // star-sized columns fill the remaining space
                    // if there's more than one star-column, available width is divided equally
                    width: "*",
                    stack: [
                      {
                        text:
                          "1) U 2) IU 3) Q.D. 4) Q.O.D. 5) MS 6) MS04 7) MgS04 8) Never write “0” after",
                        style: "fontRemark",
                      },
                      {
                        text:
                          "a decimal point [X mg] 9) always use “0” before a decimal point [0.X mg] 10) OD",
                        style: "fontRemark",
                        margin: [0, -3, 0, 0],
                      },
                    ],
                  },
                ],
                columnGap: 5,
              },
            ],
          },
          {
            // star-sized columns fill the remaining space
            // if there's more than one star-column, available width is divided equally
            width: "*",
            margin: [0, 3, 0, 0],
            stack: [
              { text: "Scanned by", style: "fontFooter", alignment: "center" },
              {
                text: " " + props.scanBy + " ",
                style: "fontFooter",
                alignment: "center",
                decoration: "underline",
              },
            ],
          },
        ],
        columnGap: 5,
      },
    ],
    images: {
      logobkhpt: origin + "/static/images/logobkhpt.png",
      checked: origin + "/static/images/checked.png",
      uncheck: origin + "/static/images/uncheck.png",
      checkmark: origin + "/static/images/checkmark.png",
    },
  };

  var win = window.open("", "_blank");
  pdfMake.createPdf(docDefinition).open({}, win);
};


