/*eslint no-unused-vars: 0*/
import { app, db, functions, ui, storage } from "./Firebase";
import * as firebaseui from "firebaseui";

export type AppHandler<S=any, E=any> 
  = (controller: AppController<S, E>, params?: any) => any

export default class AppController<S, E> {
  getState: () => S;
  setState: (state: S, callback?: any) => void;
  window: any;
  app: any;
  user: any;
  db: app.firestore.Firestore;
  functions: app.functions.Functions;
  storage: app.storage.Storage;
  ui: firebaseui.auth.AuthUI
  PROTOTYPE: boolean = true;

  constructor(
    getState: () => S,
    setState: (state: S, callback?: any) => void,
    window: any
  ) {
    this.getState = getState;
    this.setState = setState;
    this.window = window;

    console.log(" Firebase init !! ")
    // Firebase stuff
    this.app = app;
    this.db = db;
    this.functions = functions;
    this.storage = storage;
    this.ui = ui;
  }

  handleEvent: (e: E) => any = async (e : E) => {
    // TO IMPLEMENT
  }
}
