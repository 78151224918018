import React, { useState, useRef, useImperativeHandle, forwardRef, useEffect } from "react";
import { Input, Modal } from "semantic-ui-react";
import CardPatientSearchCU from "./CardPatientSearchCU";
import PropTypes from "prop-types";
import CardPatientOldName from "./CardPatientOldName";
import axios from "axios";

const PatientSearchBoxCU = forwardRef((props: any, ref) => {
  const isMounted = useRef(true);
  const [openCardPatientSearch, setOpenCardPatientSearch] = useState(false);
  const [openCardPatientOldName, setOpenCardPatientOldName] = useState(false);
  const [patientFullName, setPatientFullName] = useState(null);
  const [hnInput, setHNInput] = useState(props.initialValue);
  const inputRef = useRef();

  const [axioSource, setAxioSource] = useState(null);

  const [patientsLoading, setPatientsLoading] = useState(false);
  const [patientOldNamesLoading, setPatientOldNamesLoading] = useState(false);
  const [patients, setPatients] = useState([]);
  const [nationalities, setNationalities] = useState([]);
  const [patientOldNames, setPatientOldNames] = useState([]);
  const [numberOfPage, setNumberOfPage] = useState(props.numberOfPage);
  const [currentPage, setCurrentPage] = useState(props.currentPage);

  const [patientId, setPatientId] = useState(null);
  const [isAPILoading, setIsAPILoading] = useState(false);

  const [prevHn, setPrevHn] = useState("");

  useImperativeHandle(ref, () => ({
    focus: () => {
      (inputRef as any).current.focus();
    },
    clear: () => {
      setHNInput("");
    },
    openPatientSearch: () => {
      setOpenCardPatientSearch(true);
    },
    value: () => {
      return hnInput;
    },
    setValue: (hn: any) => {
      setHNInput(hn);
    },
    getPatientId: () => {
      return patientId;
    },
  }));

  const handleCloseCardPatientSearch = () => {
    if (props.clearHnBeforeSearch) {
      setHNInput(prevHn);
    }
    setOpenCardPatientSearch(false);
  };

  const handleCloseCardPatientOldName = () => {
    setOpenCardPatientOldName(false);
  };

  const handleOnChangeHN = (e: any) => {
    console.log(5);
    setHNInput(e.target.value);
  };

  const handleOpenCardPatientSearch = () => {
    if (props.clearHnBeforeSearch) {
      setPrevHn(hnInput);
      setHNInput("");
    }
    setOpenCardPatientSearch(true);
  };

  const handleOpenModPatientOldName = (patientId: any, hn: any, fullName: any) => {
    setOpenCardPatientOldName(true);
    // setHNInput(hn);
    setPatientFullName(fullName);
    if (props.onGetPatientOldName) {
      props.onGetPatientOldName(patientId);
    } else {
      loadPatientOldName(patientId);
    }
  };

  /* For controller */
  const loadPatients = async (params: any) => {
    if (!props.controller) {
      return;
    }
    if (axioSource) {
      (axioSource as any).cancel("cancel");
    }
    const source = axios.CancelToken.source();
    setIsAPILoading(true);
    setAxioSource(source as any);

    let pCurrentPage = params.patientCurrentPage;
    if (!params.patientCurrentPage) {
      pCurrentPage = currentPage;
    }
    setCurrentPage(pCurrentPage);

    // Create params
    let newParams = {
      ...params,
      patientCurrentPage: pCurrentPage,
      ...(source && { cancelToken: source.token }),
    };

    const [data, error, totalPage] = await props.controller.getPatientList(newParams);

    if (error === "cancel") {
      console.log("Cancel Not Stop Loading");
    } else {
      console.log(" setIsAPILoading false");
      setIsAPILoading(false);
    }

    if (data) {
      setPatients(data);
      setNumberOfPage(totalPage);
    }
  };

  const loadNationality = async () => {
    if (!props.controller) {
      return;
    }
    const [data, error] = await props.controller.getNatinalities();
    if (data) {
      setNationalities(data);
    }
  };

  const handlePaginationChange = async (params: any) => {
    await loadPatients(params);
  };

  const loadPatientOldName = async (patientId: any) => {
    if (!props.controller) {
      return;
    }
    const [data, error] = await props.controller.getPatientOldName(patientId);
    if (data) {
      setPatientOldNames(data);
    }
  };

  const handleOnKeyPressHN = async (ev: any) => {
    let hn = ev.target.value.toUpperCase();
    if (props.systemHn8Digit) {
      hn = formatHn8Digit(hn);
    }
    if (ev.key === "Enter") {
      ev.preventDefault()
      let [res, err, network] = [null, null, null];
      if (props.controller) {
        [res, err, network] = await props.controller.getPatientByHN(hn);
      }
      if (props.onGetPatientByHN) {
        [res, err, network] = await props.onGetPatientByHN(hn);
      }

      if (!(network as any)?.data?.id) {
        handleOpenCardPatientSearch();
      } else {
        const { id, hn, full_name } = (network as any).data;
        props.onEnter && props.onEnter(id, hn, full_name);
      }
    }
  };

  const formatHn8Digit = (hn: any) => {
    let cloneHN: string = hn;
    if (/(^p|^P)/.test(cloneHN)) {
      cloneHN = cloneHN.toUpperCase();
    } else if (typeof cloneHN === "string") {
      if (cloneHN.includes("-")) {
        cloneHN = cloneHN.replace(/-/g, "");
      }
      if (cloneHN.length < 8 && cloneHN.length >= 3) {
        const last = cloneHN.slice(2);
        const start = cloneHN.slice(0, 2);
        cloneHN = `${start}${"0".padEnd(8 - cloneHN.length, "0")}${last}`;
      }
    }
    return cloneHN;
  };
  /********************* */

  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  useEffect(() => {
    if (props.patientList) {
      setPatients(props.patientList);
    }
  }, [props.patientList]);

  useEffect(() => {
    if (props.nationalitiesList) {
      setNationalities(props.nationalitiesList);
    }
  }, [props.nationalitiesList]);

  useEffect(() => {
    if (props.patientOldNameDataList) {
      setPatientOldNames(props.patientOldNameDataList);
    }
  }, [props.patientOldNameDataList]);

  useEffect(() => {
    if (props.numberOfPage !== null) {
      setNumberOfPage(props.numberOfPage);
    }
  }, [props.numberOfPage]);

  useEffect(() => {
    if (props.currentPage !== null) {
      setCurrentPage(props.currentPage);
    }
  }, [props.currentPage]);

  useEffect(() => {
    if (props.patientListLoading !== null) {
      setPatientsLoading(props.patientListLoading);
    }
  }, [props.patientListLoading]);

  useEffect(() => {
    if (props.patientOldNameLoading) {
      setPatientOldNamesLoading(props.patientOldNameLoading);
    }
  }, [props.patientOldNameLoading]);

  useEffect(() => {
    if (patients.length !== 0) {
      setPatients([]);
    }

    if (props.onSelectPatient) {
      props.onSelectPatient(null, hnInput);
    }
  }, [hnInput]);

  // For controller
  useEffect(() => {
    if (props.controller) {
    }
  }, [props.controller]);

  return (
    <React.Fragment>
      <Input
        id={"InputPatientSearchBox"}
        ref={inputRef as any}
        value={hnInput === "" ? props.defaultValue : hnInput}
        disabled={props.disabled}
        // @ts-ignore
        onChange={props.forcedSelectHN ? null : handleOnChangeHN}
        onKeyPress={handleOnKeyPressHN}
        action={{
          icon: props.forcedSelectHN ? "pin" : "search",
          onClick: (event: any) => {
            if (!props.forcedSelectHN) {
              setHNInput("");
              handleOpenCardPatientSearch();
            }
          },
          type: "button",
        }}
        fluid={props.fluid}
      />
      <Modal open={openCardPatientSearch} size={props.modalSize}>
        <CardPatientSearchCU
          hnPreData={hnInput}
          // @ts-ignore
          isAPILoading={isAPILoading}
          // Data
          nationalitiesList={nationalities}
          patientListLoading={patientsLoading}
          patientList={patients}
          numberOfPage={numberOfPage}
          currentPage={currentPage}
          inputChangeAutoSearch={props.inputChangeAutoSearch}
          systemHn8Digit={props.systemHn8Digit}
          // callback
          onPaginationChange={props.onPatientListPaginationChange ? props.onPatientListPaginationChange : handlePaginationChange}
          hideCallBack={handleCloseCardPatientSearch}
          hideColumnsByAccessor={props.hideColumnsByAccessor}
          hideNationality={props.hideNationality}
          hideCitizenID={props.hideCitizenID}
          getNationality={props.getNationality ? props.getNationality : loadNationality}
          getPatientList={props.getPatientList ? props.getPatientList : loadPatients}
          onSelectPatient={(id, hn, name) => {
            console.log(id, hn, name);
            setHNInput(hn);
            setPatientId(id);
            setTimeout(() => {
              props.onSelectPatient(id, hn, name);
              handleCloseCardPatientSearch();
            }, 0);
          }}
          formatHn8Digit={formatHn8Digit}
          onOpenModPatientOldName={handleOpenModPatientOldName}
        />
      </Modal>
      <Modal open={openCardPatientOldName}>
        <CardPatientOldName
          hideCallBack={handleCloseCardPatientOldName}
          hn={hnInput}
          fullName={patientFullName}
          patientOldNameLoading={patientOldNamesLoading}
          patientOldNameDataList={patientOldNames}
        />
      </Modal>
    </React.Fragment>
  );
});

PatientSearchBoxCU.defaultProps = {
  // Initial data
  initialValue: "",
  defaultValue: "",
  patientListLoading: false,
  patientOldNameLoading: false,
  forcedSelectHN: null,
  disabled: false,
  fluid: false,
  modalSize: "large",

  // Return data
  patientList: [],
  nationalitiesList: [],
  patientOldNameDataList: [],
  // Pagination
  numberOfPage: 1,
  currentPage: 1,

  // Loading function
  getPatientList: null,
  getNationality: null,
  onGetPatientOldName: null,
  onGetPatientByHN: null,
  onPatientListPaginationChange: null,

  // Callback function
  onSelectPatient: () => { },
  onEnter: () => { },

  // Controller
  controller: null,
  hideColumnsByAccessor: [],
  hideNationality: false,
  hideCitizenID: false,
  clearHnBeforeSearch: false,
  inputChangeAutoSearch: true,
  systemHn8Digit: false,
};

PatientSearchBoxCU.propTypes = {
  // Initial data
  initialValue: PropTypes.string,
  defaultValue: PropTypes.string,
  patientListLoading: PropTypes.bool,
  patientOldNameLoading: PropTypes.bool,
  forcedSelectHN: PropTypes.string,
  disabled: PropTypes.bool,
  fluid: PropTypes.bool,
  modalSize: PropTypes.string,

  // Return data
  patientList: PropTypes.array,
  nationalitiesList: PropTypes.array,
  patientOldNameDataList: PropTypes.array,
  // Pagination
  numberOfPage: PropTypes.number,
  currentPage: PropTypes.number,

  // Loading function
  getPatientList: PropTypes.func,
  getNationality: PropTypes.func,
  onGetPatientOldName: PropTypes.func,
  onGetPatientByHN: PropTypes.func,
  onPatientListPaginationChange: PropTypes.func,

  // Calllback function
  onSelectPatient: PropTypes.func,

  onEnter: PropTypes.func,
  // Controller
  controller: PropTypes.object,
  hideColumnsByAccessor: PropTypes.array,
  hideNationality: PropTypes.bool,
  hideCitizenID: PropTypes.bool,
  clearHnBeforeSearch: PropTypes.bool,
  inputChangeAutoSearch: PropTypes.bool,
  systemHn8Digit: PropTypes.bool,
};

export default React.memo(PatientSearchBoxCU);
