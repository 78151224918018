import WasmController from 'react-lib/frameworks/WasmController'
import * as FirebaseI from 'react-lib/frameworks/FirebaseInterface'
import * as NurseI from './NurseInterface'

export default class NurseController extends WasmController<NurseI.State, NurseI.Event, NurseI.Data> {

  constructor(getState: () => NurseI.State, setState: (state: NurseI.State) => void, window: any) {
    super(getState, setState, window, NurseI.DataInitial)
  }

  handleEvent = (e: NurseI.Event) => {
    console.log(e)
    switch (e.message) {
      case "DidMount":
        NurseI.DidMount(this, e.params)
        return

      case "LoginFirebase":
        NurseI.LoginFirebase(this, e.params)
        return

      case "GetPatientSegment":
        NurseI.GetPatientSegment(this, e.params)
        return
      case "GetDiagFormById":
        NurseI.GetDiagFormById(this, e.params)
        return
      case "GetDivisionProfile":
        NurseI.GetDivisionProfile(this, e.params)
        return
      default:
        let testExhausitve: never = e
    }
    console.log("-- Unhandled case")
  }

}