/*eslint indent: 0, quotes: 0, semi: 0, no-unused-vars: 0, no-var: 0*/
export default (function() {
  /**
   * XmlHttpRequest's getAllResponseHeaders() method returns a string of response
   * headers according to the format described here:
   * http://www.w3.org/TR/XMLHttpRequest/#the-getallresponseheaders-method
   * This method parses that string into a user-friendly key/value pair object.
   */
  var parseResponseHeaders = function(headerStr: string) {
      var headers:any = {};
      if (!headerStr) {
          return headers;
      }
      var headerPairs:any = headerStr.split('\u000d\u000a');
      for (var i = 0, len = headerPairs.length; i < len; i++) {
          var headerPair = headerPairs[i];
          var index = headerPair.indexOf('\u003a\u0020');
          if (index > 0) {
              var key = headerPair.substring(0, index);
              var val = headerPair.substring(index + 2);
              headers[key] = val;
          }
      }
      return headers;
  }
  var printURL = function(pdf_url:any) {
      console.log('common Local printing...' + pdf_url);
      var iframe:any = document.getElementById('vc_local_print');
      if (iframe !== null) {
          document.body.removeChild(iframe)
      }

      // recreate iframe everytime to reliably display print dialog
      iframe = document.createElement('iframe');
      iframe.id = 'vc_local_print';
      iframe.style.display = 'none';
      iframe.addEventListener('load', function () {
          if (iframe.src === '' || iframe.src === 'about:blank') {
              return;
          }
          iframe.contentWindow.print();
      })
      document.body.appendChild(iframe);
      iframe.src = pdf_url;
  }
  var localPrint = function(xhr:any) {
    //   var headerText = xhr.getAllResponseHeaders();
      var headerText = xhr.headers
    //   if (headerText.indexOf('x-local-print') > -1) {
      if (headerText['x-local-print']) {
        //   var headers = parseResponseHeaders(headerText);
          var print_id = headerText['x-local-print'];
          printURL('/users/apis/local-print/' + print_id);
      }
  }
  return localPrint
})();