import React, { useEffect, useRef, useState, useImperativeHandle } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import CardLayout from "../../common/CardLayout";
import ModAuthen from "../../common/ModAuthen";
import PureReactTable from "../../common/PureReactTable";
import { Button, TextArea } from "semantic-ui-react";
import { toast } from "react-toastify";
import { useCookies } from "react-cookie";

var beToday = moment().add(543, "years");
const beFormatDate = "DD/MM/YYYY";
const beFormatTime = "HH:mm";
var DEFAULT_NOTE = {
  id: "mockId"
};

const CardNurseNote = React.forwardRef((props, ref) => {
  const isMounted = useRef(true);
  const [cookies] = useCookies([]);

  const [openModal, setOpenModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [authenError, setAuthenError] = React.useState(null);
  const [originalNurseNoteItem, setOriginalNurseNoteItem] = React.useState([]);
  // const [selectedRow, setSelectedRow] = React.useState(null);

  const dateRef = React.useRef([]);
  const diagnosisRef = React.useRef();
  const planRef = React.useRef();
  const goalRef = React.useRef();
  const modAuthenRef = React.useRef();

  const [nurseNoteItemList, setNurseNoteItemList] = useState([DEFAULT_NOTE]);
  const [payload, setPayload] = useState({});
  const [selectedCell, setSelectedCell] = useState({});

  useImperativeHandle(ref, () => ({
    getNoteItem: () => {
      getNoteItem()
    }
  }))

  const columns = [
    {
      Header: "วันเวลาที่บันทึก",
      accessor: "formatted_date",
      width: 150,
      Cell: row => {
        return (
          <label
            ref={el => (dateRef.current[row.index] = el)}
            style={{ width: "100%" }}
          >
            {row.original.formatted_date
              ? row.original.formatted_date
              : moment()
                .add(543, "years")
                .format(beFormatDate + " " + beFormatTime)}
          </label>
        );
      }
    },
    {
      Header: "ข้อมูล/อาการ/ปัญหา",
      accessor: "diagnosis",
      minWidth: 200,
      Cell: row => {
        if (
          selectedCell &&
          selectedCell.hasOwnProperty("diagnosis") &&
          row.index === selectedCell.diagnosis
        ) {
          return (
            <TextArea
              autoFocus
              key={"diagnosis" + row.index}
              ref={el => (diagnosisRef.current = el)}
              style={{ width: "100%" }}
              onBlur={e => {
                const { value } = e.target;
                if (
                  nurseNoteItemList[row.index].diagnosis ===
                  diagnosisRef.current.ref.current.value ||
                  !diagnosisRef.current.ref.current.value
                ) {
                  return setSelectedCell({});
                }
                handleSetData({ value, field: "diagnosis", index: row.index });
              }}
              onKeyPress={e => {
                const { value } = e.target;
                if (e.key === "Enter" && e.shiftKey) {
                  let text = diagnosisRef.current.ref.current.value;
                  e.preventDefault();
                  diagnosisRef.current.ref.current.value = text + "\n";
                } else if (e.key === "Enter") {
                  if (
                    nurseNoteItemList[row.index].diagnosis ===
                    diagnosisRef.current.ref.current.value ||
                    !diagnosisRef.current.ref.current.value
                  ) {
                    return setSelectedCell({});
                  }
                  handleSetData({
                    value,
                    field: "diagnosis",
                    index: row.index
                  });
                }
              }}
            />
          );
        }
        return <label>{row.original.diagnosis}</label>;
      }
    },
    {
      Header: "การพยาบาล/ให้คำปรึกษา",
      accessor: "plan",
      minWidth: 200,
      Cell: row => {
        if (
          selectedCell &&
          selectedCell.hasOwnProperty("plan") &&
          row.index === selectedCell.plan
        ) {
          return (
            <TextArea
              autoFocus
              key={"plan" + row.index}
              ref={el => (planRef.current = el)}
              style={{ width: "100%" }}
              // value={row.original.plan}
              onBlur={e => {
                const { value } = e.target;
                if (
                  nurseNoteItemList[row.index].plan ===
                  planRef.current.ref.current.value ||
                  !planRef.current.ref.current.value
                ) {
                  return setSelectedCell({});
                }
                handleSetData({ value, field: "plan", index: row.index });
              }}
              onKeyPress={e => {
                const { value } = e.target;
                if (e.key === "Enter" && e.shiftKey) {
                  let text = planRef.current.ref.current.value;
                  e.preventDefault();
                  planRef.current.ref.current.value = text + "\n";
                } else if (e.key === "Enter") {
                  if (
                    nurseNoteItemList[row.index].plan ===
                    planRef.current.ref.current.value ||
                    !planRef.current.ref.current.value
                  ) {
                    return setSelectedCell({});
                  }
                  handleSetData({ value, field: "plan", index: row.index });
                }
              }}
            />
          );
        }
        return <label>{row.original.plan}</label>;
      }
    },
    {
      Header: "ผลการพยาบาล",
      accessor: "goal",
      minWidth: 200,
      Cell: row => {
        if (
          selectedCell &&
          selectedCell.hasOwnProperty("goal") &&
          row.index === selectedCell.goal
        ) {
          return (
            <TextArea
              autoFocus
              key={"goal" + row.index}
              ref={el => (goalRef.current = el)}
              style={{ width: "100%" }}
              // value={row.original.goal}
              onBlur={e => {
                const { value } = e.target;
                if (
                  nurseNoteItemList[row.index].goal ===
                  goalRef.current.ref.current.value ||
                  !goalRef.current.ref.current.value
                ) {
                  return setSelectedCell({});
                }
                handleSetData({ value, field: "goal", index: row.index });
              }}
              onKeyPress={e => {
                const { value } = e.target;
                if (e.key === "Enter" && e.shiftKey) {
                  let text = goalRef.current.ref.current.value;
                  e.preventDefault();
                  goalRef.current.ref.current.value = text + "\n";
                } else if (e.key === "Enter") {
                  if (
                    nurseNoteItemList[row.index].goal ===
                    goalRef.current.ref.current.value ||
                    !goalRef.current.ref.current.value
                  ) {
                    return setSelectedCell({});
                  }
                  handleSetData({ value, field: "goal", index: row.index });
                }
              }}
            />
          );
        }
        return <label>{row.original.goal}</label>;
      }
    }
  ];

  const handleSetData = ({ value, field, index } = {}) => {
    let newArr = [...nurseNoteItemList];
    let newObj = { ...nurseNoteItemList[index] };
    newObj[field] = value;
    newArr[index] = newObj;
    setNurseNoteItemList(newArr);
    setSelectedCell({});
  };

  useEffect(() => {
    if (props.encounterId) {
      getNote();
    }
  }, [props.encounterId]);

  const getNote = async () => {
    setLoading(true);
    const [data, error] = await props.controller.geNurseNoteEncounter({
      apiToken: cookies.apiToken ? cookies.apiToken : props.apiToken,
      encounterId: props.encounterId
    });

    if (isMounted.current) {
      setLoading(false);
      if (error) {
        toast.error(error);
      } else {
        if (data) {
          // setNurseNoteItemList(data.items)
          setPayload({ ...payload, nurseNoteId: data.id });
        } else {
          setPayload({ ...payload, nurseNoteId: null });
        }
      }
    }
  };

  useEffect(() => {
    if (payload.nurseNoteId) {
      getNoteItem();
    }
  }, [payload.nurseNoteId]);

  const getNoteItem = async () => {
    setLoading(true);
    const params = {
      apiToken: props.apiToken ? props.apiToken : cookies.apiToken
    }
    if (!props.isGetNurseNoteByEncounter) {
      params.nurseNoteId = payload.nurseNoteId
    } else {
      params.encounterId = props.encounterId
    }

    const [data, error] = await props.controller.getNurseNoteItem({
      ...params
      // fromDate: null,
      // toDate: null
    });
    if (isMounted.current) {
      setLoading(false);
      if (error) {
        toast.error(error);
      } else {
        setOriginalNurseNoteItem(data.items);
        setNurseNoteItemList([...data.items, DEFAULT_NOTE]);
      }
    }
  };

  const handleSave = async ({ username, password } = {}) => {
    const savingPromise = [];
    setLoading(true);
    for (var index in nurseNoteItemList) {
      if (index === nurseNoteItemList.length - 1) {
        continue;
      }
      let objIndex = originalNurseNoteItem.findIndex(
        item => item.id === nurseNoteItemList[index].id
      );
      if (objIndex < 0) {
        continue;
      }
      if (
        originalNurseNoteItem[objIndex].plan ===
        nurseNoteItemList[index].plan &&
        originalNurseNoteItem[objIndex].goal ===
        nurseNoteItemList[index].goal &&
        originalNurseNoteItem[objIndex].diagnosis ===
        nurseNoteItemList[index].diagnosis
      )
        continue;
      const prom = props.controller.putNurseNoteItem({
        date: beToday.format(beFormatDate),
        time: beToday.format(beFormatTime),
        saveData: nurseNoteItemList[index],
        username,
        password,
        nurseNoteId: nurseNoteItemList[index].nurse_note,
        token: cookies.csrftoken,
        encounter: props.encounterId,
        nurseNoteItemId: nurseNoteItemList[index].id,
        apiToken: props.apiToken ? props.apiToken : cookies.apiToken,
        division: props.division ? props.division : cookies.division_id
      });
      savingPromise.push(prom);
    }
    if (payload.diagnosis || payload.plan || payload.goal) {
      const postProm = props.controller.postNurseNoteItem({
        ...payload,
        username: username,
        password: password,
        nurseNoteId: payload.nurseNoteId
      });
      savingPromise.push(postProm);
    }
    Promise.all(savingPromise).then(response => {
      if (isMounted.current) {
        setLoading(false);
        let err = {};
        console.log(response, "response");
        for (let item of response) {
          if (item[1]) {
            if (!Object.hasOwnProperty(item[2].response.status)) {
              if (typeof item[1] === "string" && item[1].length > 100) {
                err[item[2].response.status] = "เกิดข้อผิดพลาด";
              } else {
                err[item[2].response.status] = item[1];
              }
            }
          }
        }
        if (Object.keys(err).length > 0) {
          return setAuthenError(err);
        } else {
          setOpenModal(false);
          getNoteItem();
        }
        getNote();
      }
    });
  };

  const handleOpenModal = () => {
    setPayload({
      ...payload,
      date: beToday.format(beFormatDate),
      time: beToday.format(beFormatTime),
      plan: nurseNoteItemList[nurseNoteItemList.length - 1].plan,
      goal: nurseNoteItemList[nurseNoteItemList.length - 1].goal,
      diagnosis: nurseNoteItemList[nurseNoteItemList.length - 1].diagnosis,
      encounter: props.encounterId,
      division: props.division ? props.division : cookies.division_id,
      token: cookies.csrftoken,
      apiToken: cookies.apiToken ? cookies.apiToken : props.apiToken
    });
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    modAuthenRef.current.clear();
    setAuthenError(null);
    setOpenModal(false);
  };

  return (
    <CardLayout
      cardLayoutClassName="nurse-note"
      titleText={"Nurse’s Note"}
      headerColor="gray"
      closeable={true}
      toggleable={false}
      hideHeaderIcon={props.hideHeaderIcon}
      loading={loading}
      onClose={props.hideCallback}
      style={props.cardStyle}
      dividerStyle={props.dividerStyle}
    >
      {React.useMemo(
        () => (
          <PureReactTable
            data={nurseNoteItemList}
            columns={columns}
            showPagination={false}
            pageSize={
              props.tablePageSize ? props.tablePageSize : nurseNoteItemList.length > 10 ? nurseNoteItemList.length : 10
            }
            manual
            // onSelect={row => setSelectedRow(row)}
            // selectedRow={selectedRow}
            style={props.tableStyle}
            getTdProps={(state, rowInfo, column, instance) => {
              return {
                onClick: (e, handleOriginal) => {
                  props.onSelectedCell(rowInfo)

                  if (props.readOnly) {
                    return;
                  }
                  if (!rowInfo || !column) {
                    return;
                  }
                  if (
                    !selectedCell ||
                    selectedCell[column.id] !== rowInfo.index
                  ) {
                    setSelectedCell({ [column.id]: rowInfo.index });
                  }
                },
                style: {
                  backgroundColor: rowInfo?.index === props.selectedRowIndex ? "rgba(93, 188, 210, 0.5)" : ""
                }
              };
            }}
          />
        ),
        [nurseNoteItemList, selectedCell, diagnosisRef, planRef, goalRef, props.selectedRowIndex]
      )}
      <Button fluid style={{ display: props.readOnly ? "none" : null }} onClick={handleOpenModal}>
        บันทึก
      </Button>

      <ModAuthen
        ref={modAuthenRef}
        error={authenError}
        loading={loading}
        open={openModal}
        onDeny={handleCloseModal}
        onApprove={handleSave}
      />
    </CardLayout>
  );
});

CardNurseNote.defaultProps = {
  controller: null,
  hideCallback: () => { },
  encounterId: null,
  division: null,
  cardStyle: {},
  hideHeaderIcon: false,
  tablePageSize: null,
  dividerStyle: {},
  tableStyle: {},
  isGetNurseNoteByEncounter: false,
  onSelectedCell: () => { },
  selectedRowIndex: null
};

CardNurseNote.propTypes = {
  controller: PropTypes.object,
  hideCallback: PropTypes.func,
  encounterId: PropTypes.number,
  division: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  cardStyle: PropTypes.object,
  hideHeaderIcon: PropTypes.bool,
  tablePageSize: PropTypes.number,
  dividerStyle: PropTypes.object,
  tableStyle: PropTypes.object,
  isGetNurseNoteByEncounter: PropTypes.bool,
  onSelectedCell: PropTypes.func,
  selectedRowIndex: PropTypes.number
};

export default React.memo(CardNurseNote);
