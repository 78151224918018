import UserService from '../services/UserService';

import { to, setStateAsync, formatDateToYYYYMMDD } from '../../utils';

export default class UserManager {
  getUserPermission = async params => {
    var userPermissionData = [];
    var userPermissionError = null;
    const userService = new UserService();
    const [error, response] = await to(userService.getUserPermission(params));
    if (response) {
      userPermissionData = response;
    } else {
      userPermissionError = error;
    }
    return [userPermissionData, userPermissionError];
  };
  getUserTokenize = async ({ code, apiToken } = {}) => {
    let params = {};
    var tokenData = [];
    var tokenError = null;
    const userService = new UserService();
    if (code) {
      params.code = code;
    }
    const [error, response] = await to(userService.getUserTokenize(params, apiToken));
    if (response) {
      tokenData = response;
    } else {
      tokenError = error;
    }
    return [tokenData, tokenError];
  };
  getUserTokenizeLicense = async ({ code }) => {
    let params = {};
    var tokenData = [];
    var tokenError = null;
    const userService = new UserService();
    if (code) {
      params.code = code;
    }
    const [error, response] = await to(userService.getUserTokenizeLicense(params));
    if (response) {
      tokenData = response;
    } else {
      tokenError = error;
    }
    return [tokenData, tokenError];
  };
  getUsers = async ({ nameUserName, nurseOrDoctor, role, fullName } = {}) => {
    let params = {};
    var usersData = [];
    var usersError = null;
    const userService = new UserService();
    if (nameUserName) {
      params.name_username = nameUserName;
    }
    if (nurseOrDoctor) {
      params.nurse_or_doctor = nurseOrDoctor;
    }
    if (role) {
      params.role = role;
    }
    if (fullName) {
      params.full_name = fullName;
    }
    const [error, response] = await to(userService.getUser(params));
    if (response) {
      usersData = response.items;
    } else {
      usersError = error;
    }
    return [usersData, usersError];
  }

  getUserProfile = async ({ apiToken }) => {
    let headers = {}
    let params = {};
    if (apiToken) {
      headers.Authorization = `Token ${apiToken}`;
    }
    const userService = new UserService();
    const [error, response] = await to(userService.getUserProfile({ headers, params }));
    return [response, error];
  }

  updateUserProfile = async ({ apiToken, firstName, lastName, dob, phone }) => {
    let headers = {}
    if (apiToken) {
      headers.Authorization = `Token ${apiToken}`;
    }
    let data = {
      first_name: firstName,
      last_name: lastName,
      phone_no: phone,
    }
    if (dob) {
      data.dob = formatDateToYYYYMMDD(dob);
    }
    const userService = new UserService();
    const [error, response] = await to(userService.patchUserProfile({ headers, data }));
    return [response, error];
  }

  getUserPosition = async ({ apiToken } = {}) => {
    const userService = new UserService();
    let params = {};
    const [error, response, network] = await to(
      userService.getUserPosition({ apiToken, params })
    );
    return [response, error, network];
  }

  getUserWithPatient = async ({ apiToken, userId } = {}) => {
    const userService = new UserService();
    let params = {};
    const [error, response, network] = await to(
      userService.getUserWithPatient({ apiToken, params, userId })
    );
    return [response, error, network];
  }


}
