import React, { useState } from "react";
import { Button, Dimmer, Loader } from "semantic-ui-react";
import {
  serial_to_datestr,
  get_week_slots_display,
  get_startofweek_serial,
  DivisionServiceBlock,
  serial_to_hour,
  QueueController,
  TIME_CONST
} from "./Time";
import ServiceSlotSummary from "./ServiceSlotSummary";
import { useCookies } from "react-cookie";
import { HourRangeDropdown } from "../common";

interface TimeDoctorProps {
  apiToken: string;
  controller: QueueController;
  providerId?: number;
  onClickDaySlot?: ({ index, weekStartSerial }: { index: number, weekStartSerial: number }) => {},
}

const TimeDoctor = (props: TimeDoctorProps) => {
  const [weekStartSerial, setWeekStartSerial] = useState(
    get_startofweek_serial()
  );
  const [serviceSlots, setServiceSlots] = React.useState([]);
  const [divisionServiceBlocks, setDivisionServiceBlocks] = React.useState<
    DivisionServiceBlock[]
  >([]);
  const [doctorDivisions, setDoctorDivisions] = React.useState<any[]>([]);
  const [cookies] = useCookies([]);
  const isMounted = React.useRef(true);
  const [minHour, setMinHour] = React.useState(0);
  const [maxHour, setMaxHour] = React.useState(24);
  const [isLoading, setIsLoading] = React.useState(false);

  const min_hour = Math.min(
    ...divisionServiceBlocks.map((dsb, index) =>
      serial_to_hour(dsb.start_serial)
    )
  );
  const max_hour = Math.max(
    ...divisionServiceBlocks.map((dsb, index) => serial_to_hour(dsb.end_serial))
  );

  const weekSlotsDisplay = get_week_slots_display(
    doctorDivisions,
    serviceSlots,
    weekStartSerial,
    divisionServiceBlocks
  );

  React.useEffect(() => {
    setMinHour(min_hour);
  }, [min_hour]);

  React.useEffect(() => {
    if (max_hour > 0) {
      setMaxHour(max_hour);
    }
  }, [max_hour]);

  React.useEffect(() => {
    handleGetDivisionList();
    handleLoadServiceSlot();

    return () => {
      isMounted.current = false;
    };
  }, []);

  const handleGetDivisionList = async () => {
    setIsLoading(true);
    const [
      response,
      error,
      network
    ] = await props.controller.getDivisionHasUser({
      apiToken: props.apiToken ? props.apiToken : cookies.apiToken
    });
    if (isMounted.current) {
      setIsLoading(false);
      if (response) {
        let item = response.items.map(
          (d: { division: { id: number | string } }) => d.division.id
        );
        setDoctorDivisions(item);
      } else {
        setDoctorDivisions([]);
      }
    }
  };

  const handleLoadServiceSlot = async () => {
    setIsLoading(true);
    const [response, error, network] = await props.controller.loadServiceSlot({
      apiToken: props.apiToken ? props.apiToken : cookies.apiToken,
      providerId: props.providerId ? props.providerId : cookies.providerId,
      fromSerial: weekStartSerial,
      toSerial: weekStartSerial + 96 * 7
    });
    if (isMounted.current) {
      setIsLoading(false);
      if (response) {
        setServiceSlots(response.items);
      } else {
        setServiceSlots([]);
      }
    }
  };

  React.useEffect(() => {
    if (doctorDivisions.length > 0) {
      handleLoadDoctorDivisionServiceBlock();
    }
  }, [doctorDivisions, weekStartSerial]);

  React.useEffect(() => {
    handleLoadServiceSlot();
  }, [weekStartSerial]);

  const handleLoadDoctorDivisionServiceBlock = async () => {
    setIsLoading(true);
    const [
      response,
      error,
      network
    ] = await props.controller.loadDivisionServiceBlock({
      apiToken: props.apiToken ? props.apiToken : cookies.apiToken,
      from_serial: weekStartSerial,
      to_serial: weekStartSerial + 96 * 7,
      divisions: doctorDivisions.toString()
    });
    if (isMounted.current) {
      setIsLoading(false);
      if (response) {
        setDivisionServiceBlocks(response.items);
      } else {
        setDivisionServiceBlocks([]);
      }
    }
  };

  const setWeekSerial = async (type: string) => {
    let unit = TIME_CONST.UNIT_PER_WEEK;
    if (type === "back") {
      unit = -TIME_CONST.UNIT_PER_WEEK;
    }
    setWeekStartSerial(weekStartSerial + unit);
  };

  return (
    <div className="DoctorSchedule">
      <Dimmer.Dimmable
        dimmed={isLoading}
        style={{ height: "100%" }}
        className="MainLayout"
      >
        <Dimmer active={isLoading} inverted>
          <Loader inverted>Loading</Loader>
        </Dimmer>
        <div className="header">
          <div>
            <Button compact content="<" onClick={() => setWeekSerial("back")} />
            <span className="dateRange">
              {`${serial_to_datestr(weekStartSerial, "localeDate", {               
              month: "long",
              day: "numeric" 
            })} -
            ${serial_to_datestr(weekStartSerial + 6 * 96, "localeDate", {               
              month: "long",
              day: "numeric" 
            })}`}
            </span>
            <Button compact content=">" onClick={() => setWeekSerial("next")} />
          </div>
        </div>
        <div className="content">
          <h1>
            สรุปตารางออกตรวจ &ensp;
            <span className="header5">
              &ensp; ช่วงเวลา&ensp;
              <HourRangeDropdown
                onChange={(e: any) => setMinHour(e.currentTarget.value)}
                defaultValue={minHour}
              />
              &ensp; ถึง &ensp;{" "}
              <HourRangeDropdown
                onChange={(e: any) => setMaxHour(e.currentTarget.value)}
                defaultValue={maxHour}
              />
            </span>
          </h1>
          <div className="calendarContent">
            <ServiceSlotSummary
              weekStartSerial={weekStartSerial}
              weekSlotsDisplay={weekSlotsDisplay}
              min_hour={minHour}
              max_hour={maxHour}
              onClickDaySlot={props.onClickDaySlot}
            />
          </div>
        </div>
      </Dimmer.Dimmable>
    </div>
  );
};

export default React.memo(TimeDoctor);
