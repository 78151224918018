import REGService from "../services/REGService";
import { to, setStateAsync } from "../../utils";
export default class REGManager {
  // ---------- REG

  apiToken = null;
  constructor(apiToken = null) {
    this.apiToken = apiToken;
    this.regService = new REGService(apiToken);
  }

  getPatientInfo = async (patientId) => {
    var patientInfoData = [];
    var patientInfoError = null;
    const [error, response] = await to(this.regService.getPatientById(patientId));
    if (response) {
      patientInfoData = response;
    } else {
      patientInfoError = error;
    }
    return [patientInfoData, patientInfoError];
  };
  getSearchAllPatient = async (
    { hn, fullName, citizenId, nationality, startAge, endAge, noEncounter, mergeFile, includeCancel, isEmergency, is_old_name_search, limit, offset } = {},
    cancelToken = null
  ) => {
    let params = {};
    if (hn) {
      params.hn = hn;
    }
    if (fullName) {
      params.name_search = fullName;
    }
    if (citizenId) {
      params.citizen_passport = citizenId;
    }
    if (nationality) {
      params.nationality = nationality;
    }
    if (startAge) {
      params.start_age = startAge;
    }
    if (endAge) {
      params.end_age = endAge;
    }
    if (noEncounter) {
      params.no_encounter = noEncounter;
    }
    if (mergeFile) {
      params.merge_file = mergeFile;
    }
    if (includeCancel) {
      params.include_cancel = includeCancel;
    }
    if (isEmergency) {
      params.is_emergency = isEmergency;
    }
    if (is_old_name_search) {
      params.is_old_name_search = is_old_name_search;
    }
    if (limit) {
      params.limit = limit;
    }
    if (offset) {
      params.offset = offset;
    }
    // if (cancelToken) {
    //   params.cancelToken = cancelToken;
    // }

    const [error, response] = await to(this.regService.getSearchAllPatient(params, cancelToken));
    return [response, error];
  };
  getPatientOldName = async (patientId) => {
    const [error, response] = await to(this.regService.getPatientName(patientId));
    return [response, error];
  };
  getPatientNameWithID = async (patientId) => {
    var nameData = [];
    const [error, response] = await to(this.regService.getPatientName(patientId));
    if (response) {
      nameData = response.items;
    }
    return [nameData, error];
  };

  getPatientByHN = async (params) => {
    var patientData = [];
    var patientError = [];
    const [error, response, network] = await to(this.regService.getPatientByHN(params));
    if (response) {
      patientData = response;
    } else {
      patientError = error;
    }
    return [patientData, patientError, network];
  };

  getBloodType = async ({ apiToken }) => {
    var data = [];
    var err = null;
    let params = {};
    const [error, response] = await to(this.regService.getBloodType({ params, apiToken }));
    if (response) {
      data = response.items;
    } else {
      err = error;
    }
    return [data, err];
  };
}
