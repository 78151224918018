/* eslint-disable react/display-name */
/* eslint-disable jsx-quotes */
import React, { useState, useEffect, useRef, useImperativeHandle, CSSProperties } from "react";
import { Header, Grid, Form, Dimmer, Loader, List, Segment, Button } from "semantic-ui-react"
import moment from "moment"
import Tab from "react-lib/frameworks/Tab"

// Controller
import DashboardController from "../react-lib/apps/IsHealth/Dashboard/DashboardController";
import ChatController from "../react-lib/apps/IsHealth/Chat/ChatController";
import CardDiagFormHistoryController from "../react-lib/apps/IsHealth/Nurse/CardDiagFormHistoryController"
import { prepareForm, QuestionSection, updateThread } from "../react-lib/apps/IsHealth/Common/Diag";

import CSS from 'csstype';
import { useParams } from "react-router";

interface FormAnalyticsProps {
  apiToken: string;
  division: number;

  //controller
  controller: DashboardController;
  chatController: ChatController;
  diagFormController: CardDiagFormHistoryController;

  onEvent: (args: {
    message: string,
    params: any
  }) => void;

  //List
  patientSegmentList: any[];
  divisionProfileList?: {
    items: any[]
  };
  divisionHasUser: any[],
  diagFormDetail: any;

  isLoadingFormAnalytics: boolean,
}

type Levels = "hig" | "low" | "med" | "nor"

type ColorLevelType = {
  [key in Levels]: CSS.Property.Color
}

const COLOR_LEVEL: ColorLevelType = {
  hig: "red",
  low: "rgb(247 185 0)",
  med: "green",
  nor: "grey"
}

const FormAnalytics = React.forwardRef((props: FormAnalyticsProps, ref) => {
  // list
  const [segmentDict, setSegmentDict] = useState(new Map())
  const [triageLevelList, setTriageLevelList] = useState([])

  // loading
  const [isLoading, setIsLoading] = useState(false)
  const [isLoadingAssessment, setIsLoadingAssessment] = useState(false)

  // selected
  const [selectedKey, setSelectedKey] = useState("")
  const [selectedRow, setSelectedRow] = useState(null)

  // detail
  const [diagFormDetail, setDiagFormDetail] = useState(null)

  const [thread, setThread] = useState([]);
  const [isAccessInformation, setIsAccessInformation] = useState(true)

  // Ref
  const isMounted = useRef(false);

  useEffect(() => {
    setIsLoading(true)
    if (props.divisionHasUser[0] && !props.isLoadingFormAnalytics) {
      setSegmentDict(new Map())
      setTriageLevelList([])
      setIsAccessInformation(true)
      setThread([])
      props.onEvent({ message: "GetPatientSegment", params: { divisionHasUser: props.divisionHasUser } })
      props.onEvent({ message: "GetDivisionProfile", params: { division: props.division } })
      isMounted.current = true
    }
  }, [props.divisionHasUser])

  useEffect(() => {
    if (isMounted.current && props.divisionProfileList?.items) {
      setPatientInfo()
    }
  }, [props.patientSegmentList, props.divisionProfileList])

  useEffect(() => {
    // setDiagFormDetail(props.diagFormDetail)
    if (props.diagFormDetail?.id) {
      getPublish(props.diagFormDetail)
    }
  }, [props.diagFormDetail])

  //----  Question
  const getPublish = async (diagFormDetail) => {

    let answer = diagFormDetail.answers ? JSON.parse(diagFormDetail.answers) : {};
    let diagRuleId = diagFormDetail.published_diag_rule;

    let [
      response,
      error,
      network
    ] = await props.diagFormController.getPublishedDiagRule({
      apiToken: props.apiToken,
      params: {},
      diagRuleId: diagRuleId ? diagRuleId : null,
    });

    if (response) {
      if (isMounted.current) {
        // setPublishDiagRule(response);

        if (response) {
          let x = prepareForm(response);

          // Combine answer into Question
          for (var key in x) {
            // check if the property/key is defined in the object itself, not in parent
            if (x.hasOwnProperty(key)) {
              if (typeof answer[key] === "boolean") {
                x[key].selected = answer[key] ? true : false;
              } else {
                if (Object.keys(answer)?.length > 0) {
                  if (answer[key]) {
                    x[key].answer = answer[key].answer;
                    x[key].selected = answer[key].selected;
                    x[key].extra_finished = answer[key].extra_finished;
                  } else {
                    x[key].answer = "";
                    x[key].selected = "";
                    x[key].extra_finished = "";
                  }
                }

                if (
                  answer[key] &&
                  answer[key].extra_items &&
                  x[key] &&
                  x[key].extra_items
                ) {
                  x[key].extra_items.forEach(item => {
                    let z = answer[key].extra_items.find(ans => {
                      return ans.label == item.label;
                    });
                    if (z) {
                      item.value = z.value;
                    }
                  });
                }
              }
            }
          }

          let y = Object.values(x);
          var targetThread = [];
          targetThread = updateThread([], y, y[0]);

          setThread(targetThread);
          setIsLoadingAssessment(false)

        }
      }
    }
  };


  const dummyFunc = () => () => { };
  //---- end Question


  const setPatientInfo = async () => {
    setIsLoading(true)

    const classify = JSON.parse(props.divisionProfileList?.items?.[0]?.extra || '{}')?.classify

    let cloneList = []
    cloneList = props.patientSegmentList?.map((list) => ({
      ...list,
      data: list.data.sort((a, b) => ('' + b.form_created_date).localeCompare(a.form_created_date))
    }))

    const groupPatient = [];
    for (const list of cloneList) {
      const hasPatient = new Map();
      const group = list.data.reduce((result, item) => {
        if (!hasPatient.has(item.patient_id)) {
          result.push({
            patient_info: item.patient_info,
            items: [item],
          });
          hasPatient.set(item.patient_id, hasPatient.size);
        } else {
          const index = hasPatient.get(item.patient_id);
          result[index].items.push(item)
        }
        return result;
      }, []);

      groupPatient.push({
        data: group,
        result: list.result
      })
    }
    const traigeList = []
    classify?.forEach((item: any) => {
      // let re = new RegExp(`^${item.triage_level}`)
      const find = groupPatient?.find(acc => item.triage_level.includes(acc.result))
      segmentDict.set(find?.result, {
        data: find?.data,
        detail: item
      })
      traigeList.push({
        ...item,
        triage_level: find?.result || item.triage_level.substring(0, 3)
      })
    })
    // groupPatient.forEach((item: any) => {
    //   let re = new RegExp(`^${item.result}`)
    //   console.log(item, classify?.find(acc => re.test(acc.triage_level)))
    //   segmentDict.set(item.result, {
    //     data: item.data,
    //     detail: classify?.find(acc => re.test(acc.triage_level))
    //   })
    // })
    const index = Array.from(segmentDict.keys()).findIndex((key: string) => {
      return segmentDict.get(key)?.detail?.active
    })
    if (index === -1) {
      setIsAccessInformation(false)
    } else {
      setIsAccessInformation(true)
    }
    setSegmentDict(segmentDict)
    setSelectedKey(props.patientSegmentList[index]?.result)
    setIsLoading(false)
    setTriageLevelList(traigeList)
  }


  const handleSelectedKey = async (key) => {
    // setDiagFormDetail(null)
    setThread([])
    setSelectedKey(key)
  }

  const handleSelectedRow = async (row) => {
    // console.log(row.GetDiagFormById)

    setIsLoadingAssessment(true)
    // setDiagFormDetail(null)
    setThread([])
    await props.onEvent({ message: "GetDiagFormById", params: { id: row.diag_form_id } })
    // console.log(res)
    setSelectedRow(row)
  }

  console.log(segmentDict, triageLevelList)

  return (
    <div style={{ width: "100%", height: "calc(100vh - 56px)" }} className="FormAnalytics">
      <Dimmer active={isLoading || props.isLoadingFormAnalytics} inverted>
        <Loader inverted>Loading</Loader>
      </Dimmer>
      <div className="dashboard">
        <Header as="h1">Triage Level</Header>
      </div>
      {/* <Tab
        // key === "" ? "(Empty)" : key
        header={["HN", "ชื่อ-นามสกุล", "วันที่คัดกรอง", ""]}
        data={Array.from(segmentDict.keys()).map((key: string) => (
          {
            key: key,
            name: () => (
              <div style={{ display: "flex", justifyContent: "center" }}>
                <div
                  className="FormAnalytics triage-level-list" >
                  <label>{key === "" ? "(Empty)" : key}</label>
                  <p></p>
                  <label>{segmentDict.get(key).length}</label>
                </div>
              </div>
            ),
            render: () => (
              <div style={{ height: "100%", overflowY: "scroll" }}>
                {segmentDict.get(key).map((item: any, index: number) => (
                  <div key={index} style={{ display: "flex" }}>
                    <div style={{ flex: 1 }}>{item.division}</div>
                    <div style={{ flex: 1 }}>{item.patient_id}</div>
                    <div style={{ flex: 1 }}>{item.form_updated_date}</div>
                    <div style={{ flex: 1 }}>{item.result}</div>
                  </div>
                ))}
              </div>
            )
          }
        ))}

      /> */}
      <div style={{ display: "flex" }}>
        {
          !isAccessInformation &&
          <div style={{
            position: "absolute",
            left: "31%",
            zIndex: 1,
            top: "18%",
            fontWeight: "bold",
            fontSize: "1em"
          }}>ไม่มีสิทธิ์เข้าถึงข้อมูล</div>
        }
        {/* Left item */}
        <div style={{ width: "160px" }}>
          {
            triageLevelList.map((item: any) => {
              return item?.active ? <div style={{ display: "flex", justifyContent: "center", marginBottom: "10px" }}>
                <div
                  className={`triage-level-list${item.triage_level === selectedKey ? " selected" : ""}`}
                  onClick={() => handleSelectedKey(item.triage_level)}
                >
                  <label style={{ color: item.codeColor, fontSize: "1.2em" }}>{item.triage_level === "" ? "(Empty)" : item.description}</label>
                  <p></p>
                  <label style={{ color: "grey", fontSize: "2em" }}>{segmentDict.get(item.triage_level)?.data?.length || 0}</label>
                </div>
              </div> : null
            })
          }
        </div>
        <Segment style={{ width: "100%", padding: 0, marginTop: 0, boxShadow: "none", paddingBottom: "14px" }}>


          {/* Header item */}
          <Form className="headers" style={{ borderRadius: "3px", marginBottom: "-14px" }}>
            <Form.Group inline style={{ padding: "8px 30px" }}>
              <Form.Field width={2} style={{ textAlign: "center" }}>
                HN
              </Form.Field>
              <Form.Field width={4} style={{ textAlign: "center" }}>
                ชื่อ-นามสกุล
              </Form.Field>
              <Form.Field width={4} style={{ textAlign: "center" }}>
                วันที่คัดกรอง
              </Form.Field>
              <Form.Field width={6} style={{ textAlign: "center" }}>
                แบบประเมินอาการช่วงกักตัว [Assessment Checklist during Isolation]
              </Form.Field>
            </Form.Group>
          </Form>
          <Grid>
            {/* Center item */}
            <Grid.Column width={9} className="items-list">
              {
                segmentDict.get(selectedKey)?.data?.map((item: any, index: number) =>
                  <>
                    <Form
                      style={{ borderBottom: "1px solid rgb(211 224 225 / 50%)", }}
                    // onClick={() => handleSelectedRow(item)}
                    // className={selectedRow?.diag_form_id === item.diag_form_id ? "selected" : ""}
                    >
                      <Form.Group inline style={{ padding: "5px 30px", margin: "0", paddingRight: "5px" }}>
                        <Form.Field width={4} style={{ fontWeight: "bold" }} className="primary">
                          {item.patient_info?.hn}
                        </Form.Field>
                        <Form.Field width={5} style={{ fontWeight: "bold" }}>
                          {`${item.patient_info?.patient_name}`}
                        </Form.Field>
                        <Form.Field width={8} style={{ display: "grid", gridTemplateColumns: "108px 108px 108px", maxHeight: "82px", overflowX: "hidden", overflowY: "auto" }}>
                          {
                            item.items.map((acc) =>
                              <Button
                                onClick={() => handleSelectedRow(acc)}
                                className={selectedRow?.diag_form_id === acc.diag_form_id ? "selected" : ""}
                                style={{ padding: "10px 5px", width: "150px", margin: "4px 3px", transform: "scale(0.7)", marginLeft: "-20px", marginTop: "-8px" }}>
                                {moment(acc.form_created_date).add(543, 'year').format('DD/MM/YYYY - HH:mm')}
                              </Button>
                            )
                          }
                        </Form.Field>
                      </Form.Group>
                    </Form>
                  </>
                )
              }
            </Grid.Column>
            {/* Right - assessment */}
            <Grid.Column width={7} className="assessment-form">
              <Segment style={{
                height: "100%",
                margin: 0,
                padding: "12px 15px 12px 12px",
                boxShadow: "none",
                border: 0,
                borderRadius: 0,
                backgroundColor: "transparent",
              }}>
                <Dimmer active={isLoadingAssessment} inverted>
                  <Loader inverted>Loading</Loader>
                </Dimmer>
                {thread?.length > 0 ? (
                  <QuestionSection
                    threadQuestion={thread}
                    selectedAnswer={dummyFunc}
                    rowsColorAnswer={true}
                  />
                ) : null}
              </Segment>
            </Grid.Column>
          </Grid>

        </Segment>
      </div>



      {/* {props.patientSegmentList.map((item: any, index: number) => (
        <div key={index}>{item.diag_rule}</div>
      ))} */}
    </div>

  );
});


export default React.memo(FormAnalytics);
