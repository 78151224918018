// import pdfMake from "pdfmake/build/pdfmake";
// import pdfFonts from "../assets/fonts/pdfFonts";
import getPdfMake from "../appcon/common/pdfMake";


// const dataMockup = {
//   language: "TH", // "EN"
//   patientName: "โกวิท วัฒนา",
//   nationality: "Thai",
//   room: "204",
//   hn: "6400044",
//   physician: "007",
//   visitDate: "15/07/2564",
//   department: "เวชระเบียน",
//   birthDate: "01/06/2536",
//   age: "28",
//   sex: "Male",
//   allergies: "None",
//   sideEffect: "None",
//   citizen_no: "1111111111189",
//   dayOfInfection: "22",
//   monthOfInfection: "กรกฎาคม",
//   yearOfInfection: "2564",
//   dayStayHome: "5",
//   monthStayHome: "สิงหาคม",
//   yearStayHome: "2564",
//   houseNo: "99/9",
//   village: "รื่นรมย์",
//   soi: "9",
//   street: "พระราม 2",
//   district: "แสมดำ",
//   amphur: "แสมดำ",
//   province: "กรุงเทพมหานคร",
//   zipcode: "10200",
//   tel: "0914784655",
//   contactName: "สมฤดี ผ่องศรี",
//   contactTel: "0945457777",
//   dateSubmit: "22/07/2564",
//   timeSubmit: "14:10",
// };


export type ConsentFormIsolationType = {
  language?: string;
  patientName?: string;
  nationality?: string;
  room?: string;
  hn?: string;
  physician?: string;
  visitDate?: string;
  department?: string;
  birthDate?: string;
  age?: string;
  sex?: string;
  allergies?: string;
  sideEffect?: string;
  citizenNumber?: string;
  dateRTPCR?: string;
  monthRTPCR?: string;
  yearRTPCR?: string;
  dateUntil?: string;
  monthUntil?: string;
  yearUntil?: string;
  homeNumber?: string;
  villageNumber?: string;
  laneName?: string;
  alleyName?: string;
  street?: string;
  subDistrictName?: string;
  districtName?: string;
  provinceName?: string;
  postalCode?: string;
  phoneNumber?: string;
  emergencyContactName?: string;
  emergencyContactPhoneNumber?: string;
  patientSignature?: string;
  patientSignDate?: string;
  patientSignTime?: string;
  witnessName1?: string;
  witnessSignature1?: string;
  witnessSignDate1?: string;
  witnessSignTime1?: string;
  witnessName2?: string;
  witnessSignature2?: string;
  witnessSignDate2?: string;
  witnessSignTime2?: string;
  assignDoctor?: string;
  assignDoctorSignature?: string;
  assignDoctorSignDate?: string;
  assignDoctorSignTime?: string;
  isNotLawful?: boolean;
  isDisabillity?: boolean;
  disabillityText?: string;
  isOtherReason?: boolean;
  otherReasonText?: string;
  patientAgent?: string;
  patientAgentSignature?: string;
  patientAgentSignDate?: string;
  patientAgentSignTime?: string;
  patientAgentRelative?: string;
  patientAgentCitizenNumber?: string;
  patientAgentCardAt?: string;
  patientAgentCardDate?: string;
  patientAgentCardExpire?: string;
  patientAgentAddress?: string;
};

export const consentFormIsolationInitial: ConsentFormIsolationType = {
  language: "EN", // "EN",
  patientName: "โกวิท วัฒนา",
  nationality: "ไทย",
  room: "",
  hn: "6400044",
  physician: "",
  visitDate: "02/06/2536",
  department: "เวชระเบียน",
  birthDate: "06/06/2536",
  age: "",
  sex: "",
  allergies: "none",
  sideEffect: "none",
  citizenNumber: "",
  dateRTPCR: "",
  monthRTPCR: "",
  yearRTPCR: "",
  dateUntil: "",
  monthUntil: "",
  yearUntil: "",
  homeNumber: "",
  villageNumber: "",
  laneName: "",
  alleyName: "",
  street: "",
  subDistrictName: "",
  districtName: "",
  provinceName: "",
  postalCode: "",
  phoneNumber: "",
  emergencyContactName: "",
  emergencyContactPhoneNumber: "",
  patientSignature: "",
  patientSignDate: "",
  patientSignTime: "",
  witnessName1: "รัตนาภรณ์ วัฒนา",
  witnessSignature1: "โกวิท วัฒนา",
  witnessSignDate1: "01/06/2536",
  witnessSignTime1: "11:00",
  witnessName2: "จำนงค์ พรมเกตุ",
  witnessSignature2: "โกวิท วัฒนา",
  witnessSignDate2: "01/06/2536",
  witnessSignTime2: "11:00",
  assignDoctor: "บุญประสิทธิ์ รัศมี",
  assignDoctorSignature: "บุญประสิทธิ์",
  assignDoctorSignDate: "02/06/2536",
  assignDoctorSignTime: "11:00",
  isNotLawful: false,
  isDisabillity: false,
  disabillityText: "-",
  isOtherReason: false,
  otherReasonText: "-",
  patientAgent: "-",
  patientAgentSignature: "-",
  patientAgentSignDate: "-",
  patientAgentSignTime: "-",
  patientAgentRelative: "-",
  patientAgentCitizenNumber: "-",
  patientAgentCardAt: "-",
  patientAgentCardDate: "-",
  patientAgentCardExpire: "-",
  patientAgentAddress: "-",
};

export const conFormHomeIsolation = async (props) => {
  props = { ...consentFormIsolationInitial, ...props };
  let pdfMake = await getPdfMake();

  var origin = window?.location?.origin;

  var houseAddressFrom = props.language === "TH" ? 
  [{
    columns: [
      {
        text: "ข้าพเจ้าเข้ากักตัวที่บ้านเลขที่",
        width: "30%",
        margin: [30, 0, 0, 0],
      },
      { text: props.homeNumber, width: "15%" },
      { text: "หมู่บ้าน/คอนโด", width: "17%" },
      { text: props.villageNumber, width: "*" },
    ],
    columnGap: 5,
  },
  {
    columns: [
      { text: "ซอย", width: "5%" },
      { text: props.laneName, width: "*" },
      { text: "ถนน", width: "8%" },
      { text: props.street },
      { text: "ตำบล/แขวง", width: "10%" },
      { text: props.subDistrictName, width: "*" },
    ],
    columnGap: 5,
  },
  {
    columns: [
      { text: "อำเภอ/เขต", width: "10%" },
      { text: props.districtName, width: "22%" },
      { text: "จังหวัด", width: "8%" },
      { text: props.provinceName, width: "*" },
      { text: "รหัสไปรษณีย์", width: "10%" },
      { text: props.postalCode, width: "*" },
    ],
    columnGap: 5,
  },
  {
    columns: [
      { text: "เบอร์โทรศัพท์", width: "15%" },
      { text: props.phoneNumber },
    ],
    columnGap: 5,
  },
  {
    columns: [
      { text: "ชื่อผู้ใกล้ชิดที่ติดต่อได้", width: "15%" },
      { text: props.emergencyContactName, width: "*" },
      { text: "เบอร์โทรศัพท์", width: "15%" },
      { text: props.emergencyContactPhoneNumber, width: "*" },
    ],
  }] :
  [{
    columns: [
      {
        text: "House Number: ",
        width: "20%",
      },
      { text: props.homeNumber, width: "15%" },
      { text: "Villege/Condominium", width: "17%" },
      { text: props.villageNumber, width: "*" },
      { text: "Soi", width: "5%" },
      { text: props.laneName, width: "*" },
    ],
    columnGap: 5,
  },
  {
    columns: [
      { text: "Road", width: "8%" },
      { text: props.street, width: "*" },
      { text: "Sub-district", width: "10%" },
      { text: props.subDistrictName, width: "*" },
      { text: "District", width: "10%" },
      { text: props.districtName, width: "*" },
    ],
    columnGap: 5,
  },
  {
    columns: [
      { text: "Province/City", width: "12%" },
      { text: props.provinceName, width: "*" },
      { text: "Postcode", width: "10%" },
      { text: props.postalCode, width: "*" },
      { text: "Telephone Number", width: "20%" },
      { text: props.phoneNumber, width: "*" },
    ],
    columnGap: 5,
  },
  {
    columns: [
      { text: "Contact Person's name", width: "20%" },
      { text: props.emergencyContactName, width: "40%" },
      { text: "Telephone Number", width: "15%" },
      { text: props.emergencyContactPhoneNumber, width: "*" },
    ],
  }]

  // playground requires you to assign document definition to a variable called dd
  var headerConsentHomeIsolation = {
    margin: [45, 20, 20, 0],
    stack: [
      {
        columns: [
          {
            margin: [0, 3, 0, 0],
            width: "40%",
            stack: [
              {
                image: "logobkhpt",
                width: 145,
              },
              {
                text: "**Confidential**",
                style: "fontHeader",
                alignment: "center",
              },
              {
                margin: [0, -5, 0, 0],
                style: "tableNormal",
                table: {
                  widths: ["97%"],
                  body: [
                    [
                      {
                        style: "tableHeader",
                        margin: [0, 0, 0, 0],
                        text: [
                          {
                            text: props.language === "TH" ? "หนังสือแสดงความยินยอม/เจตนาในการแยกกักตัว ที่บ้าน (Home Isolation) เนื่องจากการติดเชื้อ ไวรัสโคโรนา 2019 (COVID-19)" : "Consent form / Agreement for COVID-19 Home Isolation Program",
                            style: "fontHeaderTableTop",
                            alignment: "center",
                          },
                        ],
                      },
                    ],
                  ],
                },
              },
            ],
          },
          // {
          //   image: "logobkhpt",
          //   width: 145,
          //   alignment: "right",
          // },
          {
            // star-sized columns fill the remaining space
            // if there's more than one star-column, available width is divided equally
            margin: [0, 0, 0, 0],
            width: "*",
            stack: [
              {
                columns: [
                  {
                    width: "10%",
                    text: "Name: ",
                    style: "fieldKey",
                  },
                  (props.language === "TH"? {
                    width: "auto",
                    text: props.patientName,
                    style: "fieldValue",
                    alignment: "left",
                  }: {
                    width: "auto",
                    margin: [0, 1 ,0 ,0],
                    text: props.patientName,
                    style: {fontSize: 12},
                    alignment: "left",
                  }),
                  {
                    // star-sized columns fill the remaining space
                    // if there's more than one star-column, available width is divided equally
                    width: "*",
                    text: [
                      { text: "Nationality: ", style: "fieldKey" },
                      { text: props.nationality, style: "fieldValue" },
                    ],
                  },
                  {
                    // star-sized columns fill the remaining space
                    // if there's more than one star-column, available width is divided equally
                    width: "*",
                    text: [
                      { text: "Room: ", style: "fieldKey" },
                      { text: props.room, style: "fieldValue" },
                    ],
                  },
                ],
                columnGap: 5,
              },
              {
                columns: [
                  {
                    width: "35%",
                    text: [
                      { text: "HN: ", style: "fieldKey" },
                      { text: props.hn, style: "fieldValue" },
                    ],
                  },
                  {
                    // star-sized columns fill the remaining space
                    // if there's more than one star-column, available width is divided equally
                    width: "*",
                    text: [
                      { text: "Physician: ", style: "fieldKey" },
                      { text: props.physician, style: "fieldValue" },
                    ],
                  },
                ],
                columnGap: 5,
              },
              {
                columns: [
                  {
                    width: "35%",
                    text: [
                      { text: "Visit Date: ", style: "fieldKey" },
                      { text: props.visitDate, style: "fieldValue" },
                    ],
                  },
                  {
                    // star-sized columns fill the remaining space
                    // if there's more than one star-column, available width is divided equally
                    width: "*",
                    text: [
                      { text: "Department: ", style: "fieldKey" },
                      { text: props.department, style: "fieldValue" },
                    ],
                  },
                ],
                columnGap: 5,
              },
              {
                columns: [
                  {
                    width: "35%",
                    text: [
                      { text: "Birth Date: ", style: "fieldKey" },
                      { text: props.birthDate, style: "fieldValue" },
                    ],
                  },
                  {
                    // star-sized columns fill the remaining space
                    // if there's more than one star-column, available width is divided equally
                    width: "*",
                    text: [
                      { text: "Age: ", style: "fieldKey" },
                      { text: props.age, style: "fieldValue" },
                    ],
                  },
                  {
                    // star-sized columns fill the remaining space
                    // if there's more than one star-column, available width is divided equally
                    width: "*",
                    text: [
                      { text: "Sex: ", style: "fieldKey" },
                      { text: props.sex, style: "fieldValue" },
                    ],
                  },
                ],
                columnGap: 5,
              },
              {
                columns: [
                  {
                    width: "*",
                    text: [
                      { text: "Allergies: ", style: "fieldKey" },
                      { text: props.allergies, style: "fieldValue" },
                    ],
                  },
                ],
                columnGap: 5,
              },
              {
                columns: [
                  {
                    width: "*",
                    text: [
                      { text: "Side Effect: ", style: "fieldKey" },
                      { text: props.sideEffect, style: "fieldValue" },
                    ],
                  },
                ],
                columnGap: 5,
              },
            ],
          },
        ],
        // optional space between columns
        columnGap: 5,
      },
    ],
  };

  var contentConsentHomeIsolation = [
    {
      stack: [
        {
          margin: [20, 0, 10, 0],
          stack: [
            {
              columns: [
                { text: props.language === "TH" ? "ข้าพเจ้า นาย / นาง / นางสาว" : "I, Mr/Mrs/Miss/Ms", width: "30%" },
                { text: props.patientName, width: "40%" },
                { text: props.language === "TH" ? "อายุ" : "Age", width: "10%" },
                { text: props.age, width: "20%" },
              ],
              columnGap: 5,
            },
          ],
        },
        {
          columns: [
            {
              text: props.language === "TH" ? "เลขประจำตัวบัตรประชาชน / เลขหนังสือเดินทาง" : "National ID Number/ Passport Number:",
              width: "40%",
            },
            { text: props.citizenNumber, width: "60%", alignment: "left" },
          ],
        },
        {
          columns: [
            {
              text:
                props.language === "TH" ? "ผลการตรวจโดยวิธี RT-PCR และ/หรือ Rapid Antigen พบว่ามีการติดเชื้อไวรัสโคโรนา 2019 วันที่ " : "SARS-CoV-2 was detected via RT-PCR Test and/or Rapid Antigen Test on Date",
              width: "auto",
              margin: [30, 0, 0, 0],
            },
            { text: props.dateRTPCR, width: "5%", margin: [6, 0, 0, 0], },
            { text: props.language === "TH" ? "เดือน" : "Month", width: "5%" },
            { text: props.monthRTPCR, width: "8%" },
            { text: props.language === "TH" ? "พ.ศ." : "Year", width: "4%" },
            { text: props.yearRTPCR, width: "5%" },
          ],
        },
        {
          margin: [30, 0, 0, 0],
          text:
            props.language === "TH" ? "ได้อ่านรายละเอียดจากเอกสารข้อมูล และรับฟังคำอธิบายจากผู้ให้การรักษา รวมถึงความเสี่ยงผลข้างเคียง และ/หรือผลที่อาจจะเกิดขึ้น" : "I have read and fully understood all information provided by the health care provider and in the related documents which",
        },
        {
          text: props.language === "TH" ? "ภายหลัง จนเป็นที่กระจ่างชัดแล้ว" : "include any risks and potential impacts as a consequence of COVID-19 infection.",
        },
        {
          columns: [
            {
              text: props.language === "TH" ? "ข้าพเจ้ายินยอมกักตัวที่บ้านจนถึงวันที่" : "I hereby agree to participate in Home Isolation Program with Bangkok Hospital until Date",
              margin: [30, 0, 0, 0],
              width: props.language === "TH" ? "31%" : "auto"
            },
            { text: props.dateUntil, width: "3%" },
            { text: props.language === "TH" ? "เดือน" : "Month", width: "6%" },
            { text: props.monthUntil, width: "8%" },
            { text: props.language === "TH" ? "พ.ศ." : "Year", width: "4%" },
            { text: props.yearUntil, width: "5%" },
            { text: props.language === "TH" ? "โดยจะปฏิบัติตามคำแนะนำทุกประการ เช่น วัดอุณหภูมิร่างกาย" : "" },
          ],
        },
        {
          text:
            props.language === "TH" ? " การตอบแบบสอบถามเกี่ยวกับอาการวันละ 2 ครั้ง และการวัดค่าความอิ่มตัวของออกซิเจนในเลือด แล้วแจ้งผลให้ทาง" +
              "โรงพยาบาลทราบทันทีผ่านช่องทาง หรือระบบการสื่อสารที่โรงพยาบาลกำหนด หากข้าพเจ้าแสดงอาการที่มีข้อบ่งชี้ต้องรักษาตัวในโรงพยาบาล " +
              "ทางโรงพยาบาลจะรับข้าพเจ้าเป็นผู้ป่วยใน หรือช่วยประสานงานดำเนินการหาเตียงผู้ป่วยใน สำหรับรองรับผู้ป่วยโควิด-19ของโรงพยาบาลนั้นๆให้ โดย" +
              "ข้าพเจ้ายินยอมให้แพทย์และบุคลากร ทางการแพทย์ของโรงพยาบาล และผู้ที่ได้รับมอบหมายสามารถกระทำการดูแล รักษา ตรวจ วินิจฉัย เพิ่มเติม " +
              "ทั้งนี้ตามดุลยพินิจของบุคลากรทางการแพทย์ ข้าพเจ้าเข้าใจถึงความจำเป็นอันเป็นเหตุให้ต้องรับการตรวจวินิจฉัย รักษาในกรณีที่มีการเปลี่ยนแปลง " +
              "ของการตรวจวินิจฉัย หรือแผนการดูแลรักษา ข้าพเจ้ามีสิทธิที่จะได้รับคำอธิบายเพิ่มเติม" :
              "where I will comply with all provided instructions e.g. taking Body Temperature, measuring oxygen saturation, responding to twice-daily" +
              "self-monitoring questionnaire. I will regularly report my body temperature and oxygen saturation parameters and also respond to the" +
              "twice-daily questionnaire to the hospital through the designated communication channels recommended by the hospital. In case when I" +
              "develop any signs and symptoms that would require hospitalization, the hospital will assist in arranging a hospital bed at Bangkok" +
              "Hospital or contacting relevant health care authority or facility with vacant hospital bed for COVID-19 patients to hospitalize me. I would " +
              "agree to be hospitalized as an inpatient where doctors, nurses and relevant care providers can perform treatments, investigations and" +
              "interventions according to clinical indications as directed by the treating physician. I have the right to be properly informed by the" +
              "treating physician in regard to any changes in the diagnosis or plan of treatments during my stay.",
          alignment: 'left',
        },
        {
          text:
            props.language === "TH" ? "“โรงพยาบาล รวมทั้งแพทย์ บุคลากรทางการแพทย์ของโรงพยาบาล และผู้ที่ได้รับมอบหมายสามารถเก็บรวบรวม ใช้ และเปิดเผยข้อมูลส่วน" : "“Hospital including physicians, health care professionals and relevant designees can collect, use and disclose your personal",
          margin: [30, 0, 0, 0],
        },
        {
          text: props.language === "TH" ? "บุคคลต่างๆ ของท่าน ซึ่งรวมถึงแต่ไม่จำกัดเพียงข้อมูลด้านสภาวะสุขภาพของท่านเพื่อให้บริการทางการแพทย์แก่ท่าน ตามมาตรา 26 (5) (ก) แห่ง" +
            "พระราชบัญญัติคุ้มครองข้อมูลส่วนบุคคล พ.ศ. 2562 รวมทั้งเพื่อให้บรรลุวัตถุประสงค์เกี่ยวกับประโยชน์สาธารณะด้านการสาธารณสุข เช่น การป้องกัน" +
            "ด้านสุขภาพจากโรคติดต่ออันตรายหรือโรคระบาดที่อาจติดต่อหรือแพร่เข้ามาในราชอาณาจักร ตามมาตรา 26 (5) (ข) แห่งพระราชบัญญัติ คุ้มครอง" +
            "ข้อมูลส่วนบุคคล พ.ศ. 2562”" : 
            "information which is not limited to your health information in order to provide medical cares to you according to Section 26 (5)(a)" +
            "Personal Data Protection Act, B.E. 2562, in addition, to achieve the purposes with respect to public interests in public health such as" +
            "protecting against cross-border dangerous and contagious diseases or epidemics according to Section 26 (5)(b) Personal Data" + 
            "Protection Act, B.E. 2562”"
        },
        {
          text:
            props.language === "TH" ? "ข้าพเจ้าขอแสดงความยินยอม ในการดูแลตนเองโดยการกักตัวที่บ้านโดยความสมัครใจ ณ ที่นี้" : "I hereby agree and give my consent to participate in the Home Isolation Program with Bangkok Hospital.",
          margin: [30, 0, 0, 0],
        },
        {
          text:
            props.language === "TH"? "" : "The address I will be staying during the Home Isolation Program is located at:",
          margin: [30, 0 ,0, 0]
        },
        {
          stack: houseAddressFrom
        }
        // {
        //   columns: [
        //     {
        //       text: props.language === "TH" ? "ข้าพเจ้าเข้ากักตัวที่บ้านเลขที่" : "House Number: ",
        //       width: props.language === "TH" ? "30%" : "42%",
        //       margin: props.language === "TH" ? [30, 0, 0, 0]: [0, 0, 0, 0],
        //     },
        //     { text: props.homeNumber, width: "15%" },
        //     { text: props.language === "TH" ? "หมู่บ้าน/คอนโด" : "Villege/Condominium", width: "17%" },
        //     { text: props.villageNumber, width: "*" },
        //   ],
        //   columnGap: 5,
        // },
        // {
        //   columns: [
        //     { text: props.language === "TH" ? "ซอย" : "Soi", width: "5%" },
        //     { text: props.laneName, width: "*" },
        //     { text: props.language === "TH" ? "ถนน" : "Road", width: "8%" },
        //     { text: props.street },
        //     { text: props.language === "TH" ? "ตำบล/แขวง" : "Sub-district", width: "10%" },
        //     { text: props.subDistrictName, width: "*" },
        //   ],
        //   columnGap: 5,
        // },
        // {
        //   columns: [
        //     { text: props.language === "TH" ? "อำเภอ/เขต" : "District", width: "10%" },
        //     { text: props.districtName, width: "22%" },
        //     { text: props.language === "TH" ? "จังหวัด" : "Province", width: "8%" },
        //     { text: props.provinceName, width: "*" },
        //     { text: props.language === "TH" ? "รหัสไปรษณีย์" : "Postcode", width: "10%" },
        //     { text: props.postalCode, width: "*" },
        //   ],
        //   columnGap: 5,
        // },
        // {
        //   columns: [
        //     { text: props.language === "TH" ? "เบอร์โทรศัพท์" : "Telephone number", width: "15%" },
        //     { text: props.phoneNumber },
        //   ],
        //   columnGap: 5,
        // },
        // {
        //   columns: [
        //     { text: props.language === "TH" ? "ชื่อผู้ใกล้ชิดที่ติดต่อได้" : "Contact nume", width: "15%" },
        //     { text: props.emergencyContactName, width: "*" },
        //     { text: props.language === "TH" ? "เบอร์โทรศัพท์" : "Telephone number", width: "15%" },
        //     { text: props.emergencyContactPhoneNumber, width: "*" },
        //   ],
        // },
      ],
    },
    {
      stack: [
        {
          text: props.language === "TH" ? "ข้าพเจ้ารับทราบและจะปฏิบัติอย่างเคร่งครัด ดังนี้" : "I have acknowledged and will strictly comply with instructions below",
          margin: [30, 10, 20, 0],
          style: "fieldHeader",
        },
        {
          margin: [40, 0, 0, 0],
          stack: [
            {
              columns: [
                { text: "1.", width: "5%", style: "fieldHeader" },
                {
                  text: [
                    {
                      text:
                        props.language === "TH" ? "ข้าพเจ้าจะปฏิบัติตามพระราชบัญญัติโรคติดต่อ พ.ศ. 2558" : "I will comply with Communicable Diseases Act, B.E. 2558 ",
                      style: "fieldHeader",
                    },
                    {
                      text: props.language === "TH" ? " เพื่อประโยชน์ในการเผ้าระวังป้องกัน และควบคุมโรคติดต่อ" +
                        "อันตราย จะแยกกักจนกว่าจะได้รับการตรวจว่าพ้นระยะติดต่อของโรคหรือสิ้นสุดเหตุอันควรสงสัยตามระยะเวลาที่เจ้าหน้าที่กำหนด" +
                        "หากฝ่าฝืน จะถือว่ามีความผิดตามมาตรา 34 มาตรา 45 และ มาตรา 51 หากท่านไม่ปฏิบัติตามกฏหมายโรงพยาบาลจำเป็นต้องแจ้ง" +
                        "ความต่อเจ้าพนักงานควบคุมโรคติดต่อเพื่อดำเนินคดีตามที่กฏหมายระบุไว้" :
                        "for the benefits of surveillance, protection and control of " +
                        "communicable diseases. I will strictly comply with the 14-day Mandatory Isolation rules and regulations. The termination " +
                        "of isolation period will be carefully determined by the hospital. Therefore, you must commit yourselves with the COVID-19" + 
                        "isolation rules to avoid offending the Communicable Diseases Act, Sections 35, 45 and 51. If you failed to comply" + 
                        "with the aforementioned act, the hospital shall report to the Communicable Disease Control officers to prosecute as" +
                        "specified by the law."
                    },
                  ]
                },
              ],
            },
            {
              columns: [
                { text: "2.", width: "5%", style: "fieldHeader" },
                {
                  columns: [
                    {
                      stack: [
                        {
                          margin: [0, 0, 0, 0],
                          text: [
                            {
                              text: props.language === "TH" ? "อยู่ในห้องส่วนตัวตลอดเวลา" : "Stay in a private room at all times ",
                              style: "fieldHeader",
                            },
                            {
                              text:
                                props.language === "TH" ? " หลีกเลี่ยงการอยู่ใกล้ชิดกับบุคคลอื่นในที่พักอาศัย โดยเฉพาะผู้สูงอายุ เด็กเล็กผู้ป่วยโรคเรื้อรังต่าง ๆ หากยังมีอาการ ไอ จาม ต้องสวมหน้ากากอนามัยแม้ขณะที่อยู่ในห้องส่วนตัว" : 
                                "to avoid close contact with others who are sharing the same accommodation," +
                                "especially elderly, children, and individuals with chronic diseases. Should you have any aerosol-generating symptoms" +
                                "e.g. coughing and sneezing, you must wear face mask despite staying in the private room."
                            },
                            {
                              text: props.language === "TH" ? " โดยแนะนำให้สวมหน้ากากอนามัยไม่ให้ใช้หน้ากากผ้า" : "",
                              decoration: "underline",
                              width: "auto",
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
            },

            {
              columns: [
                { text: "3.", width: "5%", style: "fieldHeader" },
                {
                  columns: [
                    {
                      stack: [
                        {
                          margin: [0, 0, 0, 0],
                          text: [
                            {
                              text:
                                props.language === "TH" ? "หากจำเป็นต้องเข้าใกล้ผู้อื่นต้องสวมหน้ากากอนามัยและอยู่ห่างอย่างน้อย 1 เมตร " : "At least 1-meter distance is required if close contact is inevitable and face mask must be worn at all times. ",
                              style: "fieldHeader",
                              width: "auto",
                            },
                            {
                              text:
                                props.language === "TH" ? " หรือประมาณหนึ่งช่วงแขน หากไอจามไม่ ควรเข้าใกล้ผู้อื่นหรืออยู่ห่างอย่างน้อย 2เมตร และให้หันหน้าไปยังทิศทางตรงข้ามกับตำแหน่งที่มีผู้อื่นอยู่ด้วย" : "If coughing or sneezing is present, then a minimum of 2-meter distance is required and you must turn your face away from the other person.",
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
            {
              columns: [
                { text: "4.", width: "5%", style: "fieldHeader" },
                {
                  stack: [
                    {
                      margin: [0, 0, 0, 0],
                      text: [
                        {
                          text: props.language === "TH" ? "หากไอจามขณะที่สวมหน้ากากอนามัย" : "If you are coughing or sneezing while wearing face mask: ",
                          style: "fieldHeader",
                        },
                        {
                          text:
                            props.language === "TH" ? " ไม่ต้องเอามือมาปิดปากและไม่ต้องถอดหน้ากากอนามัยออก เนื่องจากมืออาจเปรอะเปื้อน หากไอจามขณะที่ไม่ได้สวมหน้ากากอนามัยให้ใช้ต้นแขนด้านในปีดปาก และจมูก" : "Do not use a hand to cover your mouth and do not remove the face mask while coughing or sneezing. If you are coughing or sneezing while not wearing face mask, please use your inner arm to cover your nose and mouth.",
                        },
                      ],
                    },
                  ],
                },
              ],
            },
            {
              columns: [
                { text: "5.", width: "5%", style: "fieldHeader" },
                {
                  stack: [
                    {
                      margin: [0, 0, 40, 0],
                      text: [
                        {
                          text:
                            props.language === "TH" ? "ถูมือด้วยเจลแอลกอฮอล์หรือล้างมือด้วยสบู่และน้ำเป็นประจำ " : "Rub your hands with alcohol-based handrubs or wash your hands with soap and water regularly, ",
                          style: "fieldHeader",
                        },
                        {
                          text:
                            props.language === "TH" ? "(หากมือเปรอะเปื้อนให้ล้างด้วยสบู่และน้ำ) โดยเฉพาะภายหลังสัมผัสน้ำมูก น้ำลาย เสมหะ ขณะไอ จาม หรือหลังจากถ่ายปัสสาวะหรืออุจจาระ และก่อนสัมผัสจุดเสี่ยงที่มีผู้อื่นในบ้านใช้ร่วมกัน เช่น ลูกบิดประตู ราวบันได มือจับตู้เย็น ฯลฯ " : "especially when contacting with nasal discharge, saliva, secretion while coughing/sneezing or after urination or defecation. Please use alcohol-based handrubs before contacting any surfaces that might also be contacted by others e.g. door knobs, handrails. ",
                        },
                      ],
                    },
                  ],
                },
              ],
            },
            {
              margin: props.language === "TH" ? [0, 25, 0, 0] : [0, 0, 0, 0],
              columns: [
                { text: "6.", width: "5%", style: "fieldHeader" },
                {
                  columns: [
                    {
                      stack: [
                        {
                          margin: [0, 0, 40, 0],
                          text: [
                            {
                              text: props.language === "TH" ? "กรณีที่เป็นมารดาให้นมบุตร" : "Women with COVID-19 can breastfeed if they wish to do so: ",
                              width: "auto",
                              style: "fieldHeader",
                            },
                            {
                              text:
                                props.language === "TH" ? " ยังสามารถให้นมบุตรได้ เนื่องจากยังไม่มีรายงานพบเชื้อโควิด-19 แต่มารดาควรสวมหน้ากากอนามัย และล้างมืออย่างเคร่งครัดทุกครั้งก่อนสัมผัสหรือให้นมบุตร" : "At present, data are not sufficient to conclude vertical transmission of COVID-19 through breastfeeding and all mothers are advised to continue breastfeeding, while practicing good hygiene during feeding. These include the 3 Ws (Wear face mask, Wash hands with soap, Wipe and disinfect surfaces regularly).",
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
            {
              margin: [0, 0, 0, 0],
              columns: [
                { text: "7.", width: "5%", style: "fieldHeader" },
                {
                  columns: [
                    {
                      margin: [0, 0, 40, 0],
                      stack: [
                        {
                        text: [
                          {
                            text: props.language === "TH" ? "ใช้ห้องน้ำแยกจากผู้อื่น " : "Use a separate restroom (if applicable) ",
                            style: "fieldHeader",
                          },
                          {
                            text:
                              props.language === "TH" ? " หากจำเป็นต้องใช้ห้องน้ำร่วมกัน ให้ใช้เป็นคนสุดท้าย ให้ปิดฝาชักโครกก่อนกดน้ำ" : "if not applicable, it is advised that you should be the last person using the restroom and all the touched areas need to be thoroughly cleaned with anti-bacterial products. ",
                          },
                        ]
                        }
                      ]
                    }
                  ],
                },
              ],
            },

            {
              columns: [
                { text: "8.", width: "5%", style: "fieldHeader" },
                {
                  columns: [
                    {
                      stack: [
                        {
                          margin: [0, 0, 40, 0],
                          text: [
                            {
                              text: props.language === "TH" ? "การทำความสะอาดห้องน้ำและพื้นผิว " : "Cleaning a restroom and surfaces: ",
                              style: "fieldHeader",
                            },
                            {
                              text:
                                props.language === "TH" ? "ควรทำความสะอาดโถสุขภัณฑ์หรือพื้นที่ที่อาจปนเปื้อนเสมหะ น้ำมูก อุจจาระ ปัสสาวะ หรือสารคัดหลั่งด้วยน้ำ และน้ำยาฟอกผ้าขาว 5% โซเดียมไฮโปคลอไรท์ (เช่น ไฮเตอร์,คลอรอกซ์) โดยใช้ 5 % โซเดียมไฮโปคลอไรท์ น้ำยาฟอกขาว 1 ส่วนต่อน้ำ 99 ส่วน หรือ 0.5 % (น้ำยาฟอกขาว 1 ส่วนต่อน้ำ 9 ส่วน" : "it is recommended to clean the toilet or areas that might be contaminated with phlegm , nasal discharge, feces, urine or secretion, with bleach 5% Sodium Hypochlorite (e.g. Haiter, Clorox) by using 5% Sodium Hypochlorite bleach and water with ratio of 1: 99 or 0.5% (bleach to water = 1: 9)",
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
            {
              columns: [
                { text: "9.", width: "5%", style: "fieldHeader" },
                {
                  margin: [0, 0, 40, 0],
                  text: [
                    {
                      text: props.language === "TH" ? "แยกสิ่งของส่วนตัวไม่ใช้ร่วมกับผู้อื่น " : "Separate personal items and do not share with others ",
                      style: "fieldHeader",
                    },
                    {
                      text:
                        props.language === "TH" ? "เช่น จาน ช้อนส้อม แก้วน้ำ ผ้าเช็ดตัว โทรศัพท์ คอมพิวเตอร์" : "such as plates, spoons, forks, glasses, towels, telephones, computers and so on. ",
                    },
                  ],
                  columnGap: 5,
                },
              ],
            },
            {
              columns: [
                { text: "10.", width: "5%", style: "fieldHeader" },
                {
                  columns: [
                    {
                      margin: [0, 0, 40, 0],
                      stack: [
                        {
                          text: [
                            {
                              text: props.language === "TH" ? "ไม่ร่วมรับประทานอาหารกับผู้อื่น " : "Do not eat with others: ",
                              style: "fieldHeader",
                              width: "auto",
                            },
                            {
                              text: props.language === "TH" ?
                                "ควรให้ผู้อื่นจัดหาอาหารมาให้ แล้วแยกรับประทานคนเดียว ถ้าเป็นอาหารที่สั่งมา และต้องเป็น ผู้รับอาหารนั้น ควรให้ผู้ส่งอาหารวางอาหารไว้ ณ จุดที่สะดวก แล้วไปนำอาหารเข้าบ้าน ไม่รับอาหารโดยตรง จากผู้ส่งอาหาร" :
                                "It is highly recommended that you eat alone during home isolation period. If you order delivery foods, you need to advise the delivery person to leave the foods outside your room/accommodation where you pick the foods up conveniently.",
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
            {
              columns: [
                { text: "11.", width: "5%", style: "fieldHeader" },
                {
                  columns: [
                    {
                      margin: [0, 0, 40, 0],
                      stack: [
                        {
                          text: [
                            {
                              text: props.language === "TH" ? "ซักเสื้อ ผ้า ผ้าปูเตียง ผ้าขนหนู " : "Wash clothes, bed sheets, towels: ",
                              style: "fieldHeader",
                            },
                            {
                              text:
                                props.language === "TH" ? "ฯลฯ ด้วยน้ำและสบู่หรือผงซักฟอกตามปกติ หากใช้เครื่องซักผ้า ให้ใช้ผงชักฟอก และน้ำยาปรับผ้านุ่มได้ หรือซักด้วยน้ำร้อนที่ อุณหภูมิน้ำ 60-90 องศาเซลเซียส ร่วมกับผงซักฟอกหรือน้ำยาซักผ้าหากสามารถทำได้" : 
                                "Launder items with laundry soap or household detergent. Wash high-risk clothes at 60°C – 90°C water setting.",
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
            {
              columns: [
                { text: "12.", width: "5%", style: "fieldHeader" },
                {
                  columns: [
                    {
                      margin: [0, 0, 40, 0],
                      stack: [
                        {
                          text: [
                            {
                              text: props.language === "TH" ? "แยกถุงขยะของตนต่างหาก " : "Separate your waste collected in red bags from general waste: ",
                              style: "fieldHeader",
                              width: "auto",
                            },
                            {
                              text:
                                props.language === "TH" ? "ขยะทั่วไปให้ทิ้งลงถุงได้ทันที ขยะที่อาจปนเปื้อนเสมหะ น้ำมูก สารคัดหลังอื่น ๆ เช่น หน้ากากอนามัย กระดาษทิชชู ฯลฯ ให้ทิ้งในถุงพลาสติก เทน้ำยาฟอกผ้าขาว โซเดียมไฮโปคลอไรต์เข้มข้น 500 ส่วนต่อล้านส่วนหรือ 0.05%  (น้ำยาฟอกขาว 1 ส่วนต่อน้ำ 99 ส่วน) ลงในถุงเพื่อฆ่าเชื้อก่อน แล้วผูกปากถุงให้สนิทก่อนทิ้งรวมกับขยะทั่วไป หลังจากนั้นต้องล้างมือ ด้วยน้ำและสบู่ทุกครั้ง" : 
                                "Dispose waste in red bag since the waste might be contaminated with phlegm, nasal discharge, other secretion, face masks, tissue papers etc. Spray inside the red bags with Sodium Hypochlorite   mixed in water (ratio 500:1,000,000) in order to disinfect the waste prior to handling the red bags. Always wash your hands with water and soap after handling the waste bags. ",
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
            {
              columns: [
                { text: "13.", width: "5%", style: "fieldHeader" },
                {
                  columns: [
                    {
                      margin: [0, 0, 40, 0],
                      stack: [
                        {
                          text: [
                            {
                              text:
                                props.language === "TH" ? "แจ้งอุณหภูมิร่างกาย และค่าความอิ่มตัวของออกซิเจนในเลือด " : "Report your body temperature and oxygen saturation ",
                              style: "fieldHeader",
                            },
                            {
                              text:
                                props.language === "TH" ? "วันละ 2 ครั้ง 8.00 น. และ 18.00 น. ผ่านช่องทางที่โรงพยาบาลกำหนด" : 
                                "twice a day at  0800 hr and 1600 hr via self-monitoring questionnaire in the application designated by the hospital.",
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
            {
              columns: [
                { text: "14.", width: "5%", style: "fieldHeader" },
                {
                  columns: [
                    {
                      margin: [0, 0, 40, 0],
                      stack: [
                        {
                          text: [
                            {
                              text:
                                props.language === "TH" ? "*หากมีอาการป่วยเกิดขึ้น ใหม่หรืออาการเดิมมากขึ้น " : "*Should your condition deteriorates during the home isolation period ",
                              style: "fieldHeader",
                            },
                            {
                              text:
                                props.language === "TH" ? "เช่น ไข้สูง ไอมาก เหนื่อย แน่นหน้าอก หอบ หายใจไม่สะดวก ให้รีบแจ้ง โรงพยาบาล การเดินทางมาโรงพยาบาลไม่ใช้รถหรือเรือสาธารณะ ให้ใช้รถยนต์ส่วนตัวและให้เปิดหน้าต่างรถยนต์ไว้เสมอ หรือขอรถพยาบาลมารับ **หากมีข้อสงสัย โทรสอบถามโรงพยาบาล เบอร์ 1719 หรือ สายด่วนกรมควบคุมโรคโทร 1422" : 
                                "(e.g. high-grade fever, lethargy, chest tightness, shortness of breath, difficulty breathing), please do not hesitate to contact the hospital at once. You must not travel to the hospital via public transport. The hospital will assist arranging an ambulance in case of an emergency. ** if you have any concerns, please call the hospital at 1719 or hotline of Department of Disease Control at 1422.",
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          text:
            props.language === "TH" ? "ณ ที่นี้ข้าพเจ้าขอแสดงความยินยอม/เจตนาในการแยกกักตัวที่บ้าน (Home Isolation) เนื่องจากการติดเชื้อไวรัสโคโรนา 2019" : "I hereby agree and give my consent to participate in the COVID-19 Home Isolation Program.",
          margin: props.language === "TH"? [30, 0, 0, 0]: [30, 10, 0, 10],
        },
        // {
        //   margin: props.language === "TH"? [0, 0, 20, 0]: ,
        //   text: props.language === "TH" ? `ชื่อผู้ป่วย ${props.patientName} (ตัวบรรจง)ลงนามผู้ป่วย ${props.patientSignature} วันที่ ${props.patientSignDate} เวลา ${props.patientSignTime} น.` : `Patient’s Name ${props.patientName} (Printed name) Patient’s signature ${props.patientSignature} Date ${props.patientSignDate} Time ${props.patientSignTime}`,
        //   alignment: 'left',
        // },
        (props.language === "TH"? {
          margin: [0, 0, 20, 0],
          text: `ชื่อผู้ป่วย ${props.patientName} (ตัวบรรจง)ลงนามผู้ป่วย ${props.patientSignature} วันที่ ${props.patientSignDate} เวลา ${props.patientSignTime} น.`,
          alignment: 'left',
        }: {}),
        (props.language === "EN"? {
          margin: [0, 0, 60, 0],
          stack: [
            {
              text: [
                {text: "Patient’s Name ", width: "auto"},
                {text: props.patientName, style: "fieldName", width: "auto"}, 
                {text: " (Printed name) Patient’s signature Digitally signed by ", width: "auto"},
                {text: props.patientSignature, style:"fieldName"}
              ]
            },
            {text: ` Date ${props.patientSignDate} Time ${props.patientSignTime}`}
          ]
        }: {}),
        {
          text: props.language === "TH" ? "ชื่อพยาน_____________________________________(ตัวบรรจง)ลงนามพยาน _______________________ วันที่__________เวลา______น." : "Witness’s Name___________________________(Printed name) Witness’s signature ___________________ Date__________Time______",
          alignment: 'justify',
        },
        {
          text: props.language === "TH" ? "ชื่อพยาน_____________________________________(ตัวบรรจง)ลงนามพยาน _______________________ วันที่__________เวลา______น." : "Witness’s Name___________________________(Printed name) Witness’s signature ___________________ Date__________Time______",
          alignment: 'justify',
        },
        {
          text: props.language === "TH" ? "(เฉพาะกรณีพิมพ์ลายนิ้วมือผู้ป่วย)" : "(In case where the patient’s fingerprint is needed) ",
          alignment: 'justify',
        },
        {
          text: props.language === "TH" ? "ชื่อแพทย์/ทันตแพทย์/ผู้ได้รับมอบหมาย________________(ตัวบรรจง)ลงนามผู้ให้ข้อมูล____________________วันที่__________เวลา______น." : "Medical Staff’s Printed Name __________________________Medical Staff’s signature___________________ Date__________Time______",
          alignment: 'justify',
        },
        {
          text: props.language === "TH" ? "โปรดระบุเหตุผลที่ทำให้ผู้ป่วยไม่สามารถเซ็นยินยอมเพื่อรับการรักษาด้วยตนเองได้" : "(Medical staff who provided the information to the patient)",
          alignment: 'justify',
        },
        props.language === "EN" ? (
          {
            margin: [0, 0, 0, 0],
            text: "State the reason why the patient is unable to provide consent by themselves (or to self-sign on this form)",
            alignment: 'justify',
          }
        ) : {},
        {
          margin: [30, 0, 0, 0],
          columns: [
            {
              width: "auto",
              table: {
                widths: [1],
                height: [1],
                body: [
                  [
                    props.isNotLawful
                      ? {
                        margin: [-3, -3, 0, 0],
                        image: "checkmark",
                        width: 10,
                        height: 10,
                      }
                      : {
                        margin: [-3, -12, 0, 0],
                        text: " ",
                      },
                  ],
                ],
              },
            },
            {
              text: props.language === "TH" ? "ยังไม่บรรลุนิติภาวะ - ผู้ป่วยอายุไม่ครบ 20 ปี บริบูรณ์" : "Minor-any unmarried male or female whose age has not reached the age of consent (20-year-old)",
            },
          ],
          columnGap: 5,
        },
        {
          margin: [30, 0, 0, 0],
          columns: [
            {
              width: "auto",
              table: {
                widths: [1],
                height: [1],
                body: [
                  [
                    props.isDisabillity
                      ? {
                        margin: [-3, -3, 0, 0],
                        image: "checkmark",
                        width: 10,
                        height: 10,
                      }
                      : {
                        margin: [-3, -12, 0, 0],
                        text: " ",
                      },
                  ],
                ],
              },
            },
            { text: props.language === "TH" ? "ผู้บกพร่องทางกายหรือจิต _______________________________________________________________________________" : 
            "Physical / mental disorder _______________________________________________________________________________", 
            width: "auto" },
            // { text: props.disabillityText },
          ],
          columnGap: 5,
        },
        {
          margin: [30, 0, 0, 0],
          columns: [
            {
              width: "auto",
              table: {
                widths: [1],
                height: [1],
                body: [
                  [
                    props.isOtherReason
                      ? {
                        margin: [-3, -3, 0, 0],
                        image: "checkmark",
                        width: 10,
                        height: 10,
                      }
                      : {
                        margin: [-3, -12, 0, 0],
                        text: " ",
                      },
                  ],
                ],
              },
            },
            { text: props.language === "TH" ? "อื่น ๆ (โปรดระบุ)" : "Other (please specify)) ___________________________________________________________________________________" },
            // { text: props.otherReasonText },
          ],
          columnGap: 5,
        },
        {
          margin: [0, 0, 40, 0],
          text: props.language === "TH" ? "ข้อความดังกล่าวข้างต้นได้ถูกอธิบายให้ผู้ที่มีอำนาจตามกฎหมายในการตัดสินใจแทนผู้ป่วย (นามดังกล่าวข้างล่าง) ได้รับทราบและแสดงความยินยอม เพื่อรับการรักษาดังกล่าวแล้ว" : "Above information has been formally explained to legally authorized representative (as named below) who has acknowledged and hereby given the consent for the mentioned treatments.",
        },
        props.language === "TH" ? (
          {
            text: "ชื่อผู้แทนผู้ป่วย_______________________(ตัวบรรจง)ลงนามผู้แทนผู้ป่วย________________________วันที่______________เวลา_________น.",
            alignment: 'justify',
          }
        ) : {},
        props.language === "TH" ? (
          {
            text: "ความสัมพันธ์กับผู้ป่วย______________________________หมายเลขบัตรประจำตัว _______________________________________________",
            alignment: 'justify',
          }
        ) : {},

        props.language === "EN" ? (
          {
            margin: [0, 0, 0, 0],
            stack: [
              {
                text: "Legally authorized representative (LAR)’s Name______________________(Printed name) LAR’s Signature ______________________",
                alignment: 'justify',
              },
              {
                text: "Date: ___________Time________Relationship________________________ Identification number ______________________________",
                alignment: 'justify',
              }
            ]
          }
        ) : {},
        {
          text: props.language === "TH" ? "สถานที่ออกบัตรฯ_________________________________วันออกบัตรฯ__________________วันที่บัตรฯหมดอายุ _______________________" : "Place of Issue:__________________________________ Date of Issue: _________________________ Date of Expiry: _____________________",
          alignment: 'justify',
        },
        {
          text: props.language === "TH" ? "ที่อยู่ปัจจุบันของผู้ที่เซ็นแทนผู้ป่วย_______________________________________________________________________________________" : "Current Address of Legally Authorized Representative: ________________________________________________________________________",
          alignment: 'justify',
        },
        props.language === "EN" ? (
          {
            text: "_______________________________________________________________________________________________________________________"
          }
        ): {},
        props.language === "EN" ? (
          {
            margin: [0, 0, 40, 0],
            stack: [
              {
                text: "Interpreter’s Statement",
                style: { bold: true },
                decoration: "underline"
              },
              {
                margin: [30, 0, 0, 0],
                text: "I have given a/an ___________________ language translation of the Consent Form for Home Isolation Program including any",
              },
              {
                text: " additional information that a doctor or assigned staff has formally explained to the patient or the patient’s representative.",
              },
              {
                margin: [30, 0, 0, 0],
                columns: [
                  {
                    width: "15%",
                    text: "Via",
                  },
                  { image: "square", width: 14 },
                  {
                    margin: [3, 0, 0, 0],
                    text: "In-person communication",
                  },
                  { image: "square", width: 14 },
                  {
                    margin: [3, 0, 0, 0],
                    text: " Tele-communication",
                  },
                ],
              },
              {
                text: "Interpreter’s Name___________________________________(Printed name) Interpreter’s Signature ____________________________",
              },
              {
                text: "Staff ID /ID Card No. /Passport No. ________________________________________________Date____________Time______________",
              },
            ]
          }
        ) : {},
        {
          margin: [-20, 0, 0, 0],
          text: props.language === "TH" ? "สำเนาให้ผู้ป่วย 1 ฉบับ" : "1 copy to patient",
          alignment: 'center',
          style: { bold: true }
        }
      ],
    },
  ];

  var docDefinition = {
    info: {
      title: props.patientName,
      author: props.patientName,
      subject: "home isolation",
      keywords: "home isolation",
    },

    defaultStyle: {
      font: "THSarabunNew",
      // alignment: 'justify'
      lineHeight: 1,
      fontSize: 13,
    },
    footer: function (currentPage, pageCount) {
      return {
        columns: [
          {
            text: "F/M-03-BHQ-087 Rev.1(23Jul 2021)",
            alignment: "left",
            style: "fontFooterPage",
            margin: [40, 20, 10, 0],
          },
          {
            text: "Page " + currentPage.toString() + " / " + pageCount,
            alignment: "center",
            style: "fontFooterPage",
            margin: [0, 20, 0, 0],
          },
          {
            text: "Internal Use Only",
            alignment: "right",
            style: "fontFooterPage",
            margin: [0, 20, 20, 0],
          },
        ],
      };
    },
    pageMargins: [40, 145, 30, 40],
    // pageMargins: [20, 250, 20, 20],
    //A4: [595.28, 841.89]
    pageSize: "A4",
    styles: {
      facultyHeader: {
        fontSize: 16,
        bold: true,
        margin: [0, 0, 0, 0],
      },
      fontHeader: {
        fontSize: 16,
        bold: true,
      },
      fontSubHeader: {
        fontSize: 12,
        bold: true,
      },
      fontHeaderTable: {
        fontSize: 14,
        bold: true,
      },
      fieldHeader: {
        bold: true,
        fontSize: 13,
      },
      fieldKey: {
        bold: true,
        fontSize: 14,
      },
      fontHeaderTableTop: {
        fontSize: 13,
        bold: true,
      },
      fieldName: {
        fontSize: 11,
      },
      fieldValue: {
        fontSize: 13,
      },
      tableNormal: {
        margin: [0, 0, 0, 0],
      },
      tableWithOutBorder: {
        margin: [0, 0, 0, 0],
        border: [false, false, false, false],
      },
      tableHeader: {
        fillColor: "white",
      },
      fontDevelopTitle: {
        fontSize: 10,
      },
      fontDevelopName: {
        fontSize: 14,
        bold: true,
      },
      fontFooter: {
        fontSize: 12,
        bold: true,
      },
      fontRemark: {
        fontSize: 11,
        bold: true,
      },
      fontFooterPage: {
        fontSize: 12,
      },
      fontAnswer: {
        fontSize: 14,
        bold: true,
      },
    },
    header: true
      ? headerConsentHomeIsolation
      : {
        margin: [45, 20, 20, 0],
        stack: [
          {
            columns: [
              {
                margin: [0, 3, 0, 0],
                width: "40%",
                stack: [
                  {
                    image: "logobkhpt",
                    width: 145,
                  },
                  {
                    margin: [0, 20, 0, 0],
                    style: "tableNormal",
                    table: {
                      widths: ["97%"],
                      heights: 31,
                      body: [
                        [
                          {
                            style: "tableHeader",
                            margin: [0, 5, 0, 0],
                            text: [
                              {
                                text:
                                  props?.language === "TH"
                                    ? "แบบประเมินภาวะสุขภาพจิต PHQ-9"
                                    : "Patient Health Questionnaire PHQ-9",
                                style: "fontHeader",
                                alignment: "center",
                              },
                            ],
                          },
                        ],
                      ],
                    },
                  },
                ],
              },
              // {
              //   image: "logobkhpt",
              //   width: 145,
              //   alignment: "right",
              // },
              {
                // star-sized columns fill the remaining space
                // if there's more than one star-column, available width is divided equally
                margin: [0, 0, 0, 0],
                width: "*",
                stack: [
                  {
                    columns: [
                      {
                        width: "auto",
                        text: [
                          { text: "Name: ", style: "fieldKey" },
                          {
                            text: props.patientName,
                            style: "fieldValue",
                            alignment: "right",
                          },
                        ],
                      },
                      {
                        // star-sized columns fill the remaining space
                        // if there's more than one star-column, available width is divided equally
                        width: "*",
                        text: [
                          { text: "Nationality: ", style: "fieldKey" },
                          { text: props.nationality, style: "fieldValue" },
                        ],
                      },
                      {
                        // star-sized columns fill the remaining space
                        // if there's more than one star-column, available width is divided equally
                        width: "*",
                        text: [
                          { text: "Room: ", style: "fieldKey" },
                          { text: props.room, style: "fieldValue" },
                        ],
                      },
                    ],
                    columnGap: 5,
                  },
                  {
                    columns: [
                      {
                        width: "35%",
                        text: [
                          { text: "HN: ", style: "fieldKey" },
                          { text: props.hn, style: "fieldValue" },
                        ],
                      },
                      {
                        // star-sized columns fill the remaining space
                        // if there's more than one star-column, available width is divided equally
                        width: "*",
                        text: [
                          { text: "Physician: ", style: "fieldKey" },
                          { text: props.physician, style: "fieldValue" },
                        ],
                      },
                    ],
                    columnGap: 5,
                  },
                  {
                    columns: [
                      {
                        width: "35%",
                        text: [
                          { text: "Visit Date: ", style: "fieldKey" },
                          { text: props.visitDate, style: "fieldValue" },
                        ],
                      },
                      {
                        // star-sized columns fill the remaining space
                        // if there's more than one star-column, available width is divided equally
                        width: "*",
                        text: [
                          { text: "Department: ", style: "fieldKey" },
                          { text: props.department, style: "fieldValue" },
                        ],
                      },
                    ],
                    columnGap: 5,
                  },
                  {
                    columns: [
                      {
                        width: "35%",
                        text: [
                          { text: "Birth Date: ", style: "fieldKey" },
                          { text: props.birthDate, style: "fieldValue" },
                        ],
                      },
                      {
                        // star-sized columns fill the remaining space
                        // if there's more than one star-column, available width is divided equally
                        width: "*",
                        text: [
                          { text: "Age: ", style: "fieldKey" },
                          { text: props.age, style: "fieldValue" },
                        ],
                      },
                      {
                        // star-sized columns fill the remaining space
                        // if there's more than one star-column, available width is divided equally
                        width: "*",
                        text: [
                          { text: "Sex: ", style: "fieldKey" },
                          { text: props.sex, style: "fieldValue" },
                        ],
                      },
                    ],
                    columnGap: 5,
                  },
                  {
                    columns: [
                      {
                        width: "*",
                        text: [
                          { text: "Allergies: ", style: "fieldKey" },
                          { text: props.allergies, style: "fieldValue" },
                        ],
                      },
                    ],
                    columnGap: 5,
                  },
                  {
                    columns: [
                      {
                        width: "*",
                        text: [
                          { text: "Side Effect: ", style: "fieldKey" },
                          { text: props.sideEffect, style: "fieldValue" },
                        ],
                      },
                    ],
                    columnGap: 5,
                  },
                ],
              },
            ],
            // optional space between columns
            columnGap: 5,
          },
        ],
      },
    content: true
      ? contentConsentHomeIsolation
      : [
        {
          stack: [
            {
              text:
                props?.language === "TH"
                  ? "ในช่วง 2 สัปดาห์ที่ผ่านมา หรือระหว่างที่นอนพักรักษาตัวในโรงพยาบาล ท่านมีอาการดังต่อไปนี้บ่อยแค่ไหน"
                  : "Over the last 2 weeks or during hospitalization, how often have you been bothered by any of the following problem?",
              style: "fieldHeader",
            },
            {
              text:
                props?.language === "TH"
                  ? "กรุณาทำเครื่องหมาย √ ในช่องที่ตรงกับคำตอบของท่าน"
                  : "(Please check √ the box that correspond to your answer)",
              style: "fieldHeader",
            },
          ],
        },
        {
          style: "tableNormal",
          table: {
            widths: ["5%", "*", "14%", "14%", "14%", "14%"],
            body: [
              [
                {
                  text: props?.language === "TH" ? "ลำดับ" : "No.",
                  style: "fontHeaderTable",
                  alignment: "center",
                },
                {
                  text:
                    props?.language === "TH" ? "หัวข้อประเมิน" : "Content",
                  style: "fontHeaderTable",
                  alignment: "center",
                },
                {
                  stack: [
                    {
                      text:
                        props?.language === "TH" ? "ไม่เลย" : "Not at all",
                      style: "fontHeaderTable",
                      alignment: "center",
                    },
                    {
                      text: "0",
                      style: "fontHeaderTable",
                      alignment: "center",
                    },
                  ],
                },
                {
                  stack: [
                    {
                      text:
                        props?.language === "TH"
                          ? "มีบางวันไม่บ่อย"
                          : "Several days",
                      style: "fontHeaderTable",
                      alignment: "center",
                    },
                    {
                      text: "1",
                      style: "fontHeaderTable",
                      alignment: "center",
                    },
                  ],
                },
                {
                  stack: [
                    {
                      text:
                        props?.language === "TH"
                          ? "มีค่อนข้างบ่อย"
                          : "More than half the days",
                      style: "fontHeaderTable",
                      alignment: "center",
                    },
                    {
                      text: "2",
                      style: "fontHeaderTable",
                      alignment: "center",
                    },
                  ],
                },
                {
                  stack: [
                    {
                      text:
                        props?.language === "TH"
                          ? "เกือบทุกวัน"
                          : "Nearly every day",
                      style: "fontHeaderTable",
                      alignment: "center",
                    },
                    {
                      text: "3",
                      style: "fontHeaderTable",
                      alignment: "center",
                    },
                  ],
                },
              ],
              [
                { text: "1.", alignment: "center" },
                {
                  text:
                    props?.language === "TH"
                      ? "เบื่อทำอะไรๆ ก็ไม่เพลิดเพลิน"
                      : "Little interest or please in during things",
                  margin:
                    props?.language === "TH" ? [0, 2, 0, 2] : [0, 0, 0, 0],
                },
                props.answerMentalno1 === 0
                  ? { image: "checkmark", width: 16, alignment: "center" }
                  : {},
                props.answerMentalno1 === 1
                  ? { image: "checkmark", width: 16, alignment: "center" }
                  : {},
                props.answerMentalno1 === 2
                  ? { image: "checkmark", width: 16, alignment: "center" }
                  : {},
                props.answerMentalno1 === 3
                  ? { image: "checkmark", width: 16, alignment: "center" }
                  : {},
              ],
              [
                { text: "2.", alignment: "center" },
                {
                  text:
                    props?.language === "TH"
                      ? "ไม่สบายใจ ซึมเศร้า หรือท้อแท้"
                      : "Feeling down, depressed, or hopeless",
                  margin:
                    props?.language === "TH" ? [0, 2, 0, 2] : [0, 0, 0, 0],
                },
                props.answerMentalno2 === 0
                  ? { image: "checkmark", width: 16, alignment: "center" }
                  : {},
                props.answerMentalno2 === 1
                  ? { image: "checkmark", width: 16, alignment: "center" }
                  : {},
                props.answerMentalno2 === 2
                  ? { image: "checkmark", width: 16, alignment: "center" }
                  : {},
                props.answerMentalno2 === 3
                  ? { image: "checkmark", width: 16, alignment: "center" }
                  : {},
              ],
              [
                { text: "3.", alignment: "center" },
                {
                  lineHeight: props?.language === "TH" ? 1 : 0.8,
                  text:
                    props?.language === "TH"
                      ? "หลับยาก หรือหลับๆ ตื่นๆ หรือหลับมากไป"
                      : "Trouble falling / Staying asleep, sleeping too much",
                  margin:
                    props?.language === "TH" ? [0, 2, 0, 2] : [0, 0, 0, 0],
                },
                props.answerMentalno3 === 0
                  ? { image: "checkmark", width: 16, alignment: "center" }
                  : {},
                props.answerMentalno3 === 1
                  ? { image: "checkmark", width: 16, alignment: "center" }
                  : {},
                props.answerMentalno3 === 2
                  ? { image: "checkmark", width: 16, alignment: "center" }
                  : {},
                props.answerMentalno3 === 3
                  ? { image: "checkmark", width: 16, alignment: "center" }
                  : {},
              ],
              [
                { text: "4.", alignment: "center" },
                {
                  text:
                    props?.language === "TH"
                      ? "เหนื่อยง่าย หรือไม่ค่อยมีแรง"
                      : "Feeling tired or having little energy",
                  margin:
                    props?.language === "TH" ? [0, 2, 0, 2] : [0, 0, 0, 0],
                },
                props.answerMentalno4 === 0
                  ? { image: "checkmark", width: 16, alignment: "center" }
                  : {},
                props.answerMentalno4 === 1
                  ? { image: "checkmark", width: 16, alignment: "center" }
                  : {},
                props.answerMentalno4 === 2
                  ? { image: "checkmark", width: 16, alignment: "center" }
                  : {},
                props.answerMentalno4 === 3
                  ? { image: "checkmark", width: 16, alignment: "center" }
                  : {},
              ],
              [
                { text: "5.", alignment: "center" },
                {
                  text:
                    props?.language === "TH"
                      ? "เบื่ออาหาร หรือกินมากเกินไป"
                      : "Poor appetite or overeating",
                  margin:
                    props?.language === "TH" ? [0, 2, 0, 2] : [0, 0, 0, 0],
                },
                props.answerMentalno5 === 0
                  ? { image: "checkmark", width: 16, alignment: "center" }
                  : {},
                props.answerMentalno5 === 1
                  ? { image: "checkmark", width: 16, alignment: "center" }
                  : {},
                props.answerMentalno5 === 2
                  ? { image: "checkmark", width: 16, alignment: "center" }
                  : {},
                props.answerMentalno5 === 3
                  ? { image: "checkmark", width: 16, alignment: "center" }
                  : {},
              ],
              [
                { text: "6.", alignment: "center" },
                {
                  lineHeight: props?.language === "TH" ? 1 : 0.8,
                  text:
                    props?.language === "TH"
                      ? "รู้สึกไม่ดีกับตัวเอง-คิดว่าตัวเองล้มเหลว หรือเป็นคนทำให้ตัวเอง หรือครอบครัวผิดหวัง"
                      : "You are a failure, or have let yourself or your family down",
                  margin:
                    props?.language === "TH" ? [0, 2, 0, 2] : [0, 0, 0, 0],
                },
                props.answerMentalno6 === 0
                  ? { image: "checkmark", width: 16, alignment: "center" }
                  : {},
                props.answerMentalno6 === 1
                  ? { image: "checkmark", width: 16, alignment: "center" }
                  : {},
                props.answerMentalno6 === 2
                  ? { image: "checkmark", width: 16, alignment: "center" }
                  : {},
                props.answerMentalno6 === 3
                  ? { image: "checkmark", width: 16, alignment: "center" }
                  : {},
              ],
              [
                { text: "7.", alignment: "center" },
                {
                  lineHeight: props?.language === "TH" ? 1 : 0.8,
                  text:
                    props?.language === "TH"
                      ? "สมาธิไม่ดีเวลาทำอะไร เช่น ดูโทรทัศน์ ฟังวิทยุ หรือทำงานที่ต้องใช้ความตั้งใจ"
                      : "Trouble concentration on things, such as reading the newspaper or watching television",
                  margin:
                    props?.language === "TH" ? [0, 2, 0, 2] : [0, 0, 0, 0],
                },
                props.answerMentalno7 === 0
                  ? { image: "checkmark", width: 16, alignment: "center" }
                  : {},
                props.answerMentalno7 === 1
                  ? { image: "checkmark", width: 16, alignment: "center" }
                  : {},
                props.answerMentalno7 === 2
                  ? { image: "checkmark", width: 16, alignment: "center" }
                  : {},
                props.answerMentalno7 === 3
                  ? { image: "checkmark", width: 16, alignment: "center" }
                  : {},
              ],
              [
                { text: "8.", alignment: "center" },
                {
                  lineHeight: props?.language === "TH" ? 1 : 0.8,
                  text:
                    props?.language === "TH"
                      ? "พูดหรือทำอะไรช้าจนคนอื่นมองเห็น หรือกระสับกระส่ายจนท่านไม่อยู่นิ่งเหมือนเคย"
                      : "Moving or speaking so slowly that other people could have noticed, or being so fidgety or restless that you have been moving around more than usual",
                  margin:
                    props?.language === "TH" ? [0, 2, 0, 2] : [0, 0, 0, 0],
                },
                props.answerMentalno8 === 0
                  ? { image: "checkmark", width: 16, alignment: "center" }
                  : {},
                props.answerMentalno8 === 1
                  ? { image: "checkmark", width: 16, alignment: "center" }
                  : {},
                props.answerMentalno8 === 2
                  ? { image: "checkmark", width: 16, alignment: "center" }
                  : {},
                props.answerMentalno8 === 3
                  ? { image: "checkmark", width: 16, alignment: "center" }
                  : {},
              ],
              [
                { text: "9.", alignment: "center" },
                {
                  lineHeight: props?.language === "TH" ? 1 : 0.8,
                  text:
                    props?.language === "TH"
                      ? "คิดทำร้ายตนเอง หรือคิดว่าถ้าตายๆ ไปเสียคงดี"
                      : "Thoughts that you would be better off dead or of hurting yourself in some way",
                  margin:
                    props?.language === "TH" ? [0, 2, 0, 2] : [0, 0, 0, 0],
                },
                props.answerMentalno9 === 0
                  ? { image: "checkmark", width: 16, alignment: "center" }
                  : {},
                props.answerMentalno9 === 1
                  ? { image: "checkmark", width: 16, alignment: "center" }
                  : {},
                props.answerMentalno9 === 2
                  ? { image: "checkmark", width: 16, alignment: "center" }
                  : {},
                props.answerMentalno9 === 3
                  ? { image: "checkmark", width: 16, alignment: "center" }
                  : {},
              ],
              [
                {
                  text: props.language === "TH" ? "รวม" : "Sum",
                  alignment: "right",
                  style: "fontHeaderTable",
                  colSpan: 2,
                  margin:
                    props?.language === "TH" ? [0, 2, 0, 2] : [0, 0, 0, 0],
                },
                {},
                {
                  text: props.answerMentalSum0,
                  alignment: "center",
                  style: "fontHeaderTable",
                },
                {
                  text: props.answerMentalSum1,
                  alignment: "center",
                  style: "fontHeaderTable",
                },
                {
                  text: props.answerMentalSum2,
                  alignment: "center",
                  style: "fontHeaderTable",
                },
                {
                  text: props.answerMentalSum3,
                  alignment: "center",
                  style: "fontHeaderTable",
                },
              ],
              [
                {
                  text:
                    props.language === "TH"
                      ? "คะแนนรวมทั้งหมด ="
                      : "Total score =",
                  alignment: "right",
                  style: "fontHeaderTable",
                  colSpan: 2,
                  margin:
                    props?.language === "TH" ? [0, 2, 0, 2] : [0, 0, 0, 0],
                },
                {},
                {
                  text: props.answerMentalTotal,
                  alignment: "center",
                  style: "fontHeaderTable",
                  colSpan: 4,
                  margin:
                    props?.language === "TH" ? [0, 2, 0, 2] : [0, 0, 0, 0],
                },
                {},
                {},
                {},
              ],
            ],
          },
        },
        {
          style: "tableNormal",
          margin: [0, 10, 0, 2],
          table: {
            widths: ["60%", "*"],
            body: [
              [
                {
                  stack: [
                    {
                      text:
                        props.language === "TH"
                          ? "ถ้าท่านตอบว่ามีอาการ ไม่ว่าในข้อใดก็ตาม อาการนั้นๆ ทำให้ท่านมีปัญหาใน การทำงาน การดูแลสิ่งต่างๆ ในบ้าน หรือการเข้ากับผู้คนหรือไม่"
                          : "(If you checked off any problem on this questionnaire so far, how difficult have these problems made it for you to do your work, take care of things at home, or get along with other people?",
                      alignment: "left",
                    },
                    {
                      text:
                        props.language === "TH"
                          ? "(กรุณาทำเครื่องหมาย √ ในช่องที่ตรงกับคำตอบของท่าน)"
                          : "(Please check  √ the box that correspond to your answer)",
                      alignment: "left",
                    },
                  ],
                },
                {
                  columns: [
                    [
                      {
                        columns: [
                          props.answerDifficult === "Not difficult at all"
                            ? { image: "checked", width: 16 }
                            : { image: "uncheck", width: 16 },
                          {
                            width: "*",
                            text: [
                              {
                                text:
                                  props.language === "TH"
                                    ? "ไม่มีปัญหาเลย"
                                    : "Not difficult at all",
                              },
                            ],
                          },
                          props.answerDifficult === "Somewhat difficult"
                            ? { image: "checked", width: 16 }
                            : { image: "uncheck", width: 16 },
                          {
                            // star-sized columns fill the remaining space
                            // if there's more than one star-column, available width is divided equally
                            width: "*",
                            text: [
                              {
                                text:
                                  props.language === "TH"
                                    ? "มีปัญหาบ้าง"
                                    : "Somewhat difficult",
                              },
                            ],
                          },
                        ],
                        columnGap: 5,
                      },

                      {
                        columns: [
                          props.answerDifficult === "Very difficult"
                            ? { image: "checked", width: 16 }
                            : { image: "uncheck", width: 16 },
                          {
                            width: "*",
                            text: [
                              {
                                text:
                                  props.language === "TH"
                                    ? "มีปัญหามาก"
                                    : "Very difficult",
                              },
                            ],
                          },
                          props.answerDifficult === "Extremely difficult"
                            ? { image: "checked", width: 16 }
                            : { image: "uncheck", width: 16 },
                          {
                            // star-sized columns fill the remaining space
                            // if there's more than one star-column, available width is divided equally
                            width: "*",
                            text: [
                              {
                                text:
                                  props.language === "TH"
                                    ? "มีปัญหามากที่สุด"
                                    : "Extremely difficult",
                              },
                            ],
                          },
                        ],
                        // optional space between columns
                        columnGap: 5,
                      },
                    ],
                  ],
                },
              ],
            ],
          },
        },
        {
          margin: [10, 0, 0, 0],
          stack: [
            {
              columns: [
                props.unableToAccess === true
                  ? { image: "checked", width: 16 }
                  : { image: "uncheck", width: 16 },
                {
                  margin: [5, 0, 0, 0],
                  text:
                    props.language === "TH"
                      ? " ไม่สามารถประเมินภาวะสุขภาพจิตได้ในขณะนี้ "
                      : " Unable to assess the mental health at this time",
                },
              ],
            },
            {
              text: [
                {
                  text:
                    props.language === "TH"
                      ? "กรณีคะแนนประเมินอยู่ระหว่าง 10 –27 คะแนน รายงานแพทย์เจ้าของไข้ "
                      : "If total score is between 10 and 27, Notify primary physician ",
                },
                { text: "", style: "fontAnswer" },
              ],
            },
            {
              columns: [
                props.consultPsychiatrist === true
                  ? { image: "checked", width: 16 }
                  : { image: "uncheck", width: 16 },
                {
                  width: "20%",
                  text: [
                    {
                      text:
                        props.language === "TH"
                          ? "ปรึกษาจิตแพทย์"
                          : "Consult psychiatrist",
                    },
                  ],
                },
                props.consultPsychologist === true
                  ? { image: "checked", width: 16 }
                  : { image: "uncheck", width: 16 },
                {
                  width: "30%",
                  text: [
                    {
                      text:
                        props.language === "TH"
                          ? "ปรึกษานักจิตวิทยาคลีนิค"
                          : "Consult psychologist Date ",
                    },
                  ],
                },
                {
                  width: "*",
                  text: [
                    {
                      text:
                        props.language === "TH"
                          ? "วันที่ " +
                          props.consultPsychologistDate +
                          " เวลา " +
                          props.consultPsychologistTime +
                          " น."
                          : "Date " +
                          props.consultPsychologistDate +
                          " Time " +
                          props.consultPsychologistTime,
                    },
                  ],
                },
              ],
              columnGap: 5,
            },
            {
              columns: [
                props.notConsultPsy === true
                  ? { image: "checked", width: 16 }
                  : { image: "uncheck", width: 16 },
                {
                  margin: [5, 0, 0, 0],
                  text:
                    props.language === "TH"
                      ? "พิจารณายังไม่ส่งปรึกษาจิตแพทย์/ นักจิตวิทยาคลีนิค "
                      : "Not consult psychiatrist / psychologist",
                },
              ],
            },
          ],
        },
        {
          margin: [0, 5, 0, 0],
          columns: [
            {
              width: "55%",
              text: [
                {
                  text:
                    props.language === "TH"
                      ? "พยาบาลผู้รายงาน "
                      : "Registered Nurse ",
                },
                { text: props.registeredNurse, style: "fontAnswer" },
              ],
            },
            {
              // star-sized columns fill the remaining space
              // if there's more than one star-column, available width is divided equally
              width: "*",
              margin: [18, 0, 0, 0],
              text: [
                {
                  text:
                    props.language === "TH"
                      ? "วันที่ " +
                      props.registeredNurseDate +
                      " เวลา " +
                      props.registeredNurseTime +
                      " น."
                      : "Date " +
                      props.registeredNurseDate +
                      " Time " +
                      props.registeredNurseTime,
                },
              ],
            },
          ],
          columnGap: 5,
        },
        {
          margin: [0, 2, 0, 0],
          text: [
            { text: "พัฒนาโดย", style: "fontDevelopTitle" },
            {
              text:
                " Drs. Robert L. Spitzer, Janet B.W. Williams, Kurt Kroenke",
              style: "fontDevelopName",
            },
            {
              text: " และคณะ โดยได้รับทุนสนับสนุนการศึกษาจาก",
              style: "fontDevelopTitle",
            },
            { text: " Pfizer Inc", style: "fontDevelopName" },
          ],
        },
        {
          margin: [10, 3, 0, 0],
          columns: [
            {
              width: "75%",
              stack: [
                {
                  text:
                    "Remark : Please mark “N/A“ under the item that is not applicable",
                  style: "fontRemark",
                  margin: [0, 0, 0, -3],
                },
                {
                  columns: [
                    {
                      width: "25%",
                      text: "Dangerous abbreviations :",
                      style: "fontRemark",
                    },
                    {
                      // star-sized columns fill the remaining space
                      // if there's more than one star-column, available width is divided equally
                      width: "*",
                      stack: [
                        {
                          text:
                            "1) U 2) IU 3) Q.D. 4) Q.O.D. 5) MS 6) MS04 7) MgS04 8) Never write “0” after",
                          style: "fontRemark",
                        },
                        {
                          text:
                            "a decimal point [X mg] 9) always use “0” before a decimal point [0.X mg] 10) OD",
                          style: "fontRemark",
                          margin: [0, -3, 0, 0],
                        },
                      ],
                    },
                  ],
                  columnGap: 5,
                },
              ],
            },
            {
              // star-sized columns fill the remaining space
              // if there's more than one star-column, available width is divided equally
              width: "*",
              margin: [0, 3, 0, 0],
              stack: [
                {
                  text: "Scanned by",
                  style: "fontFooter",
                  alignment: "center",
                },
                {
                  text: " " + props.scanBy + " ",
                  style: "fontFooter",
                  alignment: "center",
                  decoration: "underline",
                },
              ],
            },
          ],
          columnGap: 5,
        },
      ],
    images: {
      logobkhpt: origin + "/static/images/logobkhpt.png",
      checked: origin + "/static/images/checked.png",
      uncheck: origin + "/static/images/uncheck.png",
      checkmark: origin + "/static/images/checkmark.png",
      square: origin + "/static/images/square.png"
    },
  };

  var win = window.open("", "_blank");
  pdfMake.createPdf(docDefinition).open({}, win);
};
