import WasmController, { WasmHandler } from 'react-lib/frameworks/WasmController'
import * as FirebaseI from 'react-lib/frameworks/FirebaseInterface'
import axios from "axios"
import * as Diagform from "issara-sdk/DiagForm_apps_PRX"
import DivisionProfileList from "issara-sdk/apis/DivisionProfileList_apps_PRX"
import config from "../config/config";

export type State =
  {
    patientSegmentList?: any[],
    apiToken?: string,
    diagFormDetail?: any,
    isLoadingFormAnalytics?: boolean,
    divisionProfileList?: {
      items: any[]
    }
  }

export const StateInitial =
{
  patientSegmentList: [],
  diagFormDetail: {},
  isLoadingFormAnalytics: false
}

export type Event =
  { message: "DidMount", params: {} } |
  { message: "LoginFirebase", params: {} } |
  { message: "GetPatientSegment", params: {} } |
  { message: "GetDiagFormById", params: { id: number } } |
  { message: "GetDivisionProfile", params: { division: number; code?: string } }


export type Data = {}

export const DataInitial = {}
const DIVISION = config.FORM_ANALYTICS_DIVISION
const PREFIX = config.FORM_ANALYTICS_PREFIX

type Handler = WasmHandler<State, Event>

export const DidMount: Handler = async (controller, params) => {
  console.log("DidMount", params)
  const onFirebaseAuthStateChanged = (user: any) => {
    if (user) {
      // Check if logged in
      console.log("user: ", user.uid);
    } else {
      console.log("no user");
    }
  };
  controller.app.auth().onAuthStateChanged(onFirebaseAuthStateChanged);
}

export const LoginFirebase: Handler = async (controller, params) => {
  console.log("LoginFirebase", params)
  FirebaseI.CustomLoginWithUsername(controller as any, { username: params.userId })
}

export const GetPatientSegment: Handler = async (controller, params) => {
  console.log("GetPatientSegment", params)
  // const result = await controller.db.collection("PatientSegment")
  //   .where("diag_rule", "==", "แบบสอบถามข้อมูลเบื้องต้น สำหรับผู้รับบริการ Tele My b+")
  //   .get()
  // const result = await controller.db.collection("PatientSegment")
  //   .where("diag_rule", "==", "ConsentFormBDMS")
  //   .where("form_updated_date", ">=", "2021-04-25 12")
  //   .get()
  // const patiengSegmentList = result.docs.map((doc: any) => doc.data() )

  // Get from local for development purpose
  // const response = await axios.get("/patientSegment.json")
  // console.log(response.data)
  // controller.setState({ patientSegmentList: response.data })

  // Test calling api from gen_ts2

  controller.setState({
    isLoadingFormAnalytics: true
  })

  // const [r, e, n] = await Diagform.DiagFormList_apps_PRX.list({
  //   apiToken: controller.getState().apiToken
  // })

  // console.log(r)


  // if (e) {
  //   console.log(e)
  //   console.log(n)
  //   return
  // }
  // console.log(r)
  if (PREFIX && params.divisionHasUser.findIndex(division => division.id === DIVISION) === -1) {
    controller.setState({ patientSegmentList: [], isLoadingFormAnalytics: false })
    return
  }

  // Get data from GCS
  controller.storage.refFromURL('gs://mybplus-segment')
    .child(`${PREFIX}-${DIVISION}-แบบประเมินอาการช่วงกักตัว [Assessment Checklist during isolation]`)
    .getDownloadURL()
    .then((url: string) => {
      axios.get(url)
        .then(res => {
          console.log(res.data)
          controller.setState({ patientSegmentList: res.data, isLoadingFormAnalytics: false })
        })
        .catch(err => {
          console.log(err)
        })
    })



}

export const GetDiagFormById: Handler = async (controller, params) => {
  const [r, e, n] = await Diagform.DiagFormDetail_apps_PRX.retrieve({
    pk: params.id,
    apiToken: controller.getState().apiToken
  })
  if (e) {
    console.log(e)
    console.log(n)
    return
  }
  controller.setState({
    diagFormDetail: r
  })
}

export const GetDivisionProfile: Handler = async (controller, params) => {
  const { division, code } = params
  const paramsUrl: any = {}
  if (division) {
    paramsUrl.division = DIVISION
  }
  if (code) {
    paramsUrl.code = code
  }
  const [r, e, n] = await DivisionProfileList.list({
    params: paramsUrl,
    apiToken: controller.getState().apiToken
  })
  if (e) {
    console.log(e)
    return
  }

  controller.setState({
    divisionProfileList: r
  })
}