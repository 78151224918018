/*eslint quotes: 0, semi: 0, no-extra-semi: 0, arrow-parens: 0, indent: 0, no-prototype-builtins: 0, no-unused-vars: 0*/
import moment from 'moment';
import _ from '../compat/lodashplus';

// sometime tuil func is not work with tsx file, please use tsUtils.tsx
export const formatToBEDatetime = ({
  date,
  startFormat = "MM/DD/YYYY HH:mm",
  endFormat = "DD/MM/YYYY HH:mm"
} = {}) => {
  if (!date) {
    return ""
  }
  let datetime = moment(date, startFormat)
  if (!moment(datetime).isValid()) {
    return ""
  }
  return datetime
    .add(543, "years")
    .format(endFormat);
};

export function formatDatetime(value) {
  //! Use convertToBEDatetime instead
  // Receive MM/DD/YYYY(AD) HH:mm -> DD/MM/YYYY(BE) HH:mm
  if (value) {
    var datetime = moment(value)
    if (!moment(datetime).isValid()) {
      return ""
    }
    var day = datetime.format('DD');
    var month = datetime.format('MM');
    var year = parseInt(datetime.format('YYYY')) + 543;
    var time = datetime.format('HH:mm');
    return day + '/' + month + '/' + year + ' ' + time
  } else {
    return ''
  }
}

export function formatDate(date) {
  //! Use convertToBEDatetime instead
  if (moment.isMoment(date)) {
    date = date.toDate();
  }
  if (date instanceof Date && !isNaN(date.valueOf())) {
    const day = `${date.getDate()}`.padStart(2, '0');
    const month = `${date.getMonth() + 1}`.padStart(2, '0');
    const year = `${date.getFullYear() + 543}`;
    return `${day}/${month}/${year}`;
  }
  return '';
}

export function adToBe(str, format = 'DD/MM/YYYY') {
  //! Use convertToBEDatetime instead
  if (str) {
    const momentDate = moment(str, format);
    return `${momentDate.format('DD/MM/')}${parseInt(momentDate.format('YYYY')) + 543}`;
  } else {
    return '';
  }
};

export function beToAd(str) {
  if (str && typeof str === 'string') {
    const tokens = str.split('/');
    const date = `${parseInt(tokens[2]) - 543}-${tokens[1]}-${tokens[0]}`;
    return moment(date);
  } else {
    return;
  }
};

export function parseDate(str, toMoment) {
  if (typeof str !== 'string') {
    return;
  }

  const split = str.split('/');
  if (split.length !== 3) {
    return;
  }

  const year = parseInt(split[2], 10) - 543;
  const month = parseInt(split[1], 10) - 1;
  const day = parseInt(split[0], 10);
  if (
    isNaN(year) ||
    String(year).length > 4 ||
    isNaN(month) ||
    isNaN(day) ||
    day <= 0 ||
    day > 31 ||
    month < 0 ||
    month >= 12
  ) {
    return;
  }

  // When using this function with DayPickerInput, the second argument will be a format not toMoment
  if (toMoment === true) {
    return moment(`${year}-${month + 1}-${day}`, 'YYYY-MM-DD');
  }
  return new Date(year, month, day);
}

// Need this for DayPickerInput or won't compile
export function parseDate1(str, toMoment) {
  if (typeof str !== 'string') {
    return new Date();
  }

  const split = str.split('/');
  if (split.length !== 3) {
    return new Date();
  }

  const year = parseInt(split[2], 10) - 543;
  const month = parseInt(split[1], 10) - 1;
  const day = parseInt(split[0], 10);
  if (
    isNaN(year) ||
    String(year).length > 4 ||
    isNaN(month) ||
    isNaN(day) ||
    day <= 0 ||
    day > 31 ||
    month < 0 ||
    month >= 12
  ) {
    return new Date();
  }

  return new Date(year, month, day);
}

export function formatDateToYYYYMMDD(dateString, seperator) {
  /*
    format date string dd/mm/yyyy (BE) to yyyy<seperator>mm<seperator>dd (AD)
  */
  if (typeof dateString !== 'string') {
    return dateString;
  }

  const dateSplited = dateString.split('/');
  if (dateSplited.length !== 3) {
    return dateString;
  }

  const year = String(parseInt(dateSplited[2], 10) - 543);
  const month = String(parseInt(dateSplited[1], 10)).padStart(2, '0');
  const day = String(parseInt(dateSplited[0], 10)).padStart(2, '0');

  if (seperator === undefined || seperator == null || seperator === '') {
    seperator = '-';
  }

  return year + seperator + month + seperator + day;
}

export const isValidDate = str => /^(0[1-9]|1\d|2\d|3[01])\/(0[1-9]|1[0-2])\/\d{4}$/.test(str);

export const downloadFile = response => {
  // from: https://github.com/kennethjiang/js-file-download/blob/master/file-download.js
  const disposition = response.headers['content-disposition'];
  const filename = decodeURI(disposition.match(/filename="?(.*)"?/)[1]);
  const blob = new Blob([response.data], {
    type: 'application/octet-stream'
  });
  const blobURL = window.URL.createObjectURL(blob);
  const tempLink = document.createElement('a');
  tempLink.style.display = 'none';
  tempLink.href = blobURL;
  tempLink.setAttribute('download', filename);
  document.body.appendChild(tempLink);
  tempLink.click();
  document.body.removeChild(tempLink);
  window.URL.revokeObjectURL(blobURL);
}

/*
  use with await handle error without using try catch
  example:
    [err, result] = await to(doingSomething({userId: user.id}));
    if(err) throw new CustomError('Error occurred while doing something');
    console.log(result) 

    // to function (promise.then.catch).then.catch
*/

export function to(promise) {
  return promise.then(data => {
      if (data.hasOwnProperty('responseData') && data.hasOwnProperty('response')) {
        return [null, data.responseData, data.response]
      } else {
        // backward
        return [null, data];
      }
    })
    .catch((error) => {
      if (error.hasOwnProperty('responseData') && error.hasOwnProperty('error')) {
        return [error.responseData, null, error.error]
      } else {
        // not backward 
        console.log('should not see me Catch Error(to)')
        return [error]
      }
    })
}

export function setStateAsync(state) {
  return new Promise((resolve) => {
    this.setState(state, resolve)
  });
}

export const formatDropdown = (optionsList, textField, keyField, imageField) => {
  // console.log('formatDropdown optionsList B',optionsList)
  if (optionsList && Array.isArray(optionsList)) {
    // console.log('formatDropdown optionsList B',optionsList)
    optionsList.forEach(items => {
      items.key = items[keyField];
      items.text = items[textField];
      items.value = items[keyField];
      if (imageField) {
        items.image = items[imageField]
      }
      delete items['is_active']
      delete items['require_flag']
      delete items['is_default']
      delete items['has_description']
      return items;
    });
    // console.log('formatDropdown optionsList A',optionsList)
    return optionsList;
  } else {
    // console.log('formatDropdown optionsList else []')
    return [];
  }

}

export const deepCompareEquals = (a, b) => {
  return _.isEqual(a, b);
}

export function getUrlParameters(urlString, preventDecode) {
  var paramString = ''
  if (urlString.indexOf('?') !== -1) { // check url in format http://xxxxxxx?xx=xx or ?xx=xx
    paramString = urlString.split('?')[1];
  } else {
    paramString = urlString;
  }

  var queryDict = {};
  paramString.split("&").forEach(function (item, index) {
    var tmp = item.split('=')
    if (preventDecode) {
      queryDict[tmp[0]] = tmp[1];
    } else {
      queryDict[tmp[0]] = decodeURIComponent(tmp[1]);
    }
  });
  return queryDict;
}

export function validateInteger(value) {
  var numbers = /^[0-9]+$/;
  return value.match(numbers)
}

export function validateDecimalPlace(value, decimalPlace) {
  var str = "^[0-9]+([.,][0-9]{1,significantDigit})?$";
  var re = str.replace('significantDigit', decimalPlace);
  var regex = RegExp(re, 'gi');
  return value.match(regex)
}

export function roundToTwo(num) {
  return +(Math.round(num + "e+2") + "e-2");
}

export function calculateBMI({
  height = 1,
  weight = 0
} = {}) {
  return weight / ((height / 100) * (height / 100))
}

export function validateTime(value) {
  var time = /^([0-1]?[0-9]|2[0-4]):([0-5][0-9])(:[0-5][0-9])?$/;
  return time.test(value)
}

export function formatComma(val) {
  return !isNaN(parseFloat(val)) ? parseFloat(val).toFixed(2).toString().replace(/\B(?=(?:\d{3})+(?!\d))/g, ",") : val;
}

export function formatPrice(val) {
  var value = formatComma(val);
  return '<div style="text-align: right; ">' + value + '</div>';
}

export const convertToADDate = ({ date } = {}) => {
  let dateArr = date.split("/");
  let adDate = `${dateArr[0]}/${dateArr[1]}/${parseInt(dateArr[2]) - 543}`;
  return adDate;
};

export function displayPDF(base64) {
  var parameters = 'toolbar=0,location=0,menubar=0'
  parameters += ',height=' + (window.screen.height - 20)
  parameters += ',width=' + window.screen.width
  var html = '' +
    '<html>' +
    '<head>' +
    '<style>' +
    'html, body {' +
    'margin: 0;' +
    'padding: 0;' +
    'border: 0;' +
    'height: 100%;' +
    'overflow: hidden;' +
    '}' +
    '</style>' +
    '</head>' +
    '<body>' +
    '<object height="100%" width="100%"' +
    'data="data:application/pdf;base64,' + base64 + '"' +
    'type="application/pdf">' +
    '</object>' +
    '</body>' +
    '</html>'
  var win = window.open('', '', parameters);
  if (!win) {
    alert('กรุณากดอนุญาติการเปิด popup', 'red')
  } else {
    win.document.write(html);
  }
}

export const checkSameObjectKeysValues = (obj1, obj2) => {
  if(!obj1 || !obj2){
    return false
  }
  //! not work for nested obj
  if (Object.keys(obj1).length === Object.keys(obj2).length) {
    return Object.keys(obj1).every(
      key => obj2.hasOwnProperty(key) &&
      obj2[key] === obj1[key]);
  }
  return false;
}
