import BaseService from "./BaseService";
import { USER } from "../../configs/apis";
class UserService extends BaseService {
  getSearchUser(nameUsername: any) {
    const url = USER.SEARCH_USER({ name_username: nameUsername });
    return this.client
      .get(url)
      .then(this.handleResponse)
      .catch(this.throwErrorMessage);
  }
  getUser(params: any) {
    return this.client
      .get(USER.USER, { params })
      .then(this.handleResponse)
      .catch(this.throwErrorMessage);
  }
  getUserPermission(params: any) {
    return this.client
      .post(USER.USER_PERMISSION, params)
      .then(this.handleResponse)
      .catch(this.throwErrorMessage);
  }
  getUserTokenize(params: any, apiToken: any) {
    if (apiToken) {
      this.client.defaults.headers["Authorization"] = "Token " + apiToken;
    }
    return this.client
      .post(USER.USER_TOKENIZE, params)
      .then(this.handleResponse)
      .catch(this.throwErrorMessage);
  }
  getUserTokenizeLicense(params: any) {
    return this.client
      .post(USER.USER_TOKENIZE_LICENSE, params)
      .then(this.handleResponse)
      .catch(this.throwErrorMessage);
  }
  getSessionInfo(params: any) {
    return this.client
      .get(USER.SESSION_INFO)
      .then(this.handleResponse)
      .catch(this.throwErrorMessage);
  }

  getUserProfile({ headers, params }: any = {}) {
    return this.client.get(USER.USER_PROFILE, { headers, params })
      .then(this.handleResponse)
      .catch(this.throwErrorMessage);
  }

  patchUserProfile({ headers, data }: any = {}) {
    return this.client.patch(USER.USER_PROFILE, data, { headers })
      .then(this.handleResponse)
      .catch(this.throwErrorMessage);
  }

  getUserPosition({ apiToken, params }: any = {}) {
    if (apiToken) {
      this.client.defaults.headers["Authorization"] = "Token " + apiToken;
    }
    return this.client
      .get(USER.USER_GETPOSITION, { params })
      .then(this.handleResponse)
      .catch(this.throwErrorMessage);
  }

  getUserWithPatient({ apiToken, params, userId }: any = {}) {
    if (apiToken) {
      this.client.defaults.headers["Authorization"] = "Token " + apiToken;
    }
    const url = USER.USER_WITH_PATIENT_ID({ user_id: userId })
    return this.client
      .get(url, { params })
      .then(this.handleResponse)
      .catch(this.throwErrorMessage);
  }



}
export default UserService;
