import React, { Component } from "react";
import "semantic-ui-css/semantic.css";
import "react-table-6/react-table.css";
import MainScreen from "./MainScreen";

import { withRouter, Switch, Route } from "react-router-dom";
import { Button, Menu, Dropdown } from "semantic-ui-react";

// Appcontroller
import * as NurseI from "./NurseInterface";
import NurseController from "./NurseController";

// Manager
import DPIManager from "../react-lib/apis/manager/DPIManager";
import MixInManager from "../react-lib/apis/manager/MixInManager";
import MSGManager from "../react-lib/apis/manager/MSGManager";
import PRXManager from "../react-lib/apis/manager/PRXManager";
import UserManager from "../react-lib/apis/manager/UserManager";
import PTMManager from "../react-lib/apis/manager/PTMManager";
import REGManager from "../react-lib/apis/manager/REGManager";
import CoreManager from "../react-lib/apis/manager/CoreManager";
import HRMManager from "../react-lib/apis/manager/HRMManager";
import LineLoginManager from "../react-lib/apps/IsHealth/Common/LineLoginManager";
import QUEManager from "../react-lib/apis/manager/QUEManager";

// Controller
import {
  CardClassifyController,
  CardNurseNoteController,
  CardPatientPanelController,
  CardProgressNoteController,
  CardDiagFormHistoryController,
} from "../react-lib/apps/IsHealth/Nurse";
import { ChatController } from "../react-lib/apps/IsHealth/Chat";
import AppointmentChatController from "../react-lib/apps/IsHealth/Nurse/AppointmentChatController";
import QueueController from "../react-lib/apps/QUE/QueueController";
import AppointmentDashboardController from "../react-lib/apps/IsHealth/APP/AppointmentDashboardController";
import * as serviceWorker from "../serviceWorker";

import * as IsHealthCommon from "../react-lib/apps/IsHealth/Common";
import NurseLogin from "./NurseLogin";
import { CookiesProvider, withCookies } from "react-cookie";
import EmailLogin from "../react-lib/apps/IsHealth/Common/EmailLogin/EmailLogin";
import EmailRegister from "../react-lib/apps/IsHealth/Common/EmailLogin/EmailRegister";
import EmailCallback from "../react-lib/apps/IsHealth/Common/EmailLogin/EmailCallback";
import ResolveChat from "../react-lib/apps/IsHealth/Common/ResolveChat";
import DashboardController from "../react-lib/apps/IsHealth/Dashboard/DashboardController";
import ChatTable from "../react-lib/apps/IsHealth/Chat/ChatTable";
import { vcMessenger } from "../react-lib/compat/vc-websocket";
import "../css/main.scss";
import "../react-lib/apps/QUE/Time.css";
import "../react-lib/apps/IsHealth/Common/FacebookLogin/LoginButton.css";
import config from "../config/config";
import CertLogin from "../react-lib/apps/IsHealth/Common/CertLogin/CertLogin";

const THEME = {
  DEFAULT: "",
  PENTA: "penta",
  PENGUIN: "penguin",
};

const URL_PREFIX = {
  PENGUIN: "penguin",
};
class MainNurse extends Component {
  constructor(props) {
    super(props);

    this.mainScreen = React.createRef();
    this.controller = new NurseController(
      () => {
        return this.state;
      },
      (state) => {
        this.setState(state);
      },
      window
    );

    const { cookies } = props;

    this.state = {
      division: cookies.get("division_id"),
      apiToken: cookies.get("apiToken"),
      userId: cookies.get("userId"),
      fullname: cookies.get("fullname"),
      username: cookies.get("username"),
      divisionName: "",
      finishedTriageLevel: [],
      landingLoading: false,
      providerInfo: {},

      myDivisions: [],
      currentDivision: null,
      // pathName that will redirect after login,
      //use when user open certain page but have no apiToken,
      // redirect to login and redirect back
      loginPathName: "",
      // for cnmi, no queue, so user should not be able to select division
      // hence, his will send division as url/?division=<ishealth_division_id>
      loginSearch: {},
      ...NurseI.StateInitial,
    };

    this.dpiManager = new DPIManager();
    this.mixInManager = new MixInManager();
    this.msgManager = new MSGManager();
    this.ptmManager = new PTMManager();
    this.regManager = new REGManager();
    this.prxManager = new PRXManager();
    this.coreManager = new CoreManager();
    this.userManager = new UserManager();
    this.queManager = new QUEManager();
    this.hrmManager = new HRMManager();
    this.lineLoginManager = new LineLoginManager();

    this.diagFormController = new CardDiagFormHistoryController({
      prxManager: this.prxManager,
    });

    this.classifyController = new CardClassifyController({
      coreManager: this.coreManager,
      prxManager: this.prxManager,
      userManager: this.userManager,
    });

    this.nurseNoteController = new CardNurseNoteController({
      prxManager: this.prxManager,
      ptmManager: this.ptmManager,
    });

    this.patientPanelController = new CardPatientPanelController({
      regManager: this.regManager,
      coreManager: this.coreManager,
      prxManager: this.prxManager,
    });

    this.progressNoteController = new CardProgressNoteController({
      dpiManager: this.dpiManager,
      prxManager: this.prxManager,
    });

    this.ChatController = new ChatController({
      msgManager: this.msgManager,
      prxManager: this.prxManager,
      regManager: this.regManager,
      coreManager: this.coreManager,
    });

    this.AppointmentChatController = new AppointmentChatController({
      msgManager: this.msgManager,
      prxManager: this.prxManager,
      regManager: this.regManager,
      coreManager: this.coreManager,
    });

    this.loginController = new IsHealthCommon.LoginController({
      mixInManager: this.mixInManager,
    });

    this.queueController = new QueueController({
      queManager: this.queManager,
      prxManager: this.prxManager,
      hrmManager: this.hrmManager,
    });
    this.dashboardController = new DashboardController({
      prxManager: this.prxManager,
      coreManager: this.coreManager,
    });

    this.appointmentDashboardController = new AppointmentDashboardController({
      coreManager: this.coreManager,
      prxManager: this.prxManager,
    });

    this.lastReadMessage = null;

    vcMessenger.onMessage((message) => {
      console.log("========onMessage======");
      console.log(message, this.mainScreen.qaChatTable);
      if (message["data_message_type"] === "MESSAGE") {
        if (this.mainScreen.chatTable) {
          if (this.mainScreen.chatTable.chatListRef) {
            this.mainScreen.chatTable.chatListRef.getChatList();
          }
          if (this.mainScreen.chatTable.chatBoxRef) {
            this.mainScreen.chatTable.chatBoxRef.receivedMessage({
              chatChannelId: parseInt(message["channel_id"]),
            });
          }
        }
        if (config.APPOINTMENT_CHAT_FUNCTION) {
          if (this.mainScreen.appointmentTable) {
            if (this.mainScreen.appointmentTable.chatListRef) {
              this.mainScreen.appointmentTable.chatListRef.getChatList();
            }
            if (this.mainScreen.appointmentTable.chatBoxRef) {
              this.mainScreen.appointmentTable.chatBoxRef.receivedMessage({
                chatChannelId: parseInt(message["channel_id"]),
              });
            }
          }
        }
        if (config.CLASSIFY_CHAT || config.AIRFLOW_CHAT) {
          if (this.mainScreen.qaChatTable) {
            if (this.mainScreen.qaChatTable.chatBoxRef) {
              this.mainScreen.qaChatTable.chatBoxRef.getLastMessage({
                chatChannelId: parseInt(message["channel_id"]),
              });
            }
            if (this.mainScreen.qaChatTable.chatBoxRef) {
              this.mainScreen.qaChatTable.chatBoxRef.receivedMessage({
                chatChannelId: parseInt(message["channel_id"]),
              });
            }
            if (this.mainScreen.qaChatTable) {
              this.mainScreen.qaChatTable.receivedMessage({
                chatChannelId: parseInt(message["channel_id"]),
              });
            }
          }
        }
      } else if (message["data_message_type"] === "MESSAGE_READ") {
        if (this.lastReadMessage === message.message_id) {
          return;
        }
        this.lastReadMessage = message.message_id;
        if (this.mainScreen.chatTable) {
          if (this.mainScreen.chatTable.chatBoxRef) {
            this.mainScreen.chatTable.chatBoxRef.receivedMessageRead({
              chatChannelId: parseInt(message["channel_id"]),
              messageId: parseInt(message["message_id"]),
            });
          }
        }
        if (this.mainScreen.qaChatTable) {
          if (this.mainScreen.qaChatTable.chatBoxRef) {
            this.mainScreen.qaChatTable.chatBoxRef.getLastMessage({
              chatChannelId: parseInt(message["channel_id"]),
            });
          }
          if (this.mainScreen.qaChatTable) {
            this.mainScreen.qaChatTable.receivedMessage({
              chatChannelId: parseInt(message["channel_id"]),
            });
          }
        }
        if (config.APPOINTMENT_CHAT_FUNCTION) {
          if (this.mainScreen.appointmentTable) {
            if (this.mainScreen.appointmentTable.chatBoxRef) {
              this.mainScreen.appointmentTable.chatBoxRef.receivedMessageRead({
                chatChannelId: parseInt(message["channel_id"]),
                messageId: parseInt(message["message_id"]),
              });
            }
          }
        }
      } else if (message["event"] === "RECLASSIFY") {
        if (this.mainScreen.qaChatTable) {
          this.mainScreen.qaChatTable.getListTriageLevel();
        }
        if (this.mainScreen.dashboard) {
          this.mainScreen.dashboard.refresh();
        } else {
          if (this.mainScreen.chatTable.chatListRef) {
            this.mainScreen.chatTable.chatListRef.getChatList({
              type: "RECLASSIFY",
            });
          }
          this.mainScreen.chatTable.getPatientInfo();
          // this.props.history.push("/Chat/"); cause of video call close unexpected
        }
        // let filterValue = this.mainScreen.chatTable.chatListRef.getFilterValue();
        // this.mainScreen.chatTable.chatBoxRef.receivedReclassify({
        //   filterValue: filterValue,
        //   encounterId: parseInt(message["encounter"]),
        //   chatChannelId: parseInt(message["chat_channel"])
        // });
      } else if (message["event"] === "DIAG_FORM_UPDATED") {
        if (this.mainScreen.qaChatTable) {
          this.mainScreen.qaChatTable.updateDiagForm({
            patientId: parseInt(message["patient_id"]),
          });
        }
      }
    });
  }

  componentDidUpdate() {
    if (
      !this.props.apiToken &&
      !this.props.cookies.get("apiToken") &&
      this.props.location.pathname !== "/" &&
      this.props.location.pathname !== "/Login" &&
      this.props.location.pathname.includes("/resolve-chat") &&
      !this.props.location.pathname.includes("EmailLogin") &&
      !this.props.location.pathname.includes("EmailRegister") &&
      !this.props.location.pathname.includes("email-callback")
    ) {
      let params = new URLSearchParams(this.props.location.search);
      if (this.state.loginPathName !== this.props.location.pathname) {
        this.setState({
          loginPathName: this.props.location.pathname,
          loginSearch: params,
        });
      }
      if (!this.props.location.pathname.includes("/resolve-chat")) {
        this.props.history.push("/");
      }
    }
  }

  componentWillUpdate(nextProps, nextState) {
    // when division change, clear selected encounter(chat and patient Panel)
    // however, for cnmi, there is no queue, the chat and patientPanel is base on url only
    // when user get in from certain url, and no apiToken, redirect them to landing page
    // and keep the prev path in state.loginPathName, and redirect to that path after login succ
    // this func prevent redirect because division will change when user login
    // so I add !config.HIDE_QUEUE
    if (!config.HIDE_QUEUE && this.state.division != nextState.division) {
      this.props.history.push("/Chat/");
    }
  }

  componentDidMount() {
    this.controller.handleEvent({ message: "DidMount", params: {} });
    let currentURL = window.location.href;
    if (currentURL.includes(URL_PREFIX.PENGUIN)) {
      // Theme for penguin
      this.setState({ theme: THEME.PENGUIN });
      document.documentElement.setAttribute("data-theme", THEME.PENGUIN);
      this.props.cookies.set("theme", THEME.PENGUIN, { path: "/" });
    } else {
      this.setState({ theme: THEME.PENTA });
      document.documentElement.setAttribute("data-theme", THEME.PENTA);
      this.props.cookies.set("theme", THEME.PENTA, { path: "/" });
    }
    let userId = this.props.cookies.get("userId")
      ? this.props.cookies.get("userId")
      : this.state.userId;
    let apiToken = this.props.cookies.get("apiToken")
      ? this.props.cookies.get("apiToken")
      : this.state.apiToken;
    if (apiToken) {
      this.setWebsocket({ apiToken });
    }
    if (userId) {
      this.getProviderInfo({ userId });
      this.controller.handleEvent({
        message: "LoginFirebase",
        params: { userId: userId },
      });
    }
    this.handleGetFinishedTriageLevel();
  }

  handleGetFinishedTriageLevel = async () => {
    let data = { config_PRX_FINISHED_TRIAGE_LEVEL: null };
    const [res, error, network] =
      await this.classifyController.getFinishedTriageLevel({ data });
    this.setState({
      finishedTriageLevel:
        res.config_PRX_FINISHED_TRIAGE_LEVEL &&
        res.config_PRX_FINISHED_TRIAGE_LEVEL.split(" "),
    });
  };

  setWebsocket = ({ apiToken } = {}) => {
    vcMessenger.connect(
      "MSG",
      {
        screens: this.props.cookies.get("division_id"),
        token: apiToken,
      },
      config.WS_HOST
    );
  };

  loadMydivision = async () => {
    let apiToken = this.props.cookies.get("apiToken")
      ? this.props.cookies.get("apiToken")
      : this.state.apiToken;
    if (!apiToken) {
      return;
    }
    const [response, error, network] = await this.prxManager.getDivisionHasUser(
      { apiToken: apiToken }
    );
    if (response && response.items.length > 0) {
      console.log("my division", response.items);
      var myDivisions = [];
      for (let d of response.items) {
        var dupicate = false;
        for (let m of myDivisions) {
          if (m.id === d.division.id) {
            dupicate = true;
            break;
          }
        }
        if (!dupicate) {
          myDivisions.push(d.division);
        }
      }
      // Prepare data to dropdown element
      var options = [];
      for (var division of myDivisions) {
        options.push({
          ...division,
          text: division.name,
          value: division.code,
        });
      }
      this.setState({ myDivisions: options });

      // Initial division
      var targetDivision = null;
      // from url params
      if (this.props.location.search) {
        let urlParams = new URLSearchParams(this.props.location.search);
        let divisionParams = urlParams.get("division");
        const selected = options.find(
          (division) => division.id.toString() === divisionParams
        );
        if (selected) {
          targetDivision = selected;
        }
      }
      // from cookies
      let selectedDivision = this.props.cookies.get("division_id");
      if (selectedDivision) {
        const selected = options.find(
          (division) => division.id.toString() === selectedDivision
        );
        if (selected) {
          targetDivision = selected;
        }
      }
      // No dafault division
      if (!targetDivision && options.length > 0) {
        targetDivision = options[0];
        this.props.cookies.set("division_id", targetDivision.id, { path: "/" });
      }

      if (targetDivision) {
        this.setState({
          currentDivision: targetDivision,
          division: targetDivision.id,
        });
        vcMessenger.reconnect({
          screens: targetDivision.id,
          token: this.props.cookies.get("apiToken")
            ? this.props.cookies.get("apiToken")
            : this.state.apiToken,
        });
      } else {
        return console.log("There aren't division");
        // alert("ผู้ใช้งานนี้ไม่มี โรงพยาบาล (Division) ของระบบ กรุณาติดต่อ ผู้ดูแล");
      }
    }
  };

  reconnectWs = () => {
    vcMessenger.reconnect({
      screens: this.state.division,
      token: this.props.cookies.get("apiToken")
        ? this.props.cookies.get("apiToken")
        : this.state.apiToken,
    });
  };

  handleChangeDivision = (event, data) => {
    let sValue = data.value;
    const selected = this.state.myDivisions.find(
      (division) => division.value === sValue
    );
    if (selected) {
      this.setState({
        currentDivision: selected,
        division: selected.id,
      });
      vcMessenger.reconnect({
        screens: selected.id,
        token: this.props.cookies.get("apiToken")
          ? this.props.cookies.get("apiToken")
          : this.state.apiToken,
      });
      this.props.cookies.set("division_id", selected.id, { path: "/" });
    }
  };

  handleLoginSuccess = async (response) => {
    console.log(" LoginFirebase ", response)
    this.controller.handleEvent({
      message: "LoginFirebase",
      params: { userId: response?.profile?.userId },
    });
    this.props.cookies.set("fullname", response?.profile?.fullname, {
      path: "/",
    });
    this.props.cookies.set("username", response?.profile?.username, {
      path: "/",
    });
    this.props.cookies.set("apiToken", response.token, { path: "/" });
    this.props.cookies.set("userId", response?.profile?.userId, { path: "/" });
    this.getProviderInfo({ userId: response?.profile?.userId });
    this.setState({
      userId: response?.profile?.userId,
      apiToken: response.token,
    });
    this.setWebsocket({ apiToken: response.token });
    this.setState({ landingLoading: false });
    console.log(this.state.loginPathName, 11111);
    if (this.state.loginPathName) {
      this.props.history.push({
        pathname: this.state.loginPathName,
        search: `?${this.state.loginSearch.toString()}`,
      });
      return this.setState({ loginPathName: "", loginSearch: {} });
    }
    this.props.history.push({
      pathname: "/Chat",
    });
  };

  handleLogout = async () => {
    serviceWorker.unregister();
    if (window.FB) {
      window.FB.logout(function (response) {
        console.log(" user Logout Facebook !");
      });
    }
    console.log("unregister service worker");
    // Remove data
    this.props.cookies.remove("fullname", { path: "/" });
    this.props.cookies.remove("username", { path: "/" });
    this.props.cookies.remove("apiToken", { path: "/" });
    this.props.cookies.remove("division_id", { path: "/" });
    this.props.cookies.remove("userId", { path: "/" });
    let params = new URLSearchParams(this.props.location.search);
    this.setState({
      apiToken: this.props.cookies.apiToken,
      userId: this.props.cookies.userId,
      loginPathName: this.props.location.pathname,
      loginSearch: params,
    });
    this.props.history.push({ pathname: "/", state: {} });
  };

  getRightMenuItem = () => {
    if (
      this.state.apiToken ||
      this.props.cookies.get("apiToken") ||
      (this.props.location &&
        this.props.location.state &&
        this.props.location.state.userId)
    ) {
      return (
        <Menu.Item position="right">
          <h3>
            {this.state.providerInfo.employee_info
              ? this.state.providerInfo.employee_info.full_name
              : ""}
          </h3>
          <Button onClick={this.handleLogout}>Logout</Button>
        </Menu.Item>
      );
    } else if (this.props.location.pathname === "/") {
      return (
        <Menu.Item position="right">
          <Button
            onClick={(e) => {
              this.props.history.push("/Login");
            }}
          >
            Login/Register
          </Button>
        </Menu.Item>
      );
    } else {
      return null;
    }
  };

  getLeftMenuItem = () => {
    if (this.props.location.pathname === "/") {
      return null;
    } else {
      return (
        <Menu.Item>
          <h2 onClick={this.handleHomeIconClick}>{`IsHealth Nurse`}</h2>
        </Menu.Item>
      );
    }
  };

  getDivisionMenuItem = () => {
    if (
      config.HIDE_DIVISION ||
      this.props.history.location.pathname.includes("Dashboard")
    ) {
      return <></>;
    }
    if (
      this.state.apiToken ||
      this.props.cookies.get("apiToken") ||
      (this.props.location &&
        this.props.location.state &&
        this.props.location.state.userId)
    ) {
      return (
        <Menu.Item>
          <Dropdown
            style={{ color: "white", backgroundColor: "transparent" }}
            button
            className={"icon"}
            floating
            labeled
            icon={"hospital"}
            options={this.state.myDivisions}
            search
            value={
              this.state.currentDivision ? this.state.currentDivision.value : ""
            }
            placeholder={"Select Division"}
            onChange={this.handleChangeDivision}
          />
        </Menu.Item>
      );
    } else {
      return null;
    }
  };

  handleCredentialLoginSuccess = async (response) => {
    this.props.cookies.set("fullname", response?.profile?.fullname, {
      path: "/",
    });
    this.props.cookies.set("username", response?.profile?.username, {
      path: "/",
    });
    this.props.cookies.set("apiToken", response.token, { path: "/" });
    this.props.cookies.set("userId", response?.profile?.userId, { path: "/" });
    this.getProviderInfo({ userId: response?.profile?.userId });
    this.setState({
      userId: response?.profile?.userId,
      apiToken: response.token,
    });
    this.setWebsocket({ apiToken: response.token });
    this.setState({ landingLoading: false });
    console.log(this.state.loginPathName, 11111);
    if (this.state.loginPathName) {
      this.props.history.push({
        pathname: this.state.loginPathName,
        search: `?${this.state.loginSearch.toString()}`,
      });
      return this.setState({ loginPathName: "", loginSearch: {} });
    }
  };

  handleLineLoginSuccess = (props) => {
    if (props.pentaToken) {
      this.setState({ landingLoading: true });
      setTimeout(() => {
        this.handleLoginSuccess(props.pentaToken);
      }, 0);
    } else if (props.token) {
      this.setState({ landingLoading: true });
      setTimeout(() => {
        this.handleLoginSuccess(props);
      }, 0);
    } else {
      this.setState({ landingLoading: false });
      this.props.history.replace({ pathname: "/Login" });
    }
  };

  handleLineLoginFailed = (props) => {
    this.setState({ landingLoading: false });
    console.log("handleLineLoginFailed: ", props);
    this.props.history.replace({ pathname: "/Login" });
  };

  getProviderInfo = async ({ userId } = {}) => {
    const [response, error] = await this.queueController.getProviderInfo({
      apiToken: this.props.cookies.get("apiToken")
        ? this.props.cookies.get("apiToken")
        : this.state.apiToken,
      userId,
    });
    if (response) {
      this.setState({ providerInfo: response });
      this.props.cookies.set("providerId", response.id, { path: "/" });
    } else {
      this.setState({ providerInfo: {} });
    }
  };

  render() {
    console.log(this.props.location, "location");
    return (
      <CookiesProvider>
        <div style={{ overflowY: "hidden" }} className="main-nurse">
          <Switch>
            <Route
              exact
              path="/EmailLogin/"
              render={(props) => {
                return <EmailLogin {...props} />;
              }}
            />
            <Route
              exact
              path="/EmailRegister/"
              render={(props) => {
                return <EmailRegister {...props} />;
              }}
            />
            <Route
              exact
              path="/email-callback/"
              render={(props) => {
                return (
                  <EmailCallback
                    onLoginSuccess={this.handleLoginSuccess}
                    onLoginFailed={this.handleLineLoginFailed}
                  />
                );
              }}
            ></Route>
            <Route
              exact
              path="/resolve-chat/"
              render={(props) => {
                return (
                  <ResolveChat
                    {...props}
                    apiToken={this.state.apiToken}
                    controller={this.ChatController}
                    onLoginSuccess={this.handleCredentialLoginSuccess}
                  />
                );
              }}
            />
            <Route exact path="/callback/">
              <IsHealthCommon.LineCallBack
                onLoginSuccess={this.handleLineLoginSuccess}
                onLoginFailed={this.handleLineLoginFailed}
              />
            </Route>
            <Route exact path="/Login">
              <NurseLogin
                controller={this.loginController}
                lineLoginManager={this.lineLoginManager}
                onLoginSuccess={this.handleLoginSuccess}
                onHaveApiToken={() => {
                  this.props.history.push("/Chat");
                }}
              />
            </Route>
            <Route exact path="/cert-login">
              <CertLogin onLoginSuccess={this.handleLoginSuccess} />
            </Route>
            <Route
              path="/"
              render={(props) => {
                if (this.state.apiToken) {
                  return (
                    <MainScreen
                      {...props}
                      ref={(ref) => (this.mainScreen = ref)}
                      getLeftMenuItem={this.getLeftMenuItem}
                      getDivisionMenuItem={this.getDivisionMenuItem}
                      getRightMenuItem={this.getRightMenuItem}
                      chatController={this.ChatController}
                      appointmentChatController={this.AppointmentChatController}
                      diagFormController={this.diagFormController}
                      patientPanelController={this.patientPanelController}
                      nurseNoteController={this.nurseNoteController}
                      progressNoteController={this.progressNoteController}
                      appointmentDashboardController={
                        this.appointmentDashboardController
                      }
                      classifyController={this.classifyController}
                      apiToken={this.state.apiToken}
                      onGetDivision={this.loadMydivision}
                      division={this.state.division}
                      userId={this.state.userId}
                      fullname={this.state.fullname}
                      username={this.state.username}
                      finishedTriageLevel={this.state.finishedTriageLevel}
                      queueController={this.queueController}
                      dashboardController={this.dashboardController}
                      onReconnectWs={this.reconnectWs}
                      onEvent={this.controller.handleEvent}
                      patientSegmentList={this.state.patientSegmentList}
                      diagFormDetail={this.state.diagFormDetail}
                      divisionProfileList={this.state.divisionProfileList}
                      isLoadingFormAnalytics={this.state.isLoadingFormAnalytics}
                      myDivisions={this.state.myDivisions}
                      storage={this.controller.storage}
                    />
                  );
                } else {
                  return (
                    <IsHealthCommon.LandingPage
                      detail={
                        <>
                          <span className="header darkblue">Is</span>
                          <span className="header">Health Nurse</span>
                          <div>
                            แพลตฟอร์มสำหรับแพทย์ ที่ใช้ในการสื่อสาร
                            ประเมินและให้คำปรึกษาผู้ป่วยผ่านระบบออนไลน์
                          </div>
                          <br />
                          <div>
                            สามารถตรวจสอบข้อมูลคิวผู้ป่วยที่จำเป็นจะต้องปรึกษาแพทย์
                            และตรวจสอบผลการบันทึกแบบคัดกรองตนเองของผู้ป่วย
                            ข้อมูลโน้ตการพยาบาล
                            และบันทึกโน้ตสำหรับแพทย์เพิ่มเติม ผ่านระบบออนไลน์
                          </div>
                          <br />
                          <div>
                            <Button
                              content="คลิกเพื่อเริ่มต้นใช้งานระบบ"
                              size="huge"
                              className="whiteBasicButton"
                              onClick={() =>
                                //   (window.location.pathname = "/Login")
                                this.props.history.push("/Login")
                              }
                            />
                          </div>
                        </>
                      }
                    />
                  );
                }
              }}
            />
          </Switch>
        </div>
      </CookiesProvider>
    );
  }
}

export default withCookies(withRouter(MainNurse));
