import { ChatControllerConstructorProps, ChatControllerInterface } from "./ChatControllerInterface";
import MSGManager from "../../../apis/manager/MSGManager";
import PRXManager from "../../../apis/manager/PRXManager";
import REGManager from "../../../apis/manager/REGManager";
import CoreManager from "../../../apis/manager/CoreManager";

export default class ChatController implements ChatControllerInterface {
  msgManager: MSGManager;
  prxManager: PRXManager;
  regManager: REGManager;
  coreManager: CoreManager;

  constructor(props: ChatControllerConstructorProps) {
    this.msgManager = props.msgManager;
    this.prxManager = props.prxManager;
    this.regManager = props.regManager;
    this.coreManager = props.coreManager;
  }

  postEncounterPatientAppointment = async ({
    encounterId,
    apiToken
  } = {}) => {
    const [response, error, network] = await this.prxManager.postEncounterPatientAppointment({
      encounterId,
      apiToken
    });
    return [response, error, network];
  }

  getPatient = async ({
    apiToken
  }) => {
    const [data, error] = await this.prxManager.getPatient({
      apiToken
    });
    return [data, error]
  }

  getChatList = async ({
    apiToken,
    filter,
    patientId,
    divisionIds,
    hasPatientApp,
    orderBy,
    date
  } = {}) => {
    const [response, error, network] = await this.prxManager.getEncounterTriage({
      apiToken,
      filter,
      patientId,
      divisionIds,
      hasPatientApp,
      orderBy,
      date
    });
    return [response, error, network];
  };

  getEncounterList = async ({
    apiToken,
    filter,
    patientId,
    divisionIds
  } = {}) => {
    const [response, error, network] = await this.prxManager.getEncounterTriage({
      apiToken,
      filter,
      patientId,
      divisionIds
    });
    return [response, error, network];
  };

  getEncounterTriageDetail = async ({
    apiToken,
    encounterId
  } = {}) => {
    const [response, error, network] = await this.prxManager.getEncounterTriageDetail({
      apiToken,
      encounterId,
    });
    return [response, error, network];
  }

  getLatestChatChannel = async ({
    apiToken,
    userId
  } = {}) => {
    const [
      response,
      error,
      network
    ] = await this.msgManager.getLatestChatChannel({
      apiToken,
      userId
    });
    return [response, error, network];
  };

  getChatChannelMessageList = async ({
    apiToken,
    last,
    chatChannelId,
    cancelToken
  } = {}) => {
    const [
      response,
      error,
      network
    ] = await this.prxManager.getChatChannelMessageList({
      apiToken,
      last,
      chatChannelId,
      cancelToken,
    });
    return [response, error, network];
  };

  getMessageFromURL = async ({
    url,
    apiToken
  } = {}) => {
    const [response, error, network] = await this.msgManager.getMessageFromURL({
      apiToken,
      url
    });
    return [response, error, network];
  };

  postChatChannelMessage = async ({
    apiToken,
    content,
    contentType,
    chatChannelId,
    contentFile,
    divisionId
  } = {}) => {
    const [
      response,
      error,
      network
    ] = await this.prxManager.postChatChannelMessage({
      apiToken,
      content,
      contentType,
      chatChannelId,
      contentFile,
      divisionId
    });
    return [response, error, network];
  };

  getChannelDetail = async ({
    apiToken,
    chatChannelId
  } = {}) => {
    const [response, error, network] = await this.prxManager.getChannelDetail({
      apiToken,
      chatChannelId
    });
    return [response, error, network];
  };

  getEncounterDetail = async ({
    encounterId,
    apiToken
  } = {}) => {
    const [response, error, network] = await this.coreManager.getEncounter(
      encounterId,
      apiToken
    );
    return [response, error, network];
  };

  getPatientInfo = async ({
    patientId,
    apiToken
  } = {}) => {
    const [response, error, network] = await this.regManager.getPatientInfo(
      patientId,
      apiToken
    );
    return [response, error, network];
  };

  getProxyPatient = async ({
    patientId,
    apiToken
  } = {}) => {
    const [response, error, network] = await this.prxManager.getProxyPatient({
      patientId,
      apiToken
    });
    return [response, error, network];
  };

  getTriageLevelClassify = async ({
    apiToken,
    divisionId
  } = {}) => {
    const [
      response,
      error,
      network
    ] = await this.prxManager.getTriageLevelClassify({
      apiToken,
      divisionId
    });
    return [response, error, network];
  };

  getClassifyUser = async ({
    apiToken,
    divisionId
  } = {}) => {
    const [
      response,
      error,
      network
    ] = await this.prxManager.getClassifyUser({
      apiToken,
      divisionId
    });
    return [response, error, network];
  };

  postReadMessage = async ({
    apiToken,
    chatChannelId
  } = {}) => {
    const [
      response,
      error,
      network
    ] = await this.msgManager.postReadMessage({
      apiToken,
      chatChannelId
    });
    return [response, error, network];
  }

  postUpdateSubscription = async ({
    apiToken,
    device_id,
    subscription
  } = {}) => {
    console.log("in controller: ", device_id, subscription);
    const [
      response,
      error,
      network
    ] = await this.msgManager.postUpdateSubscription({
      apiToken,
      device_id,
      subscription
    });
    return [response, error, network];
  }

  getDivisionProfile = async ({
    apiToken,
    divisionCode
  } = {}) => {
    const [
      response,
      error,
      network
    ] = await this.prxManager.getDivisionProfile({
      apiToken,
      divisionCode
    });
    return [response, error, network];
  }

  getResolveChatChannel = async ({
    apiToken,
    divisionId,
    hn
  } = {}) => {
    const [
      response,
      error,
      network
    ] = await this.prxManager.getResolveChatChannel({
      apiToken,
      divisionId,
      hn
    });
    return [response, error, network];
  }
}