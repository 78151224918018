export default { 
  apiKey: "AIzaSyD3-V5j5bAty2eEL-QkKNXm4WyhqpEmu7w",
  authDomain: "penguin-dev-e0943.firebaseapp.com",
  databaseURL: "https://penguin-dev-e0943.firebaseio.com",
  projectId: "penguin-dev-e0943",
  storageBucket: "penguin-dev-e0943.appspot.com",
  messagingSenderId: "614639654931",
  appId: "1:614639654931:web:e478ca06d3cf634ea287e6",
  measurementId: "G-B46QQ4H7YL"
};
