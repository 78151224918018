import React, {useEffect, useState} from "react";
import {useLocation} from "react-router";
import PasswordLoginManager from "../../../../apps/IsHealth/Common/PasswordLogin/PasswordLoginManager";
import settings from "../../../../../config/config";


type CertLoginProp = {
  onLoginSuccess: (response: {
    profile?: {
      fullname?: string;
      username?: string;
      userId?: string | number;
    };
    token?: string;
  }) => void,
}


const CertLogin = (props: CertLoginProp) => {
  // const history = useHistory();
  const params = new URLSearchParams(useLocation().search);
  const hisDivision = params.get("division") || "";
  const hisHN = params.get("hn") || "";
  const authVer = params.get("auth_ver") || "1";
  const app = params.get("app") || settings.APP_NAME;
  const email = params.get("email") || "";
  const firstName = params.get("first_name") || "";
  const lastName = params.get("last_name") || "";
  const time = params.get("time") || "";
  const sign = params.get("sign") || "";
  const role = params.get("role") || "";
  const division = params.get("division") || "";

  // const [divisionId, setDivisionId] = useState(null);
  const [errorMsg, setErrorMsg] = useState<any>(null);
  // const [cookies, setCookie, removeCookie] = useCookies([]);
  const [loading, setLoading] = useState<boolean>(true);
  // const isMounted = useRef(true);
  const manager = new PasswordLoginManager();

  const getCredential = async () => {
    setErrorMsg(null);
    const data = await manager.requestTokenByCertLogin({
      email,
      app,
      firstName,
      lastName,
      time,
      sign,
      role,
      division
    });
    if(data){
      props.onLoginSuccess(data);
    } else {
      setErrorMsg("Access denied");
    }
  };

  useEffect(() => {
    if (
      authVer && email && firstName !== undefined && lastName !== undefined && time &&
      sign && role !== undefined && division !== undefined)
    {
      getCredential().then(
        () => {setLoading(false)}
      ).catch(
        () => {setLoading(false)}
      );
    } else {
      setErrorMsg("Access denied");
      setLoading(false);
    }
  }, [authVer, email, firstName, lastName, time, sign, role, division]);

  let text = "Redirecting ...";
  if(errorMsg){
    text = errorMsg;
  }
  else if(!loading && (!hisDivision || !hisHN)){
    text = "ไม่สามารถระบุหน่วยงาน หรือผู้ป่วยได้";
  }

  return <h1 className='resolve-chat'>{text}</h1>;
};

export default CertLogin;
