import React, { useState } from "react";
import {
  serial_to_datestr,
  get_week_slots_display,
  get_startofweek_serial,
  DivisionServiceBlock,
  serial_to_hour,
  QueueController
} from "./Time";
import { useCookies } from "react-cookie";
import { useHistory, RouteComponentProps } from "react-router-dom";
import ServiceSlotSelect from "./ServiceSlotSelect";
import { HourRangeDropdown } from "../common";
import { Dimmer, Loader } from "semantic-ui-react";

interface RouterProps {
  id: string;
  weekStartSerial: string;
}

interface TimeDoctorProps extends RouteComponentProps<RouterProps> {
  apiToken: string;
  controller: QueueController;
  providerId?: number;
}

const TimeDoctorEdit = (props: TimeDoctorProps) => {
  const [dayIndex, setDayIndex] = useState(0);
  const [weekStartSerial, setWeekStartSerial] = useState(
    get_startofweek_serial()
  );
  const [serviceSlots, setServiceSlots] = React.useState([]);
  const [divisionServiceBlocks, setDivisionServiceBlocks] = React.useState<
    DivisionServiceBlock[]
  >([]);
  const [doctorDivisions, setDoctorDivisions] = React.useState<any[]>([]);
  const [minHour, setMinHour] = React.useState(0);
  const [maxHour, setMaxHour] = React.useState(24);
  const [isLoading, setIsLoading] = React.useState(false);
  const [cookies] = useCookies([]);
  const isMounted = React.useRef(true);
  const history = useHistory();

  const min_hour = Math.min(
    ...divisionServiceBlocks.map((dsb, index) =>
      serial_to_hour(dsb.start_serial)
    )
  );
  const max_hour = Math.max(
    ...divisionServiceBlocks.map((dsb, index) => serial_to_hour(dsb.end_serial))
  );

  const week_slots_display = get_week_slots_display(
    doctorDivisions,
    serviceSlots,
    weekStartSerial,
    divisionServiceBlocks
  );

  React.useEffect(() => {
    setMinHour(min_hour);
  }, [min_hour]);

  React.useEffect(() => {
    if (max_hour > 0) {
      setMaxHour(max_hour);
    }
  }, [max_hour]);

  React.useEffect(() => {
    handleGetDivisionList();
    handleLoadServiceSlot();

    return () => {
      isMounted.current = false;
    };
  }, []);

  React.useEffect(() => {
    if (!isNaN(parseInt(props.match.params.id))) {
      setDayIndex(parseInt(props.match.params.id));
    } else {
      history.push("/Schedule/");
    }
  }, [props.match.params.id]);

  React.useEffect(() => {
    if (!isNaN(parseInt(props.match.params.weekStartSerial))) {
      setWeekStartSerial(parseInt(props.match.params.weekStartSerial));
    } else {
      history.push("/Schedule/");
    }
  }, [props.match.params.weekStartSerial]);

  const handleGetDivisionList = async () => {
    setIsLoading(true);
    const [
      response,
      error,
      network
    ] = await props.controller.getDivisionHasUser({
      apiToken: props.apiToken ? props.apiToken : cookies.apiToken
    });
    if (isMounted.current) {
      setIsLoading(false);
      if (response) {
        let item = response.items.map(
          (d: { division: { id: number | string } }) => d.division.id
        );
        setDoctorDivisions(item);
      } else {
        setDoctorDivisions([]);
      }
    }
  };

  const handleCreateServiceSlot = async ({
    serial,
    status,
    provider,
    division
  }: { serial?: any; status?: any; provider?: any; division?: any } = {}) => {
    setIsLoading(true);
    const [response, error, network] = await props.controller.createServiceSlot(
      {
        apiToken: props.apiToken ? props.apiToken : cookies.apiToken,
        serial,
        status,
        provider,
        division
      }
    );
    if (isMounted.current) {
      setIsLoading(false);
      handleLoadServiceSlot();
    }
  };

  const handleUpdateServiceSlot = async ({
    pk,
    serial,
    status,
    provider,
    division
  }: {
    pk?: any;
    serial?: any;
    status?: any;
    provider?: any;
    division?: any;
  } = {}) => {
    setIsLoading(true);
    const [response, error, network] = await props.controller.updateServiceSlot(
      {
        apiToken: props.apiToken ? props.apiToken : cookies.apiToken,
        pk,
        serial,
        status,
        provider,
        division
      }
    );
    if (isMounted.current) {
      setIsLoading(false);
      handleLoadServiceSlot();
    }
  };

  const handleLoadServiceSlot = async () => {
    setIsLoading(true);
    const [response, error, network] = await props.controller.loadServiceSlot({
      apiToken: props.apiToken ? props.apiToken : cookies.apiToken,
      providerId: props.providerId ? props.providerId : cookies.providerId,
      fromSerial: weekStartSerial,
      toSerial: weekStartSerial + 96 * 7
    });
    if (isMounted.current) {
      setIsLoading(false);
      if (response) {
        setServiceSlots(response.items);
      } else {
        setServiceSlots([]);
      }
    }
  };

  React.useEffect(() => {
    if (doctorDivisions.length > 0) {
      handleLoadDoctorDivisionServiceBlock();
    }
  }, [doctorDivisions, weekStartSerial]);

  React.useEffect(() => {
    handleLoadServiceSlot();
  }, [weekStartSerial]);

  const handleLoadDoctorDivisionServiceBlock = async () => {
    setIsLoading(true);
    const [
      response,
      error,
      network
    ] = await props.controller.loadDivisionServiceBlock({
      apiToken: props.apiToken ? props.apiToken : cookies.apiToken,
      from_serial: weekStartSerial,
      to_serial: weekStartSerial + 96 * 7,
      divisions: doctorDivisions.toString()
    });
    if (isMounted.current) {
      setIsLoading(false);
      if (response) {
        setDivisionServiceBlocks(response.items);
      } else {
        setDivisionServiceBlocks([]);
      }
    }
  };

  return (
    <div className="TimeDoctorEdit">
      <Dimmer.Dimmable
        dimmed={isLoading}
        style={{ height: "100%" }}
        className="MainLayout"
      >
        <Dimmer active={isLoading} inverted>
          <Loader inverted>Loading</Loader>
        </Dimmer>
        <div className="dateHeader">
          <h3>
            {serial_to_datestr(weekStartSerial + dayIndex * 96, "localeDate", {
              weekday: "long",
              year: "numeric",
              month: "long",
              day: "numeric"
            })}
          </h3>
        </div>

        <div className="content">
          <h2>
            เพิ่ม/แก้ไขตารางออกตรวจ
            <span className="header5">
              &ensp; ช่วงเวลา&ensp;
              <HourRangeDropdown
                onChange={(e: any) => setMinHour(e.currentTarget.value)}
                defaultValue={minHour}
              />
              &ensp; ถึง &ensp;{" "}
              <HourRangeDropdown
                onChange={(e: any) => setMaxHour(e.currentTarget.value)}
                defaultValue={maxHour}
              />
            </span>
          </h2>
          <div className="calendarContent">
            <ServiceSlotSelect
              day_slots_display={week_slots_display[dayIndex]}
              divisions={doctorDivisions}
              createServiceSlot={handleCreateServiceSlot}
              updateServiceSlot={handleUpdateServiceSlot}
              provider={
                props.providerId ? props.providerId : cookies.providerId
              }
              min_hour={minHour}
              max_hour={maxHour}
            />
          </div>
        </div>
      </Dimmer.Dimmable>
    </div>
  );
};

export default React.memo(TimeDoctorEdit);
