import moment from "moment";

//! Dont use this file, go use utils/index.js
export function formatDate(date: any) {
  if (moment.isMoment(date)) {
    date = date.toDate();
  }
  if (date instanceof Date && !isNaN(date.valueOf())) {
    const day = `${date.getDate()}`.padStart(2, "0");
    const month = `${date.getMonth() + 1}`.padStart(2, "0");
    const year = `${date.getFullYear() + 543}`;
    return `${day}/${month}/${year}`;
  }
  return "";
}

export function parseDate(str: any, toMoment: any) {
  if (typeof str !== "string") {
    return;
  }

  const split = str.split("/");
  if (split.length !== 3) {
    return;
  }

  const year = parseInt(split[2], 10) - 543;
  const month = parseInt(split[1], 10) - 1;
  const day = parseInt(split[0], 10);
  if (
    isNaN(year) ||
    String(year).length > 4 ||
    isNaN(month) ||
    isNaN(day) ||
    day <= 0 ||
    day > 31 ||
    month < 0 ||
    month >= 12
  ) {
    return;
  }

  // When using this function with DayPickerInput, the second argument will be a format not toMoment
  if (toMoment === true) {
    return moment(`${year}-${month + 1}-${day}`, "YYYY-MM-DD");
  }
  return new Date(year, month, day);
}

// Data yyyy-mm-dd (AD) to dd/mm/yyyy (BE)
// export function formatADtoBEString(dateString) {
//   if (typeof dateString !== 'string') {
//     return;
//   }

//   const split = dateString.split('-');
//   if (split.length !== 3) {
//     return;
//   }

//   const year = parseInt(split[0], 10) + 543;
//   // const month = parseInt(split[1], 10) ;
//   // const day = parseInt(split[2], 10);
//   return split[2] + "/" + split[1] + "/" + year.toString()
// }

// Data yyyy-mm-dd (AD) to dd/mm/yyyy (BE) Default
export function formatADtoBEString(dateString: any, spliter = "-") {
  if (typeof dateString !== "string") {
    return;
  }

  const split = dateString.split(spliter);
  if (split.length !== 3) {
    return;
  }

  const year = parseInt(split[0], 10) + 543;
  // const month = parseInt(split[1], 10) ;
  // const day = parseInt(split[2], 10);
  return split[2] + "/" + split[1] + "/" + year.toString();
}

/**
 * format date string dd/mm/yyyy (BE) to yyyy<separator>mm<separator>dd (AD)
 * @param {string} dateString - date in dd/mm/yyyy
 * @param {string} separator - separator (default: '-')
 * @param {boolean} fromBE - convert from BE to AD when `true`,
 *                           `false` if do not convert (default: true)
 * @return {any} - return string of formatted date if dateString is a valid date string
 *                 else return dateString without do anything
 */
export function formatDateToYYYYMMDD(
  dateString: any,
  separator: any,
  fromBE: any
) {
  separator = separator || "-";
  fromBE = fromBE != null ? fromBE : true;

  if (typeof dateString !== "string") {
    return dateString;
  }

  const dateSplitted = dateString.split("/");
  if (dateSplitted.length !== 3) {
    return dateString;
  }

  let year;
  if (fromBE) {
    year = String(parseInt(dateSplitted[2], 10) - 543);
  } else {
    year = String(parseInt(dateSplitted[2], 10));
  }

  const month = String(parseInt(dateSplitted[1], 10)).padStart(2, "0");
  const day = String(parseInt(dateSplitted[0], 10)).padStart(2, "0");

  return year + separator + month + separator + day;
}

// Data "2020-06-22T17:17:32Z" to dd/mm/yyyy hh:mm (BE)
export function formatUTCtoBEString(utcDT: any, timeFormat = "HH:mm") {
  const dateObj = new Date(utcDT);
  const mm = moment(dateObj);
  const dateBe = formatADtoBEString(mm.format("YYYY-MM-DD"));
  const timeBe = mm.format(timeFormat);

  return [dateBe, timeBe];
}

export const isValidDate = (str: any) =>
  /^(0[1-9]|1\d|2\d|3[01])\/(0[1-9]|1[0-2])\/\d{4}$/.test(str);

export function formatDatetime(value: any) {
  if (value) {
    var datetime = moment(value);
    var day = datetime.format("DD");
    var month = datetime.format("MM");
    var year = parseInt(datetime.format("YYYY")) + 543;
    var time = datetime.format("HH:mm");
    return day + "/" + month + "/" + year + " " + time;
  } else {
    return "";
  }
}

export function dateToStringWithoutTimeBE(value: any) {
  // console.log("dateToStringWithoutTimeBE value" ,value)
  if (value) {
    var datetime = moment(value);
    var day = datetime.format("DD");
    var month = datetime.format("MM");
    var year = parseInt(datetime.format("YYYY")) + 543;
    // console.log("dateToStringWithoutTimeBE", day, "/", month, "/", year)
    return day + "/" + month + "/" + year;
  } else {
    return "";
  }
}

export function dateToStringWithoutTimeAD(value: any) {
  if (value) {
    var datetime = moment(value);
    var day = datetime.format("DD");
    var month = datetime.format("MM");
    var year = parseInt(datetime.format("YYYY"));
    return day + "/" + month + "/" + year;
  } else {
    return "";
  }
}
