export default class DashboardController {
  constructor(props) {
    this.prxManager = props.prxManager;
    this.coreManager = props.coreManager;
  }

  getTriageLevelClassify = async ({
    apiToken,
    divisionId
  } = {}) => {
    const [
      response,
      error,
      network
    ] = await this.prxManager.getTriageLevelClassify({
      apiToken,
      divisionId
    });
    return [response, error, network];
  };

  getDivision = async ({ isTelemed, apiToken } = {}) => {
    const [data, error] = await this.coreManager.getDivision({ isTelemed, apiToken });
    return [data, error];
  };

  getDashboard = async ({ 
    apiToken,
    search,
    date,
    triageLevels,
    division,
    paymentStatus
  } = {}) => {
    const [
      response,
      error,
      network
    ] = await this.prxManager.getDashboard({
      apiToken,
      search,
      date,
      triageLevels,
      division,
      paymentStatus
    });
    return [response, error, network];
  }

  getOAInviteLink = async({ 
    apiToken,
    divisionId
  }) => {

    const [
      response,
      error,
      network
    ] = await this.prxManager.getOAInviteLink({
      apiToken,
      divisionId
    });
    return [response, error, network];
  }

}