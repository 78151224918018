/*eslint no-unused-vars: 0, arrow-parens: 0, indent: 0, semi: 0, no-useless-catch: 0*/
import axios, { AxiosAdapter, AxiosInstance } from "axios";
import Qs from "qs";
import _ from "../../compat/lodashplus";
import localPrint from "../../apps/common/LocalPrint";
// import Blob from "cross-blob";

// Add this so apis can be called from node and return error for debug
// globalThis.Blob = Blob;

// Cannot get this to compile -> use windowany below instead
// declare global {
//   interface Window {
//       MobNative: any | undefined | null;
//   }
// }
// This might be due to incompatible react-scripts version
// But haven't changed it back to declare global yet

class BaseService  {
  client: AxiosInstance;

  static _interceptors = new Map();
  static addInterceptor(interceptor: any, callback: any) {
    BaseService._interceptors.set(interceptor, callback);
  }
  static removeInterceptor(interceptor: any) {
    BaseService._interceptors.delete(interceptor);
  }
  static _dispatchError(errorMessage: string) {
    BaseService._interceptors.forEach(callback => {
      callback(errorMessage);
    });
  }
  constructor(apiToken = null) {
    // console.log('Prefer BaseService')
    this.client = axios.create({
      paramsSerializer: params =>
        Qs.stringify(params, { arrayFormat: "repeat" }),
        withCredentials: false
    });
    this.client.interceptors.request.use(this._interceptRequest, error =>
      Promise.reject(error)
    );
    if (apiToken) {
      console.log("Base SErvice !!! Add Authorization !!! apiToken: ", apiToken)
      this.client.defaults.headers["Authorization"] = "Token " + apiToken;
    }
    // intercept a response error (500) and error
    this.client.interceptors.response.use(res => {
      localPrint(res);
      return res;
    }, this._handleError);
  }
  // _consoleIntercept() {
  //   // console.log('BaseService._interceptors', BaseService._interceptors)
  // }
  _interceptRequest(config: any) {
    // intercept a request to handle pagination param
    if (config.params && !_.has(config.params, "pagination_class")) {
      config.params.pagination_class = "page_number";
    }
    // intercept a request to handle a csrf token
    config.xsrfCookieName = "csrftoken";
    config.xsrfHeaderName = "X-CSRFToken";
    return config;
  }
  _readBlobAsText(blob: Blob) {
    return new Promise(res => {
      const reader = new FileReader();
      reader.onload = () => res(reader.result);
      reader.readAsText(blob);
    });
  }
  _handleError = async (err: any) => {
    if (err.response) {
      try {
        if (err.response.data instanceof Blob) {
          err.response.data = await this._readBlobAsText(err.response.data);
        }
      } catch (catchError) {
        throw catchError;
      }
    }
    if (err.response && [500, 503].includes(err.response.status)) {
      let data = err.response.data;
      let one = data.indexOf("Installed Applications:");
      let two = data.indexOf("Traceback:");
      let three = data.indexOf("META:");
      const message = `
          Internal Server Error ${err.response.status}\n
          ${data.substring(0, one)}\n
          ${data.substring(two, three)}
      `;
      BaseService._dispatchError(message.replace(/\r\n|\n|\r/gm, "\n"));
    }
    if (!err.response && err.request) {
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the browser
      // patch response.data to the error object
      const message = "Network Error: Maybe backend did not respond";
      err.response = { data: message };
      BaseService._dispatchError(message);
    }
    if (!err.response && !err.request) {
      // Something happened in setting up the request that triggered an Error
      err.response = { data: err.message };
    }
    return Promise.reject(err);
  };
  // used in subclass
  throwErrorMessage(error: any) {
    // console.log(" error.response", error.response)
    // console.log(" error.request.responseURL ",typeof(error.request.responseURL))
    let windowany: any = globalThis;
    const mobNative =
      typeof windowany.MobNative !== "undefined" ? windowany.MobNative : null;
    if (mobNative && error.response.status === 403) {
      let detail = error.response.data && error.response.data.detail;
      let msg =
        error.response.request.responseURL +
        "\n\n" +
        "Error Code:" +
        error.response.status +
        "\n\n response:" +
        detail;
      mobNative.alert(msg);
    }
    throw { responseData: error.response?.data, error: error };
  }
  handleResponse(response: any) {
    return { responseData: response.data, response: response };
  }
}
export default BaseService;
