import React from 'react';
import {FormEvent} from 'react';
import {Button, Form, Message} from 'semantic-ui-react';
import PasswordLoginManager from './PasswordLoginManager';
import OTPModal from './OTPModal';

interface AuthenticatedResponse {
  token: string,
  profile: {
    userId: number,
    fullname: string,
    username: string,
  }
}

interface Props {
  usernameText?: string;
  onLoginSuccess: (response: AuthenticatedResponse) => void;
}

interface State {
  isLoading: boolean,
  username: string,
  password: string,
  errorMessage: string,
  usernameError: string,
  passwordError: string,
  OTPModalOpen: boolean,
  OTPModalCid: string,
  userId: number,
}

export default class LoginForm extends React.PureComponent<Props, State> {

  static defaultProps = {
    usernameText: 'Username',
  }

  state: State = {
    isLoading: false,
    username: '',
    password: '',
    errorMessage: '',
    usernameError: '',
    passwordError: '',
    OTPModalOpen: false,
    OTPModalCid: '',
    userId: -1,
  }
  manager = new PasswordLoginManager();

  onFormSubmit = async (event: FormEvent) => {
    const {username, password} = this.state;
    this.setState({isLoading: true});
    try {
      const data = await this.manager.requestToken(username, password);

      console.log(data);
      let userId = data.user_id;
      let cid = data.cid;
      if (data.token == null) {
        this.setState({
          OTPModalOpen: true,
          OTPModalCid: cid,
          userId,
        });
      } else {
        this.onSuccess(data.token, userId);
      }

      this.setState({
        errorMessage: '',
        usernameError: '',
        passwordError: '',
      });
    } catch (e) {
      console.log(e);
      let data = e;
      let errorMessage = '';
      let usernameError = '';
      let passwordError = '';
      if ('non_field_errors' in data) {
        errorMessage = data['non_field_errors'][0];
      }
      if ('detail' in data) {
        errorMessage = data['detail'];
      }
      if ('username' in data) {
        usernameError = data['username'][0];
      }
      if ('password' in data) {
        passwordError = data['password'][0];
      }
      this.setState({
        errorMessage,
        usernameError,
        passwordError
      });
    }
    this.setState({isLoading: false});
  }

  onSuccess = async (token:string, userId:number) => {
    const {username} = this.state;
    console.log(`token: ${token}`);
    const profile = await this.manager.getUserProfile(token);
    let fullname = `${profile.first_name} ${profile.last_name}`;
    this.props.onLoginSuccess({
      token: token,
      profile: {
        userId,
        fullname,
        username,
      }
    });
  }

  setUsername = (event: any) => {
    this.setState({username: event.target.value});
  }
  setPassword = (event: any) => {
    this.setState({password: event.target.value});
  }
  getFieldError = (message:string) => {
    if (message) {
      return {
        content: message,
      };
    }
    return null;
  }
  openOTPModal = () => {
    this.setState({OTPModalOpen: true});
  }
  closeOTPModal = () => {
    this.setState({OTPModalOpen: false});
  }
  onOTPSuccess = (token:string) => {
    this.onSuccess(token, this.state.userId);
  }

  render() {
    const {
      isLoading, username, password,
      errorMessage, usernameError, passwordError,
      OTPModalOpen, OTPModalCid,
    } = this.state;
    const {usernameText} = this.props;

    return (
      <>
        <Form loading={isLoading} onSubmit={this.onFormSubmit} error={errorMessage !== ''}>
          { errorMessage ? (
            <Message
              error
              content={errorMessage}
            />
          ):null }
          <Form.Field>
            <Form.Input
              label={usernameText}
              value={username}
              onChange={this.setUsername}
              error={this.getFieldError(usernameError)}
            />
          </Form.Field>
          <Form.Field>
            <Form.Input
              label='Password'
              type='password'
              value={password}
              onChange={this.setPassword}
              error={this.getFieldError(passwordError)}
            />
          </Form.Field>
          <Button type='submit'>Login</Button>
        </Form>
        <OTPModal
          open={OTPModalOpen}
          cid={OTPModalCid}
          username={username}
          onCancel={this.closeOTPModal}
          onOTPSuccess={this.onOTPSuccess}
        />
      </>
    );
  }
}
